export enum WPQSegmentNames {
  singleLocationWelcomeLoad = 'WPQ.STANDARD_WPQ.WELCOME_SCREEN_PAGE_LOAD',
  multiLocationWelcomeLoad = 'WPQ.STANDARD_WPQ.MULTI_LOCATION_WELCOME_SCREEN_PAGE_LOAD',
  eligibilityLoad = 'WPQ.STANDARD_WPQ.ELIGBILITY_SCREEN_PAGE_LOAD',
  phoneNumberLoad = 'WPQ.STANDARD_WPQ.ENTER_PHONE_NUMBER_SCREEN_PAGE_LOAD',
  otpLoad = 'WPQ.STANDARD_WPQ.PHONE_VERIFICATION_PAGE_LOAD',
  personalInfoLoad = 'WPQ.STANDARD_WPQ.NAME_DOB_PAGE_LOAD',
  personalAddressLoad = 'WPQ.STANDARD_WPQ.ADDRESS_PAGE_LOAD',
  ssnLoad = 'WPQ.STANDARD_WPQ.SSN_PAGE_LOAD',
  last4ssnLoad = 'WPQ.STANDARD_WPQ.LAST_4_SSN_PAGE_LOAD',
  ssnSurveyLoad = 'WPQ.STANDARD_WPQ.SSN_LOADING_SURVEY_PAGE_LOAD',
  defaultHighLineTreatmentCostLoad = 'WPQ.DEFAULT_HIGH_LINE.TREATMENT_COST_PAGE_LOAD',
  defaultHighLineEmailLoad = 'WPQ.DEFAULT_HIGH_LINE.EMAIL_PAGE_LOAD',
  defaultHighLineHousingCostLoad = 'WPQ.DEFAULT_HIGH_LINE.HOUSING_COST_PAGE_LOAD',
  defaultHighLineStatedIncomeLoad = 'WPQ.DEFAULT_HIGH_LINE.STATED_INCOME_PAGE_LOAD',
  highLineTreatmentCostLoad = 'WPQ.HIGH_LINE.TREATMENT_COST_PAGE_LOAD',
  highLineEmailLoad = 'WPQ.HIGH_LINE.EMAIL_PAGE_LOAD',
  highLineHousingCostLoad = 'WPQ.HIGH_LINE.HOUSING_COST_PAGE_LOAD',
  recentlyMovedModalLoad = 'WPQ.ADDRESS_VERIFICATION.RECENTLY_MOVED_ADDRESS_MODAL_PAGE_LOAD',
  confirmAddressModalLoad = 'WPQ.ADDRESS_VERIFICATION.CONFIRM_ADDRESS_ENTRY_MODAL_PAGE_LOAD',
  previousAddressPageLoad = 'WPQ.ADDRESS_VERIFICATION.PREVIOUS_ADDRESS_VERIFICATION_PAGE_LOAD',
  highLineStatedIncomeLoad = 'WPQ.HIGH_LINE.STATED_INCOME_PAGE_LOAD',
  helpModalLoad = 'WPQ.HELP_MODAL_PAGE_LOAD',
  incomeVerificationLandingLoad = 'WPQ.INCOME_VERIFICATION.INCOME_VERIFICATION_PAGE_LOAD',
  incomeVerificationPlaidLoad = 'WPQ.INCOME_VERIFICATION.PLAID_LANDING_PAGE_LOAD',
  incomeVerificationBankConnectLoad = 'WPQ.INCOME_VERIFICATION.PLAID_BANK_CONNECT_PAGE_LOAD',
  incomeVerificationRepromtPageLoad = 'WPQ.INCOME_VERIFICATION.COULD_NOT_CONNECT_TO_BANK_BANK_REPROMPT_PAGE_LOAD',
  incomeVerificationNotConnectBankLoad = 'WPQ.INCOME_VERIFICATION.COULD_NOT_CONNECT_TO_BANK_PAGE_LOAD',
  incomeVerificationNotFindBankLoad = 'WPQ.INCOME_VERIFICATION.COULD_NOT_FIND_BANK_PAGE_LOAD',
  changeApprovalLocationLoaded = 'WPQ.LOCATION_SWITCH.UPDATED_TREATMENT_LOCATION_PAGE_LOAD',
  incomeVerificationPaystubLoad = 'WPQ.INCOME_VERIFICATION.PAYSTUB_CONNECT_PAGE_LOAD',
  incomeVerificationPayrollLoad = 'WPQ.INCOME_VERIFICATION.PAYROLL_CONNECT_PAGE_LOAD',
  incomeVerificationAlmostThereLoad = 'WPQ.INCOME_VERIFICATION.ALMOST_THERE_PAGE_LOAD',
  idReviewSSNVerificationLoad = 'WPQ.ID_VERIFICATION.SSN_VERIFICATION_LANDING_PAGE_LOAD',
  idReviewLandingLoad = 'WPQ.ID_VERIFICATION.ID_VERIFICATION_LANDING_PAGE_LOAD',
  idReviewCognitoLoad = 'WPQ.ID_VERIFICATION.ID_COGNITO_LANDING_PAGE_LOAD',
  approvalPageLoad = '  WPQ.OUTCOME.APPROVAL_PAGE_LOAD',
  whatHappensLoad = 'WPQ.OUTCOME.WHAT_HAPPENS_NOW_APPROVAL_PAGE_LOAD',
  denialLoad = 'WPQ.OUTCOME.DENIAL_PAGE_LOAD',
  kycDenialLoad = 'WPQ.OUTCOME.KYC_DENIAL_PAGE_LOAD',
  kycFailureLanding = 'WPQ.OUTCOME.KYC_FAILURE_PAGE_LOAD',
  manualReviewLoad = 'WPQ.OUTCOME.MANUAL_REVIEW_PAGE_LOAD',
  frozenCreditLoad = 'WPQ.OUTCOME.FROZEN_CREDIT_PAGE_LOAD',
  expiredLinkLoad = 'WPQ.OUTCOME.EXPIRED_LINK_PAGE_LOAD',
  purchaseCanceledLoad = 'WPQ.OUTCOME.PURCHASE_CANCELLED_PAGE_LOAD',
  paymentFailedLoad = 'WPQ.OUTCOME.PAYMENT_FAILED_PAGE_LOAD',
  mickeyMouseApproval = 'WPQ.OUTCOME.MICKEY_MOUSE_APPROVAL_PAGE_LOAD',
  postCheckoutLandingLoad = '  WPQ.CHECKOUT.CHECKOUT_LANDING_PAGE_LOAD',
  postCheckoutSelectionLoad = 'WPQ.CHECKOUT.PLAN_SELECTION_PAGE_LOAD',
  postCheckoutConfirmationLoad = 'WPQ.CHECKOUT.PLAN_CONFIRMATION_PAGE_LOAD',
  postCheckoutAddCardLoad = 'WPQ.CHECKOUT.ADD_CARD_PAGE_LOAD',
  postCheckoutDownPaymentLoad = 'WPQ.CHECKOUT.DOWN_PAYMENT_PAGE_LOAD',
  postCheckoutRecurringLoad = 'WPQ.CHECKOUT.RECURRING_PAYMENT_PAGE_LOAD',
  postCheckoutFinalAgreementLoad = 'WPQ.CHECKOUT.FINAL_AGREEMENT_PAGE_LOAD',
  postCheckoutPostFundingLoad = 'WPQ.CHECKOUT.POST_FUNDING_PAGE_LOAD',
  postCheckoutCustomDownPaymentLoad = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_PAGE_LOAD',
  planSelectionCustomDownPaymentModalLoad = 'WPQ.OUTCOME.PLAN_SELECTION_DOWN_PAYMENT_MODAL_PAGE_LOAD',
  singleLocationButtonClicked = 'WPQ.STANDARD_WPQ.WELCOME_SCREEN_CONTINUE_CLICKED',
  multiLocationGetHelpButtonClicked = 'WPQ.MULTI_LOCATION_WELCOME_SCREEN._GET_HELP_CLICKED',
  singleLocationGetHelpButtonClicked = 'WPQ.STANDARD_WPQ.WELCOME_SCREEN_GET_HELP_CLICKED',
  helpModalEmailClicked = 'WPQ.HELP_MODAL_EMAIL_SUPPORT_CLICKED',
  helpModalCallClicked = 'WPQ.HELP_MODAL_CALL_SUPPORT_CLICKED',
  helpModalChatClicked = 'WPQ.HELP_MODAL_CHAT_SUPPORT_CLICKED',
  helpModalStartOverClicked = 'WPQ.HELP_MODAL_START_OVER_CLICKED',
  multiLocationWelcomeScreen = 'WPQ.MULTI_LOCATION_WELCOME_SCREEN._CONTINUE_CLICKED',
  eligibilityClicked = 'WPQ.STANDARD_WPQ.ElIGBILITY_SCREEN_CONTINUE_CLICKED',
  phoneNumberInputClicked = 'WPQ.STANDARD_WPQ.PHONE_NUMBER_SCREEN_PHONE_NUMBER_INPUT_CLICKED',
  phoneNumberInputFilled = 'WPQ.STANDARD_WPQ.PHONE_NUMBER_SCREEN_PHONE_NUMBER_INPUT_FILLED',
  phoneNumberContinueClicked = 'WPQ.STANDARD_WPQ.PHONE_NUMBER_SCREEN_CONTINUE_CLICKED',
  otpInputClicked = 'WPQ.STANDARD_WPQ.OTP_SCREEN_OTP_INPUT_CLICKED',
  otpInputFilled = 'WPQ.STANDARD_WPQ.OTP_SCREEN_OTP_INPUT_FILLED',
  otpContinueClicked = 'WPQ.STANDARD_WPQ.OTP_SCREEN_CONTINUE_CLICKED',
  otpDidnotGetCodeClicked = 'WPQ.STANDARD_WPQ.OTP_SCREEN_DID_NOT_GET_CODE_CLICKED',
  otpResendCodeClicked = 'WPQ.STANDARD_WPQ.OTP_SCREEN_RE_SEND_CODE_CLICKED',
  otpCallWithClicked = 'WPQ.STANDARD_WPQ.OTP_SCREEN_CALL_WITH_CODE_CLICKED',
  firstNameClicked = 'WPQ.STANDARD_WPQ.NAME_DOB_FIRST_NAME_INPUT_CLICKED',
  firstNameFilled = 'WPQ.STANDARD_WPQ.NAME_DOB_FIRST_NAME_INPUT_FILLED',
  lastNameClicked = 'WPQ.STANDARD_WPQ.NAME_DOB_LAST_NAME_INPUT_CLICKED',
  lastNameFilled = 'WPQ.STANDARD_WPQ.NAME_DOB_LAST_NAME_INPUT_FILLED',
  dateOfBirthClicked = 'WPQ.STANDARD_WPQ.NAME_DOB_DOB_INPUT_CLICKED',
  dateOfBirthFilled = 'WPQ.STANDARD_WPQ.NAME_DOB_DOB_INPUT_FILLED',
  whatIsLegalNameClicked = 'WPQ.STANDARD_WPQ.NAME_DOB_WHAT_IS_LEGAL_NAME_CLICKED',
  personalInfoContinueClicked = 'WPQ.STANDARD_WPQ.NAME_DOB_CONTINUE_CLICKED',
  streetClicked = 'WPQ.STANDARD_WPQ.ADDRESS_STREET_INPUT_CLICKED',
  streetFilled = 'WPQ.STANDARD_WPQ.ADDRESS_STREET_INPUT_FILLED',
  aptClicked = 'WPQ.STANDARD_WPQ.ADDRESS_APT_INPUT_CLICKED',
  aptFilled = 'WPQ.STANDARD_WPQ.ADDRESS_APT_INPUT_FILLED',
  cityClicked = 'WPQ.STANDARD_WPQ.ADDRESS_CITY_INPUT_CLICKED',
  cityFilled = 'WPQ.STANDARD_WPQ.ADDRESS_CITY_INPUT_FILLED',
  stateClicked = 'WPQ.STANDARD_WPQ.ADDRESS_STATE_INPUT_CLICKED',
  stateFilled = 'WPQ.STANDARD_WPQ.ADDRESS_STATE_INPUT_FILLED',
  zipClicked = 'WPQ.STANDARD_WPQ.ADDRESS_ZIP_INPUT_CLICKED',
  zipFilled = 'WPQ.STANDARD_WPQ.ADDRESS_ZIP_INPUT_FILLED',
  addressContinueClicked = 'WPQ.STANDARD_WPQ.ADDRESS_CONTINUE_CLICKED',
  ssnClicked = 'WPQ.STANDARD_WPQ.SSN_SSN_INPUT_CLICKED',
  last4ssnClicked = 'WPQ.STANDARD_WPQ.LAST_4_SSN_SSN_INPUT_CLICKED',
  ssnFilled = 'WPQ.STANDARD_WPQ.SSN_SSN_INPUT_FILLED',
  last4ssnFilled = 'WPQ.STANDARD_WPQ.LAST_4_SSN_SSN_INPUT_FILLED',
  ssnContinueClicked = 'WPQ.STANDARD_WPQ.SSN_ELIGIBILITY_CLICKED',
  last4ssnContinueClicked = 'WPQ.STANDARD_WPQ.LAST_4_SSN_ELIGIBILITY_CLICKED',
  ivAtLeast3MonthClicked = '  WPQ.INCOME_VERIFICATION.LANDING_AT_LEAST_3_MONTHS_OLD_CLICKED',
  ivDepositIncomeClicked = 'WPQ.INCOME_VERIFICATION.LANDING_DEPOSIT_INCOME_INTO_ACCOUNT_CLICKED',
  ivLandingMake1000Clicked = 'WPQ.INCOME_VERIFICATION.LANDING_MAKE_1000_PER_MONTH_CLICKED',
  ivHaveQuestionClicked = 'WPQ.INCOME_VERIFICATION.LANDING_HAVE_A_QUESTION_CLICKED',
  ivConnectBankAcountClicked = 'WPQ.INCOME_VERIFICATION.LANDING_CONNECT_BANK_ACCOUNT_CLICKED',
  ivPaystubsRadioClicked = 'WPQ.INCOME_VERIFICATION.COULD_NOT_FIND_BANK_SUBMIT_MY_LAST_PAYSTUBS_CLICKED',
  ivPayrollRadioButtonClicked = 'WPQ.INCOME_VERIFICATION.COULD_NOT_FIND_BANK_CONNECT_PAYROLL_CLICKED',
  ivBankAccountInsteadClicked = 'WPQ.INCOME_VERIFICATION.COULD_NOT_FIND_BANK_CONNECT_MY_BANK_ACCOUNT_INSTEAD_CLICKED',
  ivPaystubContinueClicked = 'WPQ.INCOME_VERIFICATION.PAYSTUB_CONNECT_CONTINUE_CLICKED',
  ivPayrolContinueClicked = 'WPQ.INCOME_VERIFICATION.PAYROLL_CONNECT_CONTINUE_CLICKED',
  idVerificationContinueClicked = 'WPQ.ID_VERIFICATION.SSN_VERIFICATION_LANDING_CONTINUE_CLICKED',
  idVerificationSubmitMyIdClicked = 'WPQ.ID_VERIFICATION.ID_VERIFICATION_LANDING_SUBMIT_MY_ID_CLICKED',
  idVerificationCognitoCompleted = 'WPQ.ID_VERIFICATION.ID_COGNITO_LANDING_COMPLETED',
  checkoutLandingChooseClicked = 'WPQ.CHECKOUT.CHECKOUT_LANDING_CHOOSE_MY_PLAN_CLICKED',
  planConfirmationClicked = 'WPQ.CHECKOUT.PLAN_CONFIRMATION_CONTINUE_CLICKED',
  planConfirmationChooseAnotherClicked = 'WPQ.CHECKOUT.PLAN_CONFIRMATION_CHOOSE_ANOTHER _PLAN_CLICKED',
  addCardClicked = 'WPQ.CHECKOUT.ADD_CARD_CLICKED',
  downPaymentContinueClicked = 'WPQ.CHECKOUT.DOWN_PAYMENT_CONTINUE_CLICKED',
  downPaymentAddMethodClicked = 'WPQ.CHECKOUT.DOWN_PAYMENT_ADD_PAYMENT_METHOD_CLICKED',
  recurringContinueClicked = 'WPQ.CHECKOUT.RECURRING_PAYMENT_CONTINUE_CLICKED',
  recurrringAddMethodClicked = 'WPQ.CHECKOUT.RECURRING_PAYMENT_ADD_PAYMENT_METHOD_CLICKED',
  finalAgreementExpandClicked = 'WPQ.CHECKOUT.FINAL_AGREEMENT_EXPAND_CLICKED',
  finalAgreementConfirmClicked = 'WPQ.CHECKOUT.FINAL_AGREEMENT_CONFIRM_CLICKED',
  succesPatientPortalClicked = 'WPQ.CHECKOUT.POST_FUNDING_VIEW_PATIENT_PORTAL_CLICKED',
  successSubmitEmailClicked = 'WPQ.CHECKOUT.POST_FUNDING_SCREEN_SUBMIT_EMAIL_CLICKED',
  successEmailFilled = 'WPQ.CHECKOUT.POST_FUNDING_SCREEN_EMAIL_FIELD_FILLED',
  successEmailClicked = 'WPQ.CHECKOUT.POST_FUNDING_SCREEN_EMAIL_FIELD_CLICKED',
  surveyAccepted = 'WPQ.CHECKOUT.POST_FUNDING_SCREEN_COMPLETE_SURVEY_CLICKED',
  customDownPaymentClicked = 'WPQ.OUTCOME.PLAN_SELECTION_CHANGE_DOWN_PAYMENT_CLICKED',
  customDownPaymentEnterAmountClicked = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_ENTER_CUSTOM_DOWN_PAYMENT_CLICKED',
  customDownPaymentAmountFilled = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_ENTER_CUSTOM_DOWN_PAYMENT_FILLED',
  customDownPaymentMoreClicked = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_PAY_MORE_CLICKED',
  customDownPaymentLessClicked = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_PAY_LESS_CLICKED',
  customDownPaymentSaveClicked = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_SAVE_PAYMENT_CLICKED',
  customDownPaymentCancelClicked = 'WPQ.CHECKOUT.CUSTOM_DOWNPAYMENT_CANCEL_CLICKED',
  defaultHighLineTreatmentCostClicked = 'WPQ.DEFAULT_HIGH_LINE_WPQ.TREATMENT_COST_TREATMENT_COST_INPUT_CLICKED',
  defaultHighLineTreatmentCostFilled = 'WPQ.DEFAULT_HIGH_LINE_WPQ.TREATMENT_COST_SCREEN_TREATMENT_COST_INPUT_FILLED',
  defaultHighLineTreatmentCostContinue = 'WPQ.DEFAULT_HIGH_LINE_WPQ.TREATMENT_COST_CONTINUE_CLICKED',
  defaultHighLineHousingCostClicked = 'WPQ.DEFAULT_HIGH_LINE_WPQ.HOUSING_COST_HOUSING_COST_INPUT_CLICKED',
  defaultHighLineHousingCostFilled = 'WPQ.DEFAULT_HIGH_LINE_WPQ.HOUSING_COST_SCREEN_HOUSING_COST_INPUT_FILLED',
  defaultHighLineHousingCostContinue = 'WPQ.DEFAULT_HIGH_LINE_WPQ.HOUSING_COST_CONTINUE_CLICKED',
  defaultHighLineEmailClicked = 'WPQ.DEFAULT_HIGH_LINE_WPQ.EMAIL_EMAIL_INPUT_CLICKED',
  defaultHighLineEmailFilled = 'WPQ.DEFAULT_HIGH_LINE_WPQ.EMAIL_EMAIL_INPUT_INPUT_FILLED',
  defaultHighLineEmailContinue = 'WPQ.DEFAULT_HIGH_LINE_WPQ.EMAIL_CONTINUE_CLICKED',
  defaultHighLineStatedIncomeClicked = 'WPQ.DEFAULT_HIGH_LINE_WPQ.STATED_INCOME_STATED_INCOME_INPUT_CLICKED',
  defaultHighLineStatedIncomeFilled = 'WPQ.DEFAULT_HIGH_LINE_WPQ.STATED_INCOME_STATED_INCOME_INPUT_INPUT_FILLED',
  defaultHighLineStatedIncomeContinue = 'WPQ.DEFAULT_HIGH_LINE_WPQ.STATED_INCOME_CONTINUE_CLICKED',
  highLineTreatmentCostClicked = 'WPQ.HIGH_LINE_WPQ.TREATMENT_COST_TREATMENT_COST_INPUT_CLICKED',
  highLineTreatmentCostFilled = 'WPQ.HIGH_LINE_WPQ.TREATMENT_COST_SCREEN_TREATMENT_COST_INPUT_FILLED',
  highLineTreatmentCostContinue = 'WPQ.HIGH_LINE_WPQ.TREATMENT_COST_CONTINUE_CLICKED',
  highLineHousingCostClicked = 'WPQ.HIGH_LINE_WPQ.HOUSING_COST_HOUSING_COST_INPUT_CLICKED',
  highLineHousingCostFilled = 'WPQ.HIGH_LINE_WPQ.HOUSING_COST_SCREEN_HOUSING_COST_INPUT_FILLED',
  highLineHousingCostContinue = 'WPQ.HIGH_LINE_WPQ.HOUSING_COST_CONTINUE_CLICKED',
  highLineEmailClicked = 'WPQ.HIGH_LINE_WPQ.EMAIL_EMAIL_INPUT_CLICKED',
  highLineEmailFilled = 'WPQ.HIGH_LINE_WPQ.EMAIL_EMAIL_INPUT_INPUT_FILLED',
  highLineEmailContinue = 'WPQ.HIGH_LINE_WPQ.EMAIL_CONTINUE_CLICKED',
  highLineStatedIncomeClicked = 'WPQ.HIGH_LINE_WPQ.STATED_INCOME_STATED_INCOME_INPUT_CLICKED',
  highLineStatedIncomeFilled = 'WPQ.HIGH_LINE_WPQ.STATED_INCOME_STATED_INCOME_INPUT_INPUT_FILLED',
  highLineStatedIncomeContinue = 'WPQ.HIGH_LINE_WPQ.STATED_INCOME_CONTINUE_CLICKED',
  approvalHaveQuestionClicked = 'WPQ.OUTCOME.APPROVAL_HAVE_QUESTIONS_CLICKED',
  requestIncreaseClicked = 'WPQ.OUTCOME.APPROVAL_REQUEST_AN_INCREASE_CLICKED',
  denialViewInPatientClick = 'WPQ.OUTCOME.DENIAL_VIEW_IN_PATIENT_PORTAL_CLICKED',
  kycDenialPatientPortalClicked = 'WPQ.OUTCOME.KYC_DENIAL_VIEW_IN_PATIENT_PORTAL_CLICKED',
  frozenApplyAgainClicked = 'WPQ.OUTCOME.FROZEN_CREDIT_APPLY_AGAIN_CLICKED',
  howToUnfreezeClicked = 'WPQ.OUTCOME.FROZEN_CREDIT_HOW_TO_UNFREEZE_CREDIT_CLICKED',
  expiredLinkCallClicked = 'WPQ.OUTCOME.EXPIRED_LINK_CALL_CLICKED',
  expiredLinkVisitClicked = 'WPQ.OUTCOME.EXPIRED_LINK_VISIT_WEBSITE_CLICKED',
  purchaseCancelCallClicked = 'WPQ.OUTCOME.PURCHASE_CANCELLED_CALL_CLICKED',
  purchaseCancelWebsiteClicked = 'WPQ.OUTCOME.PURCHASE_CANCELLED_VISIT_WEBSITE_CLICKED',
  paymentFailedCallClicked = 'WPQ.OUTCOME.PAYMENT_FAILED_CALL_CLICKED',
  paymentFailedWebsiteClicked = 'WPQ.OUTCOME.PAYMENT_FAILED_VISIT_WEBSITE_CLICKED',
  mickeyMouseContinueClicked = 'WPQ.OUTCOME.MICKEY_MOUSE_APPROVAL_CONTINUE_MY_APP_CLICKED',
  mickeyMouseUseMyApprovalClicked = 'WPQ.OUTCOME.MICKEY_MOUSE_APPROVAL_USE_MY_APPROVAL_CLICKED',
  mickeyMouseModalCloseClicked = 'WPQ.OUTCOME.MICKEY_MOUSE_APPROVAL_X_CLICKED',
  mickeyMouseRequestIncreaseClicked = 'WPQ.OUTCOME.MICKEY_MOUSE_APPROVAL_REQUEST_AN_INCREASE_CLICKED',
  changeLocationClicked = 'WPQ.OUTCOME.APPROVAL_CHANGE_LOCATION_CLICKED',
  locationChangeInputClicked = 'WPQ.LOCATION_SWITCH.UPDATED_TREATMENT_LOCATION_LOCATION_INPUT_CLICKED',
  locationChangeInputFilled = 'WPQ.LOCATION_SWITCH.UPDATED_TREATMENT_LOCATION_LOCATION_INPUT_FILLED',
  locationChangeContinueClicked = 'WPQ.LOCATION_SWITCH.UPDATED_TREATMENT_LOCATION_CONTINUE_CLICKED',
  whatHappensHaveQuestion = 'WPQ.OUTCOME.WHAT_HAPPENS_NOW_APPROVAL_HAVE_QUESTIONS_CLICKED',
  approvalContinueClicked = 'WPQ.OUTCOME.APPROVAL_CONTINUE_CLICKED',
  almostTherePaystubClicked = 'WPQ.INCOME_VERIFICATION.ALMOST_THERE_SUBMIT_MY_LAST_PAYSTUBS_CLICKED',
  almostTherePaystubContinueClicked = 'WPQ.INCOME_VERIFICATION.ALMOST_THERE_SUBMIT_MY_LAST_PAYSTUBS_CONTINUE_CLICKED',
  almostTherePayrollClicked = 'WPQ.INCOME_VERIFICATION.ALMOST_THERE_CONNECT_PAYROLL_CLICKED',
  almostTherePayrollContinueClicked = 'WPQ.INCOME_VERIFICATION.ALMOST_THERE_CONNECT_PAYROLL_CONTINUE_CLICKED',
  almostThereInsteadConnectClicked = 'WPQ.INCOME_VERIFICATION.ALMOST_THERE_CONNECT_MY_BANK_ACCOUNT_INSTEAD_CLICKED',
  almostThereRepromptConnectBank = 'WPQ.INCOME_VERIFICATION.COULD_NOT_CONNECT_TO_BANK_BANK_REPROMPT_CONNECT_BANK_ACCOUNT_CLICKED',
  almostThereRepromptPaystubPayrollBank = 'WPQ.INCOME_VERIFICATION.COULD_NOT_CONNECT_TO_BANK_BANK_REPROMPT_CONNECT_PAYROLL_PAYSTUBS_CLICKED',
  whatHappensNowApprovalVisitPatientPortalClicked = 'WPQ.OUTCOME.WHAT_HAPPENS_NOW_APPROVAL_VISIT_PATIENT_PORTAL_CLICKED',
  yesRecentlyMovedClicked = 'WPQ.ADDRESS_VERIFICATION.RECENTLY_MOVED_MODAL_YES_I_HAVE_MOVED_CLICKED',
  notRecentlyMovedClicked = 'WPQ.ADDRESS_VERIFICATION.RECENTLY_MOVED_MODAL_NO_I_HAVE_NOT_MOVED_CLICKED',
  closeMovedModalClicked = 'WPQ.ADDRESS_VERIFICATION.RECENTLY_MOVED_MODAL_X_DISMISS_CLICKED',
  confirmYourAddressClicked = 'WPQ.ADDRESS_VERIFICATION.CONFIRM_ADDRESS_ENTRY_MODAL_CONFIRM_ADDRESS_CLICKED',
  editYourAddressClicked = 'WPQ.ADDRESS_VERIFICATION.CONFIRM_ADDRESS_ENTRY_MODAL_EDIT_ADDRESS_CLICKED',
  closeConfirmAddressModalClicked = 'WPQ.ADDRESS_VERIFICATION.CONFIRM_ADDRESS_ENTRY_MODAL_X_DISMISS_CLICKED',
  previousAddressContinueClicked = 'WPQ.ADDRESS_VERIFICATION.PREVIOUS_ADDRESS_VERIFICATION_CONTINUE_CLICKED',
  singleLocationWelcomeScreenChangeLanguageClicked = 'WPQ.SINGLE_LOCATION_WELCOME_SCREEN_CHANGE_LANGUAGE_CLICKED',
  multiLocationWelcomeScreenChangeLanguageClicked = 'WPQ.MULTI_LOCATION_WELCOME_SCREEN_CHANGE_LANGUAGE_CLICKED',
  helpModalChangeLanguageClicked = 'WPQ.HELP_MODAL_CHANGE_LANGUAGE_CLICKED',
  phoneUpdateNo = 'WPQ.STANDARD_WPQ.SSN_CONFLICT_PHONE_CHANGE.NO_CHANGE',
  phoneUpdateYes = 'WPQ.STANDARD_WPQ.SSN_CONFLICT_PHONE_CHANGE.PHONE_CHANGE',
  fullSsnLoad = 'WPQ.STANDARD_WPQ.FULL_SSN_COLLECT_PAGE_LOAD',
  fullSsnInputClicked = 'WPQ.STANDARD_WPQ.FULL_SSN_INPUT_CLICKED',
  fullSsnInputFilled = 'WPQ.STANDARD_WPQ.FULL_SSN_INPUT_FILLED',
  fullSsnButtonClicked = 'WPQ.STANDARD_WPQ.FULL_SSN_ELIGIBILITY_CLICKED',
  planSelectionCustomDownPaymentAmountClicked = 'WPQ.OUTCOME.PLAN_SELECTION_DOWN_PAYMENT_MODAL_AMOUNT_CLICKED',
  planSelectionCustomDownPaymentContinueClicked = 'WPQ.OUTCOME.PLAN_SELECTION_DOWN_PAYMENT_AMOUNT_MODAL_SAVE_DOWN_PAYMENT_CLICKED',
  planSelectionCustomDownPaymentModalClosed = 'WPQ.OUTCOME.PLAN_SELECTION_DOWN_PAYMENT_AMOUNT_MODAL_CANCEL_CLICKED',
  whatDoesThisMeanClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_DOES_THIS_MEAN_CLICKED',
  reviewPaymentOptionLoad = 'WPQ.OUTCOME.APPROVAL_PLAN_PREVIEW_PAGE_LOAD',
  reviewPaymentOptionInputFilled = 'WPQ.OUTCOME.APPROVAL_TREATMENT_COST_INPUT_FILLED',
  reviewPaymentOptionContinueClicked = 'WPQ.OUTCOME.APPROVAL_PLAN_PREVIEW_CONTINUE_CLICKED',
  whatHappensNextLoad = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_PAGE_LOAD',
  whatHappensNextLearnMoreClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_LEARN_MORE_CLICKED',
  whatHappensNextMoreQuestionClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_MORE_QUESTIONS_CLICKED',
  whatHappensNextContinueClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_CONTINUE_CLICKED',
  allSetLoad = 'WPQ.OUTCOME.APPROVAL_ALL_SET_PAGE_LOAD',
  allSetSurveyClicked = 'WPQ.OUTCOME.APPROVAL_ALL_SET_COMPLETE_SURVEY_CLICKED',
  allSetSurveyShown = 'WPQ.OUTCOME.APPROVAL_ALL_SET_COMPLETE_SURVEY_SHOWN',
  allSetVisitPortalClicked = 'WPQ.OUTCOME.APPROVAL_ALL_SET_VISIT_PORTAL_CLICKED',
  whatDoesMeanLoad = 'WPQ.OUTCOME.APPROVAL_WHAT_DOES_THIS_MEAN__MODAL_LOAD',
  whatDoestMeanGotItClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_DOES_THIS_MEAN__MODAL_GOT_IT_CLICKED',
  helpModalFaqClicked = 'WPQ.HELP_MODAL_FAQ_CLICKED',
  learnMoreLoad = 'WPQ.LEARN_MORE_MODAL_PAGE_LOAD',
  learnMoreGotItClicked = 'WPQ.LEARN_MORE_MODAL_GOT_IT_CLICKED',
  learnMoreXClicked = 'WPQ.LEARN_MORE_MODAL_X_CLICKED',
  learnMoreMoreQuestionClicked = 'WPQ.LEARN_MORE_MODAL_MORE_QUESTIONS_CLICKED',
  helpModalLanguageClicked = 'WPQ.HELP_MODAL_SPANISH_LANGUAGE_CLICKED',
  approvalCardAppleWalletClicked = 'WPQ.OUTCOME.APPROVAL_APPLE_WALLET_CLICKED',
  approvalCardGoogleWalletClicked = 'WPQ.OUTCOME.APPROVAL_GOOGLE_WALLET_CLICKED',
  approvalCardSaveClicked = 'WPQ.OUTCOME.APPROVAL_APPROVAL_IMAGE_SAVE_CLICKED',
  approvalCardEmailFilled = 'WPQ.OUTCOME.APPROVAL_EMAIL_APPROVAL_FIELD_FILLED',
  approvalCardEmailSubmit = 'WPQ.OUTCOME.APPROVAL_EMAIL_APPROVAL_CLICKED',
  whatHappenNextAppleWalletClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_APPLE_WALLET_CLICKED',
  whatHappenNextGoogleWalletClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_GOOGLE_WALLET_CLICKED',
  whatHappenNextImageSaveClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_APPROVAL_IMAGE_SAVE_CLICKED',
  whatHappenNextEmailFilled = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_EMAIL_APPROVAL_FIELD_FILLED',
  whatHappenNextEmailSubmit = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_EMAIL_APPROVAL_CLICKED',
  denialClickedAdverseActionLetter = 'WPQ.OUTCOME.DENIAL_CLICKED_ADVERSE_ACTION_LETTER',
  denialSurveyClicked = 'WPQ.OUTCOME.DENIAL_SURVEY_CLICKED',
  adverseActionLetterPageLoad = 'WPQ.OUTCOME.ADVERSE_ACTION_LETTER_PAGE_LOAD',
  approvalRequestAnIncreaseFakeModalPageLoad = 'WPQ.OUTCOME.APPROVAL_REQUEST_AN_INCREASE_FAKE_MODAL_PAGE_LOAD',
  approvalWhatHappensNextPatientPortalClicked = 'WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_VISIT_PATIENT_PORTAL_CLICKED',
}
