import styled from 'styled-components';

import { theme } from 'config/theme';
import { Icon } from 'lib/components';
import { SelectedPaymentMethodType } from 'pages/DownPayment/DownPayment';
import { useTranslation } from 'react-i18next';

interface Props {
  paymentMethod: SelectedPaymentMethodType;
  selectedPaymentMethod: SelectedPaymentMethodType;
  selectPaymentMethod: (paymentMethod: SelectedPaymentMethodType) => void;
  isPreferred: boolean;
}

interface IconName {
  type: string;
  name: string;
}

const Icons: IconName[] = [
  { type: 'CARD', name: 'credit_card' },
  { type: 'ACH', name: 'bank' },
  { type: 'RCC', name: 'remote-check' },
];

enum TypeEnum {
  ACH = 'ACH',
  CARD = 'CARD',
}

const PaymentMethodCard = ({ paymentMethod, selectedPaymentMethod, selectPaymentMethod, isPreferred }: Props) => {
  const { t: translate } = useTranslation();
  const { id, type, achAccount, rccAccount, storedCard } = paymentMethod || {};

  const accountKey = type === TypeEnum.ACH ? achAccount : rccAccount;
  const cardTitle = type === TypeEnum.CARD ? `${storedCard?.network} - ${storedCard?.type}` : `${accountKey?.bankName}`;
  const cardNumber =
    type === TypeEnum.CARD
      ? storedCard?.last4
      : accountKey?.accountNumber.substring(accountKey?.accountNumber.length - 4);

  const IconNames = () => {
    const isActive = selectedPaymentMethod?.id === id;
    const findIcon: IconName | undefined = Icons.find((ic) => ic?.type === type);

    if (isActive) {
      return `active_${findIcon?.name}`;
    } else {
      return findIcon?.name || '';
    }
  };

  const selectPaymentMethodHandler = () => {
    selectPaymentMethod(paymentMethod);
  };

  return (
    <Container
      onClick={selectPaymentMethodHandler}
      isActive={Number(selectedPaymentMethod?.id) === Number(id)}
      data-testid="payment-method-card"
    >
      <StyledIcon src={IconNames()} width={20} height={20} />
      <ContentContainer>
        <Title isActive={Number(selectedPaymentMethod?.id) === Number(id)}>{cardTitle}</Title>
        <Subtitle isActive={Number(selectedPaymentMethod?.id) === Number(id)}>************ {cardNumber}</Subtitle>
      </ContentContainer>
      {isPreferred && <PreferredContainer>{translate('downPayment.modal.preferred')}</PreferredContainer>}
    </Container>
  );
};

export default PaymentMethodCard;

const PreferredContainer = styled.div`
  font-size: 10px;
  line-height: 10.89px;
  color: ${(props) => props.theme.main.green};
  background: #e6f9f2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 4px 6px 5px;
  gap: 4px;
  border-radius: 16px;
`;

const Container = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${theme.main.grayColor};
  border-radius: 4px;
  padding: 12px 18px 12px ${(props) => (props.isActive ? '17px' : '18px')};
  box-sizing: border-box;
  margin-bottom: 8px;
  height: 56px;

  ${({ isActive }) =>
    isActive &&
    `
    border: 2px solid ${theme.main.green};
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  flex: 1;
`;

const Title = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.isActive ? props.theme.main.midnightBlue : props.theme.main.grayColor)};
`;

const Subtitle = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 10px;
  line-height: 13px;
  color: ${(props) => (props.isActive ? props.theme.main.midnightBlue : props.theme.main.grayColor)};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.main.green};
`;
