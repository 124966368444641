import { gql } from '@apollo/client/core';

export const FETCH_APPLICATION_LIST = gql`
  query fetchApplicationList($input: ApplicationListingRequest) {
    fetchApplicationList(input: $input) {
      contents {
        activeLoanId
        balanceAvailable
        borrowerId
        borrower {
          firstName
          lastName
          phone
        }
        channel
        createdAt
        demo
        displayId
        expireAt
        id
        merchantId
        maxEligible
        organizationId
        partner
        purchaseAmount
        selfCheckout
        status
        updatedAt
        validity
        readyForCheckout
        creditLineIncrease
        menu {
          maxPurchase
        }
      }
      total
    }
  }
`;

export const GET_APPLICATION = gql`
  query getApplication($input: ApplicationInput) {
    getApplication(input: $input) {
      activeLoanId
      balanceAvailable
      borrowerId
      channel
      createdAt
      demo
      displayId
      expireAt
      id
      merchantId
      ipAddress
      isValidForLoan
      ivAllow {
        paystub
      }
      maxEligible
      organizationId
      partner
      purchaseAmount
      reason
      mickeyMouse
      riskScore
      selfCheckout
      status
      validity
      creditLineIncrease
      flowType
      menu {
        maxPurchase
      }
      bankPartner
    }
  }
`;

export const GET_APPLICATION_FLOW = gql`
  query getApplicationFlow($input: ApplicationInput) {
    getApplicationFlow(input: $input) {
      id
      borrowerId
      applicationId
      status
      missingInfos
      createdAt
      updatedAt
      hasTreatmentQuestions
      requestAmount
      kycdenial
      ssnconflict
    }
  }
`;

export const GET_APPLICATION_CHERRY_ACCESS = gql`
  query getApplicationCherryAccess($input: ApplicationInput) {
    getApplicationCherryAccess(input: $input) {
      id
      applicationId
      amount
      status
      statedInfos {
        id
        type
        value
        status
      }
      missingInfos
      createdAt
      updatedAt
      missingInfos
    }
  }
`;

export const GET_APPLY_WITH_PATIENT_CONSENT = gql`
  query GetApplyWithPatientConsent($input: ApplyWithPatientConsentId) {
    getApplyWithPatientConsent(input: $input) {
      organizationId
      merchantId
      firstName
      lastName
      dob
      email
      phone
      last4ssn
      statedIncome
      housingPayment
      requestedAmount
      address {
        aptUnitAddress
        streetAddress
        city
        state
        zip
      }
      status
      borrowerId
      id
    }
  }
`;
