import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Grid } from '@mui/material';
import Confetti from 'react-confetti';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import timezone from 'dayjs/plugin/timezone';
import { useApolloClient, useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import { IApplication } from 'lib/types';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { confettiColors, theme } from 'config/theme';
import { GET_APPLICATION } from 'lib/graphql/queries';
import { useNavigation } from 'lib/hooks/useNavigation';
import { FormatAmount, FormatPhoneNumber, FormatPrice } from 'lib/utils';
import { EXPIRED_DATE_FORMAT } from 'lib/constants';
import { Button, Container, Icon, Loading, Subtitle, Title } from 'lib/components';
import {
  CreditLineIncreaseStatus,
  CreditLineIncreaseSubStatus,
  useCreditLineIncrease,
} from 'lib/hooks/useCreditLineIncrease';
import CreditLineIncreaseModal from './modals/CreditLineIncreaseModal';
import AlleApproval from './AlleApproval';
import MickeyMouseModal from './modals/MickeyMouseModal';
import { PartialApprovalModal } from './modals/PartialApprovalModal';
import { CREATE_LOANS } from 'lib/graphql/mutations';
import { useServices } from 'pages/AuthChecker/services';
import { loanStatusService } from 'pages/AuthChecker/utilityFunctions';
import RevampMickeyMouseModal from './modals/RevampMickeyMouseModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import OldApproval from './OldApproval';
import WhatDoesMeanModal from './modals/WhatDoesMeanModal';
import { useViewport } from 'lib/hooks/useViewport';
import RequestLargerApprovalModal from './RequestLargerApprovalModal';
import PreviewPaymentOptionsWidget from './PreviewPaymentOptionsWidget';
import ApprovalCard from './components/ApprovalCard';
import AddWallet from './components/AddWallet';
import SendApprovalEmail from './components/SendApprovalEmail';
import ThanksForClickingModal from './modals/ThanksForClickingModal';

dayjs.extend(utc);
dayjs.extend(timezone);

enum CreditLineStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
}

const STANDARD_APPROVAL_PAGE_FLOWS = ['ApplyCheckoutFlow', 'PointOfSalesFlow'];

const Approval = () => {
  const { t: translate } = useTranslation();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { submitApplication } = useNeuroId();
  const { isMobile } = useViewport();
  const client = useApolloClient();
  const [createLoanRequest] = useMutation(CREATE_LOANS);
  const { getApplication } = useServices();
  const alert = useAlert();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();

  const {
    selectedLocation,
    locations,
    setSelectedApplication,
    organization,
    flowType,
    borrower,
    isAlle,
    setApplication,
    sessionData,
    application,
    setLoan,
    isRequestLargerApprovalButtonDisabled,
    disableRequestLargerApprovalButton,
  } = useStore();
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewApprovalScreenEnable = featureFlags?.newApprovalScreen || false;
  const isNewApprovalCardEnable = featureFlags?.newApprovalCard || false;
  const isFakeRequestIncreaseExprimentEnabled =
    (!isNewApprovalScreenEnable && featureFlags?.isFakeRequestIncreaseExprimentEnabled) || false;
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [contentsData, setContentsData] = useState<IApplication | null>(null);
  const [showMickeyMouseModal, setShowMickeyMouseModal] = useState(false);
  const [showRevampMickeyMouseModal, setShowRevampMickeyMouseModal] = useState(false);
  const [whatdoesMeanModalVisibility, setWhatdoesMeanModalVisibility] = useState(false);
  const [thanksForClickingModalVisibility, setThanksForClickingModalVisibility] = useState(false);
  const [readyForAnalyticEvents, setReadyForAnalyticEvents] = useState<boolean>(false);
  const [isPartialApprovalModalVisible, setIsPartialApprovalModalVisible] = useState(false);
  const [isRequestLargeApprovalModalVisible, setIsRequestLargeApprovalModalVisible] = useState(false);
  const {
    fetchLatestCreditLineIncrease,
    pollLatestCreditLineIncrease,
    loading,
    creditLineIncrease,
    setShowCreditLineIncreaseModal,
    showCreditLineIncreaseModal,
  } = useCreditLineIncrease();

  const [creditLineIncreaseItem, setCreditLineIncreaseItem] = useState(
    creditLineIncrease || sessionApiData?.creditLineIncrease,
  );

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const containerRef: any = useRef(null);
  const [confettiActive, setConfettiActive] = useState(false);
  const isEligibleForCreditLineIncrease = contentsData?.creditLineIncrease === CreditLineStatus.ELIGIBLE;
  const shouldShowFakeCreditLineIncreaseModal =
    !isEligibleForCreditLineIncrease && isFakeRequestIncreaseExprimentEnabled;
  const shouldShowNewApprovalScreen = isNewApprovalScreenEnable || isFakeRequestIncreaseExprimentEnabled;
  const getInitialStatus = () => {
    const { applicationFlow } = useStore.getState() || {};
    setConfettiActive(
      dayjs().diff(applicationFlow?.updatedAt, 'minutes') < 135 && applicationFlow?.status === 'APPROVED',
    );
  };

  const setAnalyticsEvent = () => {
    const { application } = useStore.getState() || {};

    sendLoadSegmentEvent(WPQSegmentNames.approvalPageLoad, {
      approvalAmount: application?.balanceAvailable,
      lineIncreaseEligibility: application?.creditLineIncrease,
    });
  };
  useEffect(() => {
    getInitialStatus();
    setTimeout(() => {
      setConfettiActive(false);
    }, 6000);
  }, []);

  useEffect(() => {
    const { application } = useStore.getState() || {};
    if (application?.id) {
      fetchLatestCreditLineIncrease(application?.id).then((res) => {
        if (res?.id) {
          setCreditLineIncreaseItem(res);
          checkCreditLineIncreaseModalStatus(res);
        }
      });
    }
  }, []);

  const checkCreditLineIncreaseModalStatus = (cLineIncreaseItem) => {
    if (
      [CreditLineIncreaseStatus.REVIEW].includes(cLineIncreaseItem?.status) &&
      ![
        CreditLineIncreaseSubStatus.PROCESSING,
        CreditLineIncreaseSubStatus.MISSING_INFO,
        CreditLineIncreaseSubStatus.APPROVED,
        CreditLineIncreaseSubStatus.MORE,
      ].includes(cLineIncreaseItem?.subStatus) &&
      cLineIncreaseItem?.source === 'NORMAL'
    ) {
      setShowCreditLineIncreaseModal(true);
    } else {
      setShowCreditLineIncreaseModal(false);
    }
  };

  useEffect(() => {
    const { application } = useStore.getState();
    checkCreditLineIncreaseModalStatus(creditLineIncreaseItem);
    if (application?.id) {
      getApplicationsWithSearch?.(application?.id);
    }
    submitApplication('approval');
  }, []);

  useEffect(() => {
    if (readyForAnalyticEvents) {
      setAnalyticsEvent();
    }
  }, [readyForAnalyticEvents]);

  const hideCreditLineIncreaseModal = () => setShowCreditLineIncreaseModal(false);

  const hideMickeyMouseModal = () => setShowMickeyMouseModal(false);
  const hideRevampMickeyMouseModal = () => setShowRevampMickeyMouseModal(false);
  const hideWhatDoesMeanModal = () => setWhatdoesMeanModalVisibility(false);
  const hideThanksForClicking = () => setThanksForClickingModalVisibility(false);

  const changeLocationHandler = () => {
    sendActionSegmentEvent(WPQSegmentNames.changeLocationClicked);
    navigate(`update-location`);
  };

  const getApplicationsWithSearch = async (applicationId: string) => {
    setContentLoading(true);
    const { applicationFlow } = useStore.getState() || {};
    try {
      if (applicationId) {
        const { data } = await client.query({
          query: GET_APPLICATION,
          variables: {
            input: {
              applicationId,
            },
          },
        });

        if (data?.getApplication?.id) {
          const response = data.getApplication;
          setContentsData(response);
          setSelectedApplication(response);
          setContentLoading(false);
          setReadyForAnalyticEvents(true);
          setApplication(response);
          if (
            response.mickeyMouse &&
            response.creditLineIncrease === CreditLineStatus.ELIGIBLE &&
            applicationFlow.requestAmount > response.balanceAvailable
          ) {
            setShowRevampMickeyMouseModal(true);
          }
          return;
        } else {
          setContentLoading(false);
        }
      } else {
        throw new Error('Approval Page - application id not found.');
      }
    } catch (err) {
      captureException(err, {
        message: 'Approval Page - fetch application list with search error',
        error: err,
      });
      setContentLoading(false);
    }
  };

  const requestCreditLineIncrease = async () => {
    if (contentsData?.mickeyMouse) {
      setShowMickeyMouseModal(true);
      sendActionSegmentEvent(WPQSegmentNames.mickeyMouseRequestIncreaseClicked);
    } else if (shouldShowFakeCreditLineIncreaseModal) {
      sendActionSegmentEvent(WPQSegmentNames.requestIncreaseClicked);
      setIsRequestLargeApprovalModalVisible(true);
    } else {
      sendActionSegmentEvent(WPQSegmentNames.requestIncreaseClicked);
      await pollLatestCreditLineIncrease('APPROVAL_SCREEN');
    }
  };

  const iconName = () => {
    return `party`;
  };

  const titleText = () => {
    return translate('approval.title');
  };

  const hideRequestLargeApprovalModal = () => {
    setIsRequestLargeApprovalModalVisible(false);
    disableRequestLargerApprovalButton();
  };

  const preferredLocationText = (): JSX.Element | string => {
    const merchantId = contentsData?.merchantId;
    const findLocation = locations?.find((loc) => loc.id === merchantId);

    if (selectedLocation || findLocation) {
      const { name, address } = findLocation || selectedLocation || {};
      return (
        <LocationText>
          {name} - {address?.city?.name ?? address?.city}
        </LocationText>
      );
    } else if (locations?.length === 1) {
      const { name, address } = locations[0] || {};
      return (
        <LocationText>
          {name} - {address?.city?.name ?? address?.city}
        </LocationText>
      );
    }
    return '';
  };

  const approvalPartialOrDeniedText = () => {
    if (
      creditLineIncreaseItem &&
      (creditLineIncreaseItem.source === 'DEFAULT_HIGH_LINE' || creditLineIncreaseItem.source === 'NORMAL') &&
      creditLineIncreaseItem.subStatus === 'PARTIAL'
    ) {
      return (
        <Subtitle m={'0px 0px 14px 0px'} textAlign="center">
          {translate('approval.cli.success', {
            balanceAvailable: FormatPrice(application?.balanceAvailable),
          })}
        </Subtitle>
      );
    }

    if (
      creditLineIncreaseItem &&
      creditLineIncreaseItem.source === 'NORMAL' &&
      creditLineIncreaseItem.status === 'DENIED'
    ) {
      return (
        <Subtitle m={'0px 0px 14px 0px'} textAlign="center">
          {translate('approval.cli.denied', {
            balanceAvailable: FormatPrice(application?.balanceAvailable),
          })}
        </Subtitle>
      );
    }

    return null;
  };

  const posCheckoutHandler = async () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked);
    if (flowType === 'PointOfSalesFlow') {
      navigate('pos-checkout');
    } else if (flowType === 'ApplyCheckoutFlow') {
      setContentLoading(true);
      const params = {
        merchantId: selectedLocation?.id,
        applicationId: application?.id,
        purchaseAmount: Math.min(sessionData.treatmentAmount, Number(contentsData?.balanceAvailable)),
        selfCheckout: true,
        channel: 'APPLY_CHECKOUT',
      };
      const { data } = await createLoanRequest({ variables: { input: { ...params } } });
      if (data.createLoan?.data) {
        await getApplication(data.createLoan?.data?.applicationId);
        setLoan(data?.createLoan?.data);
        loanStatusService(data.createLoan?.data, organization?.slug);
      } else {
        alert.info(`${translate('approval.error.default')}`);
      }
      setContentLoading(false);
      navigate(`choose-plan`);
    }
  };

  const handleContinue = () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked);
    navigate('preview-payment');
  };

  const handleExperimentContinue = () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked);
    navigate('what-happens-next');
  };

  const openWhatDoesMeanModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatDoesThisMeanClicked);
    setWhatdoesMeanModalVisibility(true);
  };

  const closePartialApprovalModal = () => {
    setIsPartialApprovalModalVisible(false);
  };

  const openPartialApprovalModal = () => {
    setIsPartialApprovalModalVisible(true);
  };

  if (contentLoading) {
    return (
      <CenterLoading>
        <Loading size={40} />
      </CenterLoading>
    );
  }

  if (isAlle) {
    return <AlleApproval />;
  }
  return (
    <>
      {shouldShowNewApprovalScreen ? (
        <>
          <Container showBackButton={false}>
            {confettiActive ? (
              <ConfettiContainer>
                <Confetti
                  width={600}
                  height={containerRef.current?.clientHeight}
                  numberOfPieces={300}
                  colors={confettiColors}
                  recycle={false}
                />
              </ConfettiContainer>
            ) : null}
            <ContentContainer removeBottomPadding={isFakeRequestIncreaseExprimentEnabled} isMobile={isMobile}>
              <InnerContainer>
                <Icon src={iconName()} />
                <Title m={'0px 0px 0px 16px'}>{titleText()}</Title>
                {approvalPartialOrDeniedText()}
              </InnerContainer>
              {isNewApprovalCardEnable ? (
                <>
                  <ApprovalCardDescription>
                    <Trans
                      i18nKey="approvalCard.subtitle"
                      values={{ name: organization?.name }}
                      components={{
                        bold: <strong />,
                      }}
                    ></Trans>
                  </ApprovalCardDescription>
                  <ApprovalCard expireAt={contentsData?.expireAt} balanceAvailable={contentsData?.balanceAvailable} />
                  <AddWallet openThanksModal={setThanksForClickingModalVisibility} page="approval" />
                  <SendApprovalEmail openThanksModal={setThanksForClickingModalVisibility} page="approval" />
                </>
              ) : (
                <>
                  {isFakeRequestIncreaseExprimentEnabled ? (
                    <ApprovalContainer isMobile={isMobile} isExperimental>
                      <Approved>
                        <span>{translate('approval.approved')}</span>
                        <Icon src={'check_big'} width={21} />
                      </Approved>
                      <ApprovalText bold size="20">
                        {borrower?.firstName} {borrower?.lastName}
                      </ApprovalText>
                      <ApprovalText bold size="12" marginTop="16">
                        {translate('approval.upTo')}
                      </ApprovalText>
                      <ApprovalText bold size="32">
                        {contentsData?.balanceAvailable && FormatAmount(contentsData?.balanceAvailable)}
                      </ApprovalText>
                      <ApprovalText bold size="20">
                        {organization?.name}
                      </ApprovalText>
                      <ExpireDate>
                        {translate('approval.expires')} {dayjs(contentsData?.expireAt).format(EXPIRED_DATE_FORMAT)}
                      </ExpireDate>
                      {creditLineIncreaseItem?.status === 'REVIEW' &&
                        !STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) &&
                        !application?.mickeyMouse && (
                          <CreditLineIncreaseReview>
                            {translate('approval.cli.review', {
                              requested: FormatAmount(creditLineIncreaseItem?.amount?.requested),
                            })}
                          </CreditLineIncreaseReview>
                        )}
                      {!STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
                        <LocationInfoContainer noMargin={!(preferredLocationText?.() === '' && locations?.length < 1)}>
                          {preferredLocationText()}
                          {locations?.length > 1 ? (
                            <ChangeLocationButton onClick={changeLocationHandler}>
                              {translate('approval.changeLocation')}
                            </ChangeLocationButton>
                          ) : null}
                        </LocationInfoContainer>
                      ) : null}
                    </ApprovalContainer>
                  ) : (
                    <ApprovalContainer isMobile={isMobile}>
                      <Approved>
                        <span>{translate('approval.approved')}</span>
                        <Icon src={'check_big'} width={21} />
                      </Approved>
                      <ApprovalText bold size="20">
                        {borrower?.firstName} {borrower?.lastName}
                      </ApprovalText>
                      <ApprovalText size="18">{FormatPhoneNumber(borrower?.phone)}</ApprovalText>
                      <ApprovalText size="12" marginTop="16">
                        {translate('approval.upTo')}
                      </ApprovalText>
                      <ApprovalText bold size="32">
                        {contentsData?.balanceAvailable && FormatAmount(contentsData?.balanceAvailable)}
                      </ApprovalText>
                      <ApprovalText bold size="20">
                        {organization?.name}
                      </ApprovalText>
                      <ExpireDate>
                        {translate('approval.expires')} {dayjs(contentsData?.expireAt).format(EXPIRED_DATE_FORMAT)}
                      </ExpireDate>
                      {creditLineIncreaseItem?.status === 'REVIEW' &&
                        !STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) &&
                        !application?.mickeyMouse && (
                          <CreditLineIncreaseReview>
                            {translate('approval.cli.review', {
                              requested: FormatAmount(creditLineIncreaseItem?.amount?.requested),
                            })}
                          </CreditLineIncreaseReview>
                        )}
                      {!STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
                        <LocationInfoContainer noMargin={!(preferredLocationText?.() === '' && locations?.length < 1)}>
                          {preferredLocationText()}
                          {locations?.length > 1 ? (
                            <ChangeLocationButton onClick={changeLocationHandler}>
                              {translate('approval.changeLocation')}
                            </ChangeLocationButton>
                          ) : null}
                        </LocationInfoContainer>
                      ) : null}
                    </ApprovalContainer>
                  )}
                </>
              )}

              {!isNewApprovalCardEnable && (
                <>
                  <WhatDoesMeanContainer onClick={openWhatDoesMeanModal}>
                    <Icon src={'info_circle_green'} width={21} />
                    <span>{translate('approval.whatDoesMean')}</span>
                  </WhatDoesMeanContainer>
                </>
              )}
              {isFakeRequestIncreaseExprimentEnabled && <PreviewPaymentOptionsWidget />}
              {flowType === 'ApplyCheckoutFlow' ? (
                Number(contentsData?.balanceAvailable) >= sessionData.treatmentAmount ? (
                  <Grid container alignItems="center" wrap="nowrap" gap="8px">
                    <Icon height={24} width={24} src="circle_check_outline_black" />
                    <Subtitle>
                      {translate('fullyCoversYou', {
                        treatmentAmount: FormatAmount(sessionData.treatmentAmount),
                      })}
                    </Subtitle>
                  </Grid>
                ) : (
                  <Grid direction="column" container gap="16px" mt={5}>
                    <Subtitle>
                      {translate('approval.partOfYour', {
                        treatmentAmount: FormatAmount(sessionData.treatmentAmount),
                      })}
                    </Subtitle>
                    <Grid container item alignItems="center" wrap="nowrap" gap="8px">
                      <Icon height={24} width={24} src="help_circle_fill" />
                      <Subtitle cursor="pointer" onClick={openPartialApprovalModal}>
                        <u>{translate('approval.partialApproval')}</u>
                      </Subtitle>
                    </Grid>

                    <PartialApprovalModal show={isPartialApprovalModalVisible} hideModal={closePartialApprovalModal} />
                  </Grid>
                )
              ) : null}

              {STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
                <>
                  <ButtonContainer isMobile={isMobile}>
                    <Button onClick={posCheckoutHandler}>{translate('buttons.continue')}</Button>
                  </ButtonContainer>
                </>
              ) : (
                <>
                  {isFakeRequestIncreaseExprimentEnabled ? (
                    <StickyButtonContainer isMobile={isMobile}>
                      <Button onClick={handleExperimentContinue}>{translate('buttons.continue')}</Button>
                      <Button
                        secondary={true}
                        onClick={requestCreditLineIncrease}
                        loading={loading}
                        disabled={loading || isRequestLargerApprovalButtonDisabled}
                      >
                        {translate('buttons.requestLargerApproval')}
                      </Button>
                    </StickyButtonContainer>
                  ) : (
                    <ButtonContainer isMobile={isMobile}>
                      <Button onClick={handleContinue}>{translate('buttons.continue')}</Button>
                      {isEligibleForCreditLineIncrease && (
                        <Button
                          secondary={true}
                          onClick={requestCreditLineIncrease}
                          loading={loading}
                          disabled={loading}
                        >
                          {translate('buttons.requestLargerApproval')}
                        </Button>
                      )}
                    </ButtonContainer>
                  )}

                  {showCreditLineIncreaseModal ? (
                    <CreditLineIncreaseModal
                      show={showCreditLineIncreaseModal}
                      hideModal={hideCreditLineIncreaseModal}
                      creditLineIncrease={creditLineIncreaseItem}
                    />
                  ) : null}
                </>
              )}
            </ContentContainer>

            {showMickeyMouseModal ? (
              <MickeyMouseModal show={showMickeyMouseModal} hideModal={hideMickeyMouseModal} />
            ) : null}
            {showRevampMickeyMouseModal ? (
              <RevampMickeyMouseModal show={true} hideModal={hideRevampMickeyMouseModal} />
            ) : null}
            {whatdoesMeanModalVisibility ? <WhatDoesMeanModal show={true} hideModal={hideWhatDoesMeanModal} /> : null}
            {isRequestLargeApprovalModalVisible && (
              <RequestLargerApprovalModal
                application={contentsData}
                show={true}
                hideModal={hideRequestLargeApprovalModal}
              />
            )}
            {thanksForClickingModalVisibility ? (
              <ThanksForClickingModal show={true} hideModal={hideThanksForClicking} />
            ) : null}
          </Container>
        </>
      ) : (
        <OldApproval />
      )}
    </>
  );
};

export default Approval;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WhatDoesMeanContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 24px;
  span {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }
`;

const CenterLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;

const ApprovalContainer = styled.div<{ isMobile: boolean; isExperimental?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 0;
  margin-top: 24px;
  padding: ${(props) => (props.isExperimental ? '16px 24px' : '24px')};
  border-radius: 24px;
  opacity: 0px;
  border: ${(props) => (!props?.isMobile ? '2px solid #e7e9ea' : '0px')};
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 36px 67px 0 rgba(0, 0, 0, 0.07);
`;

const ChangeLocationButton = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
`;

const ApprovalCardDescription = styled.span`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 8px 0px 24px;
`;

const ContentContainer = styled.div<{ isMobile?: boolean; removeBottomPadding: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 0px 24px 20px 24px;
  padding-bottom: ${(props) => (props?.removeBottomPadding ? '0px' : '20px')};
  margin-top: 0px;
  padding-top: 15px;
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
`;

const ButtonContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
  padding: 0px 24px 14px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: ${(props) => (props?.isMobile ? 'none' : 'inset 0 1px 0 #dadada')};
  padding-top: ${(props) => (props?.isMobile ? '0px' : '24px')};
`;

const StickyButtonContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${theme.main.white};
  padding: 0px 24px 14px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: inset 0 1px 0 #dadada;
  padding-top: 24px;
  position: sticky;
  bottom: 0;
  margin: 0 -24px 0;
  z-index: 1;
`;

const LocationText = styled.span`
  display: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
`;

const LocationInfoContainer = styled.div<{ noMargin?: boolean }>`
  margin-top: 24px;
  margin-bottom: 24px;

  ${({ noMargin }) =>
    noMargin &&
    `
       margin: 0;
    `}
`;

const CreditLineIncreaseReview = styled.span`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-top: 8px;
`;

const Approved = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: center;
    color: ${theme.main.green};
  }
`;
const ApprovalText = styled.span<{ bold?: boolean; size: string; marginTop?: string }>`
  font-size: ${(props) => `${props?.size}px`};
  font-weight: ${(props) => (props?.bold ? 700 : 400)};
  text-align: center;
  margin-top: ${(props) => `${props?.marginTop}px`};
`;

const ExpireDate = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: center;
  color: ${theme.main.grey20};
`;
