import React, { useEffect, useRef, useState } from 'react';
import Rox from 'rox-browser';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

import { theme } from 'config/theme';
import { useMerchantsDetail } from 'lib/hooks/useMerchantDetail';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { FormatAmount, FormatPhoneNumber, Redirect } from 'lib/utils';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import AlleChoosePlan from 'pages/ChoosePlan/AlleChoosePlan';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

let segmentEventSentCount = 0;

const ChoosePlan = () => {
  const alert = useAlert();
  const { navigate } = useNavigation();
  const { captureException, setTag } = useSentry();
  const { getProductCalculation } = useProductCalculation();
  const { trackPage, trackGroup, trackIdentify } = useSegment();
  const { t: translate } = useTranslation();
  const { organization, borrower, isAlle, setSessionApiData } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const loan = useStore((state) => state?.loan);
  const application = useStore((state) => state?.application);

  const { flags } = useFlags();
  const { fetchMerchantDetail, address, phone, name } = useMerchantsDetail();
  Rox.setCustomNumberProperty('organization_id', Number(organization?.id));
  Rox.setCustomStringProperty('organization_id_str', organization?.id?.toString());

  Rox.setCustomStringProperty('rox.distinct_id', borrower?.id);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutLandingLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setTag('application', application?.id || loan?.applicationId);
    setTag('loan', loan?.id);
  }, []);

  const navigateToPlan = async () => {
    const applicationId = application?.id || loan?.applicationId;
    sendActionSegmentEvent(WPQSegmentNames.checkoutLandingChooseClicked);
    try {
      const amount = loan?.purchaseAmount;

      setLoading(true);
      const response = await getProductCalculation(applicationId, amount);
      if (response && response.length > 0) {
        setLoading(false);

        if (flags?.SCREEN?.spanishIssueAcknowledgement.isEnabled()) {
          navigate(`acknowledgement`);
        } else {
          navigate(`plans`);
        }
      } else {
        alert.info(`${translate('choosePlan.error.default')}`);
      }
    } catch (err) {
      captureException('Product Calculation Error', {
        error: err,
        page: 'Choose Plan',
        message: 'Product Calculation Error',
        loanId: loan?.id || 'No Loan',
        applicationId: application?.id || 'No Application',
      });
      setLoading(false);
      navigate(``);
    }
  };

  const gotoCall = () => {
    Redirect(`tel:${phone || organization?.phone}`, '_self');
  };

  const getMerchantsDetail = async () => {
    const merchantId = loan?.merchantId;
    const downPaymentEnabled = flags.CHECKOUT.isDownPaymentEnabled.isEnabled() || false;
    const newProductSelection = flags.SCREEN.newProductSelection.isEnabled() || false;

    try {
      await fetchMerchantDetail(merchantId);
      setSessionApiData({
        featureFlags: {
          downPaymentEnabled,
          newProductSelection,
        },
      });
      handleSegmentEvents(loan?.id, borrower, merchantId, downPaymentEnabled);
    } catch (err) {
      captureException('Get Merchants Detail Error', {
        error: err,
        page: 'Choose Plan',
        message: 'Get Merchant Detail Error',
        loanId: loan?.id || 'No Loan',
        applicationId: application?.id || 'No Application',
        merchantId: merchantId || 'No Merchant',
      });
    }
  };

  const handleSegmentEvents = (loanId, borrower, merchantId, downPaymentEnabled) => {
    const applicationId = application?.id || loan?.applicationId;

    if (borrower?.id && merchantId && segmentEventSentCount < 1) {
      trackIdentify({ ...borrower });
      trackPage('choosePlan', {
        application: 'checkout',
        applicationId,
        loanId,
        borrower_id: borrower?.id,
      });
      trackGroup(`mer-${merchantId}`, {
        application: 'checkout',
        merchantId,
        organizationId: organization?.id,
        variable_down_payment_enabled: downPaymentEnabled,
      });
      segmentEventSentCount++;
    }
  };

  const getAmount = () => {
    try {
      const amount = loan?.purchaseAmount;
      return `${FormatAmount(amount)}`;
    } catch (err) {
      // Err
    }
  };

  useEffect(() => {
    getMerchantsDetail?.();
  }, []);

  if (isAlle) {
    return (
      <AlleChoosePlan
        loading={loading}
        gotoCall={gotoCall}
        getAmount={getAmount}
        navigateToPlan={navigateToPlan}
        address={address}
        phone={phone}
        name={name}
      />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'payment'} />
          <Title m={'10px 0px 24px 0px'} textAlign="center">
            {translate('choosePlan.main.title')}
          </Title>

          <SuccessContainer>
            <ApprovalContainer>
              <Title fontSize={theme.constants.m} m={'10px 0px 0px 0px'} textAlign="center">
                {translate('choosePlan.main.subTitle')}
              </Title>

              <Title fontSize={theme.constants.xl} color={theme.main.green} m={'10px 0 0 0'} textAlign="center">
                {getAmount()}
              </Title>

              <Subtitle m={'24px 0px 10px 0px'} textAlign="center" fontWeight="700">
                {name || organization?.name}
              </Subtitle>

              {address && (
                <Subtitle m={'0 0 0 0'} textAlign="center">
                  {address?.street} , {address?.city}, {address?.state?.name}
                </Subtitle>
              )}

              {(phone || organization?.phone) && (
                <Subtitle m={'0 0 0 0'} textAlign="center" onClick={gotoCall} cursor="pointer">
                  {FormatPhoneNumber(phone || organization?.phone)}
                </Subtitle>
              )}
              <Gap />
              <Button data-testid="submit" onClick={navigateToPlan} loading={loading} disabled={loading}>
                {translate('buttons.chooseMyPlan')}
              </Button>
            </ApprovalContainer>
          </SuccessContainer>
        </InnerContainer>
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>
              {translate('choosePlan.main.info', {
                organizationName: organization?.name,
              })}
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default ChoosePlan;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const InfoText = styled.span`
  color: ${(props) => props.theme.main.midnightBlue};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-left: 12px;
`;

const SuccessContainer = styled.div`
  width: 98%;
  margin-left: 1%;
  filter: drop-shadow(0px 0px 2px #d1d1d1);
`;

const ApprovalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  border-radius: 8px 8px 0 0;
  padding: 24px 16px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-filter: drop-shadow(3px 3px 1px black);
  filter: drop-shadow(0px 0px 5px white);

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0px;
    content: '';
    display: block;
    background: radial-gradient(circle, #ffffff00 20px, #ffffff 20px);
    background-size: 35px 40px;
    background-position: -20px -20px;
    width: 100%;
    height: 40px;
    z-index: -1;
  }
`;

const Gap = styled.div`
  height: 24px;
`;
