import { useEffect } from 'react';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { AlleFullScreenLoading } from 'lib/components';
import { ALLE_COGNITO_PARAMS } from 'lib/constants';
import { API_URL } from 'config';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';

export const AlleCognito = () => {
  const { navigate } = useNavigation();
  const { startCheckApplicationStatus } = useSSEContext();
  const { borrower, defaultLanguage, setSessionApiData } = useStore.getState() || {};

  const url = `${API_URL}/v1/borrowers/${borrower?.id}/cognito-iframe?language=${defaultLanguage}`;

  let windowContext: Window;
  let isFlowCompleted = false;

  useEffect(() => {
    windowContext = window?.open(url, 'cognito', ALLE_COGNITO_PARAMS) || window;

    window?.addEventListener('message', handleCognitoWindowEvent, false);
    const intervalId = setInterval(checkWindowStatus, 1000);
    return () => {
      window?.removeEventListener('message', handleCognitoWindowEvent);
      clearInterval(intervalId);
    };
  }, []);

  const checkWindowStatus = () => {
    if (!isFlowCompleted && windowContext?.closed) {
      navigate('id-review');
    }
  };

  const handleCognitoWindowEvent = async (event) => {
    const res = JSON.parse(event?.data);
    console.warn('Event name:', res?.event);
    if (res?.event === 'closed') {
      isFlowCompleted = true;
      setSessionApiData({
        isCognitoCompleted: true,
      });
      windowContext?.close();
      startCheckApplicationStatus({});
    }
  };

  return <AlleFullScreenLoading isTextPresent={false} />;
};
export default AlleCognito;
