import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { FaqItem, GetFaqListParams } from './types';

const getFaqManualReviewList = ({ translate }: GetFaqListParams): FaqItem[] => {
  return [
    {
      title: translate('FAQ.whatDoesThisMean.question'),
      content: translate('FAQ.whatDoesThisMean.answer'),
    },
    {
      title: translate('FAQ.howDoIKnowIfIHaveBeenApproved.question'),
      content: translate('FAQ.howDoIKnowIfIHaveBeenApproved.answer'),
    },
    {
      title: translate('FAQ.IfIAmDeniedCanIAppealThisDecision.question'),
      content: translate('FAQ.IfIAmDeniedCanIAppealThisDecision.answer'),
    },
  ];
};

const NewFaqManualReviewList = () => {
  const { t: translate } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>('panel0');
  const faqManualReviewList = getFaqManualReviewList({ translate });

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  return (
    <ContentContainer noMargin noPadding>
      <InfoBox>
        <Typography mb={1} fontSize={16} fontWeight="700" variant="h2">
          {translate('applicationReview.infoBoxTitle')}
        </Typography>
        <Typography fontSize={14} fontWeight="400" variant="body2">
          {translate('applicationReview.infoBoxSubtitle')}
        </Typography>
      </InfoBox>
      <Title m={'10px 0 0 0'}>{translate('faqManualReview.title')}</Title>
      <Accordion data={faqManualReviewList} onChange={handleChange} expanded={expanded} />
    </ContentContainer>
  );
};

const InfoBox = styled(Box)`
  background: ${(props) => props.theme.main.greenLight};
  border: 2px solid ${(props) => props.theme.main.greenLightDark};
  border-radius: 8px;
  padding: 12px 16px 16px;
  margin-bottom: 40px;
`;

export default NewFaqManualReviewList;
