import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'config/theme';
import { IPlan } from 'pages/Plans/types';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

interface PlanBoxProps {
  isActive: boolean;
  plan: IPlan;
  setActivePlan: (plan: IPlan) => void;
  totalLength: number;
  approvalScreen?: boolean;
}

export const PlanBox = ({ isActive, plan, setActivePlan, totalLength, approvalScreen }: PlanBoxProps) => {
  const { t: translate } = useTranslation();
  const { sendActionSegmentEvent } = useSegmentContext();

  const onProductBoxClick = () => {
    setActivePlan(plan);
    if (approvalScreen) {
      sendActionSegmentEvent(`WPQ.OUTCOME.APPROVAL_${plan?.product?.term}_MONTH_PLAN_CLICKED`);
    } else {
      sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${plan?.product?.term}_MONTH_PLAN_CLICKED`);
    }
  };

  return (
    <ProductContainer totalLength={totalLength}>
      <Product isActive={isActive} onClick={onProductBoxClick}>
        <ProductTitle isActive={isActive}>{plan?.product?.term}</ProductTitle>
        <ProductDescription isActive={isActive}>{translate('plans.box.month')}</ProductDescription>
      </Product>
    </ProductContainer>
  );
};

const boxWidthCalculation = (totalLength) => {
  let boxWidth;
  if (totalLength === 5) {
    boxWidth = 19;
  } else {
    boxWidth = 25;
  }
  return `${boxWidth}%`;
};

const ProductContainer = styled.div<{ totalLength: number }>`
  width: ${(props) => boxWidthCalculation(props?.totalLength)};
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
`;

const Product = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 56px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => (props?.isActive ? theme.main.green : theme.main.white)};
  border: ${(props) => (props?.isActive ? `2px solid ${theme.main.green}` : `2px solid ${theme.main.greenWhite}`)};
`;

const ProductTitle = styled.div<{ isActive: boolean }>`
  font-size: ${(props) => (props?.isActive ? `32px` : `24px`)};
  line-height: ${(props) => (props?.isActive ? `32px` : `24px`)};
  font-weight: 700;
  text-align: center;
  color: ${(props) => (props?.isActive ? theme.main.white : theme.main.black)};
`;

const ProductDescription = styled.div<{ isActive: boolean }>`
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: ${(props) => (props?.isActive ? theme.main.white : theme.main.black)};
`;
