import styled from 'styled-components';

import { Icon } from 'lib/components';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';

interface Props {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  paymentMethod: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedPaymentMethod: any;
  selectPaymentMethod: (id: number) => void;
  isPreferred?: boolean;
}

interface IconName {
  type: string;
  name: string;
}

const Icons: IconName[] = [
  { type: 'CARD', name: 'credit_card' },
  { type: 'ACH', name: 'bank' },
  { type: 'RCC', name: 'remote-check' },
];

const PaymentMethodCard = ({
  paymentMethod,
  selectedPaymentMethod,
  selectPaymentMethod,
  isPreferred = false,
}: Props) => {
  const { t: translate } = useTranslation();
  const { id, type, achAccount, storedCard } = paymentMethod || {};
  const cardNumber =
    type === 'CARD' ? storedCard?.last4 : achAccount?.accountNumber?.substring(achAccount?.accountNumber?.length - 4);

  const cardTitle = type === 'CARD' ? `${storedCard?.network} - ${storedCard?.type}` : `${achAccount?.bankName}`;

  const IconNames = () => {
    const isActive = selectedPaymentMethod?.id === id;
    const findIcon: IconName | undefined = Icons?.find((ic) => ic?.type === type);

    if (isActive) {
      return `active_${findIcon?.name}`;
    } else {
      return findIcon?.name || '';
    }
  };

  const selectPaymentMethodHandler = () => {
    selectPaymentMethod(paymentMethod);
  };

  return (
    <Container
      data-testid="payment-method-card"
      onClick={selectPaymentMethodHandler}
      isActive={Number(selectedPaymentMethod?.id) === Number(id)}
    >
      <StyledIcon src={IconNames()} width={20} height={20} />
      <ContentContainer>
        <Title isActive={Number(selectedPaymentMethod?.id) === Number(id)}>{cardTitle}</Title>
        <Subtitle isActive={Number(selectedPaymentMethod?.id) === Number(id)}>************ {cardNumber}</Subtitle>
      </ContentContainer>
      {isPreferred ? <PreferredContainer>{translate('downPayment.modal.preferred')}</PreferredContainer> : null}
    </Container>
  );
};

export default PaymentMethodCard;

const PreferredContainer = styled.div`
  font-size: 10px;
  line-height: 11px;
  color: ${(props) => props.theme.main.green};
  background: #e6f9f2;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 4px 6px 5px;
  gap: 4px;
  border-radius: 16px;
`;

const Container = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${theme.main.grayColor};
  border-radius: 4px;
  padding: 12px 18px;
  box-sizing: border-box;
  margin-bottom: 8px;

  ${({ isActive }) =>
    isActive &&
    `
    border: 2px solid ${theme.main.green};
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  flex: 1;
`;

const Title = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.isActive ? theme.main.textColor : theme.main.grayColor)};
`;

const Subtitle = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 10px;
  line-height: 13px;
  color: ${(props) => (props.isActive ? theme.main.textColor : theme.main.grayColor)};
`;

const StyledIcon = styled(Icon)`
  color: ${(props) => props.theme.main.green};
`;
