import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { API_URL, APP_ENV } from 'config';
import { AlleFullScreenLoading, Loading } from 'lib/components';
import { CREDIT_CARD_DOWN_PAYMENT, LANGUAGES } from 'lib/constants';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';

export const PaymentMethodConnect = ({ onConnect, isDemo, view = 'wpq' }) => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { isAlle, features } = useStore();

  const [showIframe, setShowIFrame] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const iframeRef: any = useRef();
  const hasCreditCardPaymentActive = features?.includes(CREDIT_CARD_DOWN_PAYMENT);

  useEffect(() => {
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  const getIframeUrl = () => {
    const borrower = useStore((state) => state.borrower);
    const application = useStore((state) => state.application);
    const { defaultLanguage } = useStore((state) => state.defaultLanguage);

    const borrowerId = borrower?.id || application?.borrowerId;

    if (borrowerId) {
      return `${API_URL}/v1/borrowers/${borrowerId}/payment-methods/iframe?view=${view}&demo=${isDemo}&language=${
        defaultLanguage || LANGUAGES[0]
      }`;
    } else {
      captureException(
        {},
        {
          errorContext: 'borrowerId Not Found => PaymentMethodConnect',
          borrower: borrower || 'No Borrower',
          application: application || 'No Application',
        },
      );
      return '';
    }
  };

  const onIFrameLoad = () => {
    setTimeout(() => {
      // For some reason the iframe is not set loading in iframe event
      setLoading(false);
    }, 2000);

    if (isDemo || APP_ENV === 'sandbox') {
      setTimeout(() => {
        iframeRef?.current?.contentWindow?.postMessage(
          {
            eventName: 'fillData',
            scannedCard: {
              cardNumber: '5111201111111117',
              expireMonth: 11,
              expireYear: 33,
              cvv: 123,
            },
          },
          '*',
        );
      }, 2000);
    }
  };

  const toggleIframe = () => {
    setShowIFrame(false);
    setTimeout(() => {
      setShowIFrame(true);
    }, 350);
  };

  const onIframeLoadError = (error) => {
    captureException('Add Card Iframe Load Error', { error });
  };

  const receiveMessage = ({ origin, data }) => {
    if (origin === API_URL) {
      const iFrameEvent = JSON.parse(data);

      switch (iFrameEvent.status) {
        case 'expired':
          break;
        case 'loaded':
          setLoading(false);
          break;
        case 'error':
          alert.info(iFrameEvent.message);
          const { borrower } = useStore.getState() || {};
          if (!iFrameEvent?.message?.includes('Unprocessable')) {
            captureException('Add Card Iframe Error', {
              iframeEventData: iFrameEvent,
              borrower,
            });
          }
          break;
        case 'success':
          const { type, network } = iFrameEvent?.paymentMethod?.storedCard || {};
          if (!hasCreditCardPaymentActive && !(type === 'DEBIT' || network === 'AMEX')) {
            alert.info(translate('paymentMethodConnect.info.message'));
            toggleIframe();
          } else {
            onConnect(iFrameEvent, 'debit');
          }
          break;
        case 'loading':
          break;
      }
    }
  };

  return (
    <Container isAlle={isAlle}>
      {loading &&
        (isAlle ? (
          <AlleFullScreenLoading isTextPresent={false} />
        ) : (
          <CenterLoading>
            <Loading size={40} />
          </CenterLoading>
        ))}
      {showIframe ? (
        <iframe
          ref={iframeRef}
          id="add-payment-method"
          data-testid="the-frame"
          title={translate('addCard.iframeTitle')}
          width="100%"
          height="100%"
          frameBorder="0"
          src={getIframeUrl()}
          onLoad={onIFrameLoad}
          onError={onIframeLoadError}
          data-hj-allow-iframe=""
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div<{ isAlle: boolean }>`
  height: 320px;
  margin-top: 5px;
`;

const CenterLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
