import { gql } from '@apollo/client/core';

export const FETCH_BANNER = gql`
  query fetchBanner {
    fetchBanner {
      showForPatient
      patientText
    }
  }
`;
