import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { emailPattern } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useDidMountEffect } from 'lib/hooks/useDidMountEffect';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import useStore from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';

const EmailInfo = ({ loading, setMissingInfo }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  const {
    control,
    formState: { isValid } = {},
    clearErrors,
    watch,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.defaultHighLineEmailLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const emailValue = watch('email');

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        next();
      }
    }
  };

  const next = async () => {
    sendActionSegmentEvent(WPQSegmentNames.defaultHighLineEmailContinue);
    setMissingInfo(String(emailValue), 'EMAIL');
  };

  const onFormValueChange = (e, callback) => {
    clearErrors('email');
    const searchTerm = '@';
    const value = e?.target?.value;

    let isActive = false;
    if (value) {
      if (value.indexOf(searchTerm) >= 0 && emailPattern.test(value)) {
        isActive = true;
      }
    }
    setIsFormValid(isActive);
    callback(e);
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'mail'} />
        <Title m={'10px 0px 8px 0px'}>{translate('defaultHighLine.view.email.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('defaultHighLine.view.email.subTitle')}</Subtitle>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: translate('defaultHighLine.view.email.error'),
              },
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email"
                  variant="filled"
                  data-testid="email"
                  data-neuro-label="email"
                  label={translate('defaultHighLine.view.email.label')}
                  type="text"
                  value={value}
                  onChange={(e) => onFormValueChange(e, onChange)}
                  onKeyDown={checkKeyDown}
                  onFocus={() => {
                    sendActionSegmentEvent(WPQSegmentNames.defaultHighLineEmailClicked);
                  }}
                  onBlur={(e) => {
                    sendActionSegmentEvent(WPQSegmentNames.defaultHighLineEmailFilled, {
                      metaData: e.target.defaultValue,
                    });
                  }}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={next}
          disabled={!isFormValid || (!emailValue && emailValue !== '') || loading}
          loading={loading}
        >
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default EmailInfo;
