export const FormatPhoneNumber = (str: string) => {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

export const FormatPrice = (price: number | string | undefined) => {
  if (price) {
    return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return price;
};

export const FormatAmount = (amount: number) => {
  if (amount && typeof amount === 'number') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(amount);
  }
  return '$0.00';
};

export const GetOrdinal = (day: number): string => {
  if (day >= 11 && day <= 13) {
    return day + 'th';
  }

  switch (day % 10) {
    case 1:
      return day + 'st';
    case 2:
      return day + 'nd';
    case 3:
      return day + 'rd';
    default:
      return day + 'th';
  }
};

export const generateSevenDigitNumber = () => {
  const randomNumber = Math.floor(2000000 + Math.random() * 8000000);
  return randomNumber;
};
