export const theme = {
  main: {
    darkBlue: '#0e1f2e',
    midnightBlue: '#0E202F',
    midnightBlue20: '#CFD2D5',
    midnightBlue40: '#9FA6AC',
    midnightBlue60: '#6e7982',
    white: '#ffffff',
    black: '#000000',
    green: '#00c37d',
    lightGreen: '#E6F9F2',
    greenWhite: '#CCF3E5',
    greenLight: '#F2FFFB',
    disabledBtn: '#777777',
    textColor: '#0E202F',
    borderColor: '#979797',
    grayColor: '#56636D',
    demoModeColor: '#EBC075',
    pinkColor: '#EC3360',
    lightGrayColor: '#808080',
    redColor: '#ff0000',
    infoColor: '#e6cb72',
    darkGrayColor: '#f2f4f5',
    alleBlack: '#090909',
    black10: '#111111',
    black20: '#020202',
    beige: '#EFE1DD',
    slateGray: '#787676',
    silverGray: '#d1d1d1',
    midnightGray: '#1F1F1F',
    pinkishBeige: '#DFC2BB',
    grayishBeige: '#b3b0ae',
    mutedRedBrown: '#C08676',
    grey: '#9ea6ac',
    grey20: '#879097',
    lightGrayishBeige: '#CFCBCA',
    whiteGray: '#f3f4f5',
    greenLightDark: '#CCF3E5',
    blueLight5: '#F2F4F5',
    greenMain: '#00C37D',
  },
  constants: {
    s: '8px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '48px',
    zIndex: 99,
  },
  size: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    mobileXl: '680px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
  fonts: {
    primary: 'Open Sans',
  },
};

export const confettiColors: string[] = ['#00bb71', '#1D291D', '#C5F1E1', '#B1BC74', '#00bb71'];
