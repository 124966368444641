import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components';
import { DATE_FORMAT } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { calculatePaymentFeeAmount, FormatAmount } from 'lib/utils';

import AgreementModal from './AgreementModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { theme } from 'config/theme';

type SummaryCardProps = {
  useElegantTheme?: boolean;
};

const SummaryCard = ({ useElegantTheme }: SummaryCardProps) => {
  const { t: translate } = useTranslation();
  const { loan, downPaymentMethod, installmentMethod, selectedPlan, agreement, paymentMethodFees } = useStore();
  const { sendActionSegmentEvent } = useSegmentContext();

  const { downPaymentAmount, installmentAmount, paymentDates, financeCharge, product, promo } = selectedPlan;
  const [show, setShow] = useState<boolean>(false);
  const hideModal = () => setShow(false);
  const showModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.finalAgreementExpandClicked);
    setShow(true);
  };

  const disabledLinksAgreement =
    (typeof agreement === 'string' && agreement?.replace('table {', 'a { pointer-events:none; } table {')) || '';

  const hasAutoPay = loan?.autoPay;
  const downPaymentFee = paymentMethodFees?.[downPaymentMethod?.storedCard?.type || '']?.percent || 0;
  const installmentPaymentFee = paymentMethodFees?.[installmentMethod?.storedCard?.type || '']?.percent || 0;

  // downPaymentMethod
  const cardTitleDownPaymentMethod =
    downPaymentMethod?.type === 'CARD'
      ? `${downPaymentMethod?.storedCard?.network} - ${downPaymentMethod?.storedCard?.type}`
      : `${downPaymentMethod?.achAccount?.bankName}`;

  const cardNumberDownPaymentMethod =
    downPaymentMethod?.type === 'CARD'
      ? downPaymentMethod?.storedCard?.last4
      : downPaymentMethod?.achAccount?.accountNumber?.substring(
          downPaymentMethod?.achAccount?.accountNumber?.length - 4,
        );

  // installmentMethod
  const cardTitleInstallmentMethod =
    installmentMethod?.type === 'CARD'
      ? `${installmentMethod?.storedCard?.network} - ${installmentMethod?.storedCard?.type}`
      : installmentMethod?.type === 'ACH'
      ? `${installmentMethod?.achAccount?.bankName}`
      : hasAutoPay
      ? 'Checks'
      : '';

  const cardNumberInstallmentMethod =
    installmentMethod?.type === 'CARD'
      ? installmentMethod?.storedCard?.last4
      : installmentMethod?.type === 'ACH'
      ? installmentMethod?.achAccount?.accountNumber?.substring(
          installmentMethod?.achAccount?.accountNumber?.length - 4,
        )
      : hasAutoPay
      ? installmentMethod?.rccAccount?.accountNumber?.substring(
          installmentMethod?.rccAccount?.accountNumber?.length - 4,
        )
      : '';

  const layout = (
    <IframeContainer useElegantTheme={useElegantTheme}>
      <StyledIframe useElegantTheme={useElegantTheme} dangerouslySetInnerHTML={{ __html: disabledLinksAgreement }} />
    </IframeContainer>
  );

  const downPaymentFeeAmount =
    downPaymentMethod?.type === 'CARD' && downPaymentFee > 0
      ? calculatePaymentFeeAmount(paymentMethodFees, downPaymentMethod?.storedCard?.type, downPaymentAmount)
      : 0;

  const installmentFeeAmount =
    installmentMethod?.type === 'CARD' && installmentPaymentFee > 0
      ? calculatePaymentFeeAmount(paymentMethodFees, installmentMethod?.storedCard?.type, installmentAmount)
      : 0;

  const customHeaderText = product?.parentId ? translate('confirm.summaryCard.custom') : '';

  return (
    <Container useElegantTheme={useElegantTheme}>
      <Header useElegantTheme={useElegantTheme}>
        {translate('confirm.newSummaryCard.title', {
          term: product?.term,
        })}
      </Header>
      <ContentContainer>
        <ContentItemContainer>
          <ColumnContainer>
            <ContentTitle useElegantTheme={useElegantTheme}>{translate('confirm.summaryCard.dueNow')}</ContentTitle>
            <ContentText useElegantTheme={useElegantTheme}>
              {FormatAmount(downPaymentAmount + downPaymentFeeAmount)}
            </ContentText>
          </ColumnContainer>
          <CardContainer>
            <CardTitle useElegantTheme={useElegantTheme}>{cardTitleDownPaymentMethod}</CardTitle>
            <CardTitle useElegantTheme={useElegantTheme}>************ {cardNumberDownPaymentMethod}</CardTitle>
          </CardContainer>
        </ContentItemContainer>

        <ColumnContainer>
          <ContentSubText useElegantTheme={useElegantTheme}>
            {useElegantTheme ? (
              <>
                <BoldText>{FormatAmount(downPaymentAmount)}</BoldText> {translate('confirm.summaryCard.downPayment')}
              </>
            ) : (
              <b>
                {FormatAmount(downPaymentAmount)} {translate('confirm.summaryCard.downPayment')}
              </b>
            )}
          </ContentSubText>
          {downPaymentMethod?.type === 'CARD' && downPaymentFeeAmount > 0 && (
            <ContentSubText useElegantTheme={useElegantTheme}>
              <b>{FormatAmount(downPaymentFeeAmount)}</b> {translate('confirm.summaryCard.creditCardFee')}
            </ContentSubText>
          )}
        </ColumnContainer>

        <ContentItemContainer>
          <ContentBorder />
        </ContentItemContainer>

        <ContentItemContainer>
          <ColumnContainer>
            <ContentTitle useElegantTheme={useElegantTheme}>{translate('confirm.summaryCard.dueLater')}</ContentTitle>
            <ContentText useElegantTheme={useElegantTheme}>
              {translate('confirm.summaryCard.monthly', {
                amount: FormatAmount(installmentAmount + installmentFeeAmount),
              })}
            </ContentText>
          </ColumnContainer>
          <CardContainer>
            <CardTitle useElegantTheme={useElegantTheme}>{cardTitleInstallmentMethod}</CardTitle>
            <CardTitle useElegantTheme={useElegantTheme}>************ {cardNumberInstallmentMethod}</CardTitle>
          </CardContainer>
        </ContentItemContainer>

        <ColumnContainer>
          <ContentSubText useElegantTheme={useElegantTheme}>
            {useElegantTheme ? (
              <>
                <BoldText>{FormatAmount(installmentAmount)}</BoldText> {translate('confirm.summaryCard.monthlyPayment')}
              </>
            ) : (
              <b>
                {FormatAmount(installmentAmount)} {translate('confirm.summaryCard.monthlyPayment')}
              </b>
            )}
          </ContentSubText>
          {installmentMethod?.type === 'CARD' && installmentFeeAmount > 0 && (
            <ContentSubText useElegantTheme={useElegantTheme}>
              <b>{FormatAmount(installmentFeeAmount)}</b> {translate('confirm.summaryCard.creditCardFee')}
            </ContentSubText>
          )}
        </ColumnContainer>

        <br />

        <ColumnContainer>
          <ContentSubText
            useElegantTheme={useElegantTheme}
            dangerouslySetInnerHTML={{
              __html: translate('confirm.summaryCard.payments', {
                term: product?.term,
                date: dayjs(paymentDates?.[0]).format(DATE_FORMAT),
              }),
            }}
          />

          {!useElegantTheme ? (
            <ContentSubText useElegantTheme={useElegantTheme}>
              <b>{promo ? product?.promoApr : product?.apr}%</b> {translate('confirm.summaryCard.apr')}{' '}
              {promo && selectedPlan?.product?.promoApr !== null
                ? `${translate('confirm.summaryCard.promo', {
                    apr: selectedPlan?.product?.fallbackApr || selectedPlan?.product?.apr,
                  })}`
                : null}
            </ContentSubText>
          ) : null}

          <ContentSubText
            useElegantTheme={useElegantTheme}
            dangerouslySetInnerHTML={{
              __html: translate('confirm.summaryCard.financeCharge', {
                financeCharge: FormatAmount(financeCharge),
              }),
            }}
          />

          {useElegantTheme ? (
            <ContentSubText useElegantTheme={useElegantTheme}>
              <b>{promo ? product?.promoApr : product?.apr}%</b> {translate('confirm.summaryCard.apr')}{' '}
              {promo && selectedPlan?.product?.promoApr !== null
                ? `${translate('confirm.summaryCard.promo', {
                    apr: selectedPlan?.product?.fallbackApr || selectedPlan?.product?.apr,
                  })}`
                : null}
            </ContentSubText>
          ) : null}
        </ColumnContainer>

        <DisclosureContainer useElegantTheme={useElegantTheme}>
          {layout}
          <IconContainer onClick={showModal}>
            <Icon
              src={useElegantTheme ? 'location-export-alle' : 'location-export'}
              width={16}
              height={16}
              hover={true}
            />
          </IconContainer>
        </DisclosureContainer>
      </ContentContainer>
      <AgreementModal useElegantTheme={useElegantTheme} show={show} hideModal={hideModal} agreement={agreement} />
    </Container>
  );
};

export default SummaryCard;

const Container = styled.div<{ useElegantTheme?: boolean }>`
  position: relative;
  border: 1px solid ${(props) => (props.useElegantTheme ? props.theme.main.mutedRedBrown : theme.main.midnightBlue)};
  border-radius: 10px;
  margin-right: 10px;
`;

const Header = styled.div<{ useElegantTheme?: boolean }>`
  font-size: 16px;
  font-weight: 700;
  text-align: left;

  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: ${(props) => (props.useElegantTheme ? 400 : 700)};
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) => (props.useElegantTheme ? props.theme.main.mutedRedBrown : theme.main.midnightBlue)};
  padding: 5px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
`;

const ContentContainer = styled.div`
  padding: 12px 16px 4px;
`;

const ContentItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const DisclosureContainer = styled.div<{ useElegantTheme?: boolean }>`
  background: ${(props) => props.theme.main.darkGrayColor};
  border-radius: 8px;
  padding: ${(props) => (props.useElegantTheme ? '16px' : '8px')};
  margin: 12px 0;
  position: relative;

  * {
    font-family: ${(props) => props.useElegantTheme && 'Graphik !important'};
  }
`;

const CardTitle = styled.span<{ useElegantTheme?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => (props.useElegantTheme ? props.theme.main.midnightGray : props.theme.main.grey)};
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
`;

const ContentTitle = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  text-align: left;
  text-transform: uppercase;
  color: ${(props) => (props.useElegantTheme ? props.theme.main.mutedRedBrown : props.theme.main.green)};
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  letter-spacing: ${(props) => props.useElegantTheme && '1px'};
`;

const ContentText = styled.span<{ useElegantTheme?: boolean }>`
  font-family: ${(props) => props.useElegantTheme && 'Petersburg'};
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => (props.useElegantTheme ? props.theme.main.midnightGray : theme.main.midnightBlue)};
`;

const ContentSubText = styled.span<{ useElegantTheme?: boolean }>`
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  font-style: ${(props) => (props.useElegantTheme ? 'none' : 'italic')};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => (props.useElegantTheme ? props.theme.main.midnightGray : props.theme.main.midnightBlue)};
`;

const ContentBorder = styled.div`
  width: 100%;
  border: 1px dashed ${(props) => theme.main.grayColor};
  margin: 8px 0;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -30px;
  right: 3px;
`;

const IframeContainer = styled.div<{ useElegantTheme?: boolean }>`
  background: ${(props) => props.theme.main.darkGrayColor};
  padding: ${(props) => (props.useElegantTheme ? '0' : '16px')};
  margin-bottom: ${(props) => (props.useElegantTheme ? '0' : '30px')};
  height: 200px;
  overflow: hidden;
  overflow-y: scroll;
`;

const StyledIframe = styled.div<{ useElegantTheme?: boolean }>`
  background-color: ${(props) => props.theme.main.white};
  padding: ${(props) => props.useElegantTheme && '12px'};

  td {
    padding: 5px 0;
    font-size: 12px;
  }

  th {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    td {
      word-break: break-word;
    }
  }
`;

const BoldText = styled.span`
  font-weight: 500;
`;
