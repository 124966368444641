import { Redirect } from './Redirect';

export default function openPdfFile(pdfDataString: string) {
  const base64StringBytes = atob(pdfDataString);
  const length = base64StringBytes.length;
  const out = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    out[i] = base64StringBytes.charCodeAt(i);
  }

  const pdfFileBlob = new Blob([out], { type: 'application/pdf' });
  const fileUrl = URL.createObjectURL(pdfFileBlob);
  Redirect(fileUrl);
}
