import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'config/theme';
import { FormatAmount } from 'lib/utils';
import { Icon } from 'lib/components';
import { IPlan } from 'pages/Plans/types';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

interface SelectedProductCardProps {
  selectedPlan: IPlan;
  openCustomDownPayment?: (status: boolean) => void;
  approvalScreen?: boolean;
}

export const SelectedProductCard = ({
  selectedPlan,
  openCustomDownPayment,
  approvalScreen,
}: SelectedProductCardProps) => {
  const { t: translate } = useTranslation();
  const { sendLoadSegmentEvent } = useSegmentContext();

  const { product, installmentAmount, promo, downPaymentAmount } = selectedPlan;

  const openModal = () => {
    openCustomDownPayment && openCustomDownPayment(true);
    sendLoadSegmentEvent(WPQSegmentNames.planSelectionCustomDownPaymentModalLoad);
  };

  const isAprZero = () => {
    if ((promo && product?.promoApr === 0) || (!promo && product?.apr === 0)) {
      return true;
    }
    return false;
  };

  return (
    <SelectedProductContainer>
      <SelectedProductTitle>
        <div>
          <InstallmentCount>{product?.term}</InstallmentCount>
          <InstallmentText> {translate('plans.selectedCard.payment')}</InstallmentText>
        </div>
        <div>
          <InstallmentAmount>{FormatAmount(installmentAmount)}</InstallmentAmount>
          <InstallmentMonthText>/ {translate('plans.selectedCard.month')}</InstallmentMonthText>
        </div>
      </SelectedProductTitle>
      <SelectedProductContent approvalScreen={approvalScreen}>
        <SelectedProductRow>
          <DescriptionSide isAPRZero={isAprZero()}>
            <span>{translate('plans.selectedCard.apr')}</span>
            <div>{promo ? product?.promoApr : product?.apr}%</div>
            {isAprZero() && <Icon src={'check_bold_green'} width={16} height={16} />}
          </DescriptionSide>
        </SelectedProductRow>
        {!approvalScreen && <Seperator></Seperator>}
        <SelectedProductRow>
          <DescriptionSide>
            <span>{translate('plans.selectedCard.downPayment')}</span>
            <div>{FormatAmount(downPaymentAmount)}</div>
          </DescriptionSide>
          {!approvalScreen && (
            <ButtonContainer onClick={openModal}>
              <span>{translate('plans.selectedCard.edit')}</span>
              <Icon src="edit" width={20} height={20} />
            </ButtonContainer>
          )}
        </SelectedProductRow>
      </SelectedProductContent>
    </SelectedProductContainer>
  );
};

const InstallmentCount = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  letter-spacing: 0.05em;
  text-align: left;
`;

const DescriptionSide = styled.div<{ isAPRZero?: boolean }>`
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
    color: ${theme.main.midnightBlue};
  }
  div {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    text-align: left;
    align-items: center;
    color: ${(props) => (props?.isAPRZero ? theme.main.green : theme.main.midnightBlue)};
  }
  gap: 4px;
  display: flex;
  align-items: center;
`;
const ButtonContainer = styled.div`
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  gap: 8px;
  border-radius: 4px;
  justify-content: space-between;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: ${theme.main.green};
  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: center;
  }
`;

const InstallmentText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  letter-spacing: 0.05em;
  text-align: left;
`;
const InstallmentAmount = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 32.68px;
  text-align: right;
  margin-right: 4px;
`;
const InstallmentMonthText = styled.span`
  font-size: 13.96px;
  font-weight: 600;
  line-height: 19.01px;
  text-align: left;
`;

const Seperator = styled.hr`
  border-top: 1px dashed ${theme.main.grey20};
  margin: 4px 0px;
`;

const SelectedProductContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 8px 16px 16px 16px;

  border: 1px solid ${theme.main.midnightBlue};
`;

const SelectedProductTitle = styled.div`
  padding: 8px 16px;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${theme.main.white};
  font-weight: 400;
  background-color: ${theme.main.midnightBlue};
`;

const SelectedProductContent = styled.div<{ approvalScreen?: boolean }>`
  background-color: white;
  border-radius: 0px 0px 7px 7px;
  padding: 8px 16px;
  display: ${(props) => (props?.approvalScreen ? 'flex' : 'block')};
  justify-content: ${(props) => (props?.approvalScreen ? 'space-between' : '')};
`;

const SelectedProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
