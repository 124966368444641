import { gql } from '@apollo/client/core';

export const GET_LOANS = gql`
  mutation getLoansWithLoanId($input: LoanInput) {
    getLoansWithLoanId(input: $input) {
      amount
      applicationId
      autoPay
      borrowerId
      channel
      createdAt
      demo
      displayId
      downPaymentAmount
      downPaymentPaymentMethodId
      financeCharge
      grossAmount
      id
      installmentAmount
      installmentPaymentMethodId
      partner
      productId
      purchaseAmount
      selfCheckout
      status
      subStatus
      totalAmount
    }
  }
`;

export const CREATE_LOANS = gql`
  mutation createLoan($input: CreateLoanRequest) {
    createLoan(input: $input) {
      code
      success
      message
      data {
        amount
        applicationId
        autoPay
        avoidance
        borrowerId
        ceaseAndDesist
        channel
        createdAt
        createdBy
        demo
        displayId
        dispute
        doNotCall
        doNotReport
        downPaymentAmount
        downPaymentAttempt
        downPaymentPaymentMethodId
        financeCharge
        financialHardship
        forbearance
        fraud
        fundedAt
        grossAmount
        id
        installmentAmount
        installmentPaymentMethodId
        merchantFund
        merchantId
        merchantRevenue
        partner
        productId
        purchaseAmount
        selfCheckout
        status
        subStatus
        totalAmount
      }
    }
  }
`;

export const PATCH_LOANS = gql`
  mutation patchLoan($input: PatchLoanRequest) {
    patchLoan(input: $input) {
      amount
      id
      merchant {
        name
      }
      status
    }
  }
`;

export const CONFIRM_LOAN = gql`
  mutation confirmLoan($input: ConfirmLoanRequest) {
    confirmLoan(input: $input) {
      amount
      applicationId
      autoPay
      avoidance
      borrowerId
      ceaseAndDesist
      channel
      createdAt
      createdBy
      demo
      displayId
      dispute
      doNotCall
      doNotReport
      downPaymentAmount
      downPaymentAttempt
      downPaymentPaymentMethodId
      financeCharge
      financialHardship
      forbearance
      fraud
      fundedAt
      grossAmount
      id
      installmentAmount
      installmentPaymentMethodId
      merchantFund
      merchantId
      merchantRevenue
      partner
      productId
      purchaseAmount
      selfCheckout
      status
      subStatus
      totalAmount
      code
      message
      threeD
      paymentId
    }
  }
`;

export const CREATE_REFUNDING_STATUS_SURVEY = gql`
  mutation createRefundingStatusSurvey($input: RefundingStatusSurveyRequest) {
    createRefundingStatusSurvey(input: $input) {
      message
    }
  }
`;

export const ALIGN_DUE_DATES = gql`
  mutation alignDueDates($input: AlignDueDatesRequest) {
    alignDueDates(input: $input) {
      success
      error {
        status
        message
      }
    }
  }
`;

export const CREATE_DATA_GATHERING_SURVEY = gql`
  mutation createDataGatheringSurvey($input: DataGatheringSurveyRequest) {
    createDataGatheringSurvey(input: $input) {
      message
    }
  }
`;

export const POST_EMAIL_CONFIRMATION = gql`
  mutation PostEmailConfirmation($input: LoanInput) {
    postEmailConfirmation(input: $input) {
      success
      message
      code
    }
  }
`;
