import { useMutation } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { UPDATE_BORROWER } from 'lib/graphql/mutations';

import { APP_ENV } from 'config';
import { US_STATES, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { poBoxRegex, prohibitedStreetRegex } from 'lib/utils';
import { houseNumberRegex, numericPattern, trapSpacesForRequiredFields } from 'lib/utils/Validators';

import { Box, Button, Container, Icon, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

import { FormDataType, ISelectedAddress, USStates } from './types';
import AllePersonalAddressInfo from 'pages/PersonalAddressInfo/AllePersonalAddressInfo';
import { ButtonClickedEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';

import useAddressAutocomplete from 'lib/hooks/useAddressAutocomplete';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';

const PersonalAddressInfo = () => {
  const alert = useAlert();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { captureException, captureMessage } = useSentry();
  const { trackIdentify, trackSegmentEvent } = useSegment();
  const { pushEvent } = useIframeEvents();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();
  const { setSessionData, setPageIndex, organization, sessionData, application, borrower, applicationFlow, isAlle } =
    useStore();
  const { startCheckApplicationStatus } = useSSEContext();

  const [updateBorrower, { loading: borrowerLoading }] = useMutation(UPDATE_BORROWER);

  const { setSearchTerm, autocompleteData, loading } = useAddressAutocomplete();

  let info = sessionData;
  if (APP_ENV === 'dev') {
    info = {
      street: sessionData.street || '1234 Test Address',
      addressLine2: sessionData.addressLine2 || 'Apartment #1234',
      city: sessionData.city || 'City',
      state: sessionData.state || 'CA',
      zip: sessionData.zip || '23423',
    };
  }

  if (isAlle) {
    const temp = borrower?.address;
    info = {
      street: temp?.street || '',
      city: temp?.city || '',
      state: temp?.state?.code || '',
      addressLine2: temp?.addressLine2 || '',
      zip: temp?.zip || '',
    };
  }

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.personalAddressLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: info,
  });

  useEffect(() => {
    setPageIndex(6);
  }, [setPageIndex]);

  const setAddressValues = (selectedAddress: ISelectedAddress) => {
    if (selectedAddress) {
      setValue('street', selectedAddress?.street, { shouldValidate: true });
      setValue('city', selectedAddress?.city, { shouldValidate: true });
      setValue('state', selectedAddress?.state, { shouldValidate: true });
      setValue('addressLine2', selectedAddress?.addressLine2, { shouldValidate: true });
      setValue('zip', selectedAddress?.zip, { shouldValidate: true });
    }
  };

  const onSubmit = async (formData: FormDataType) => {
    if (isAlle) {
      pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.PATIENT_ADDRESS_CONTINUE_BUTTON_CLICKED);
    }
    sendActionSegmentEvent(WPQSegmentNames.addressContinueClicked);
    setSessionData(formData);
    const { borrower } = useStore.getState() || {};
    if (poBoxRegex.test(formData?.street)) {
      alert.info(translate('personalAddressInfo.errors.poBox'));
    } else if (prohibitedStreetRegex.test(formData?.street)) {
      alert.info(translate('personalAddressInfo.errors.notGeneralDelivery'));
    } else if (!houseNumberRegex.test(formData?.street)) {
      alert.info(translate('personalAddressInfo.errors.includeAStreetNumber'));
    } else {
      trackEvent(AnalyticEventNames.BI_SEE_IF_QUALIFY);
      trackIdentify({ ...borrower, ...sessionData, ...formData });
      trackSegmentEvent('Address Submitted', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        application: WEBPREQUAL,
        value: 25,
      });

      if (borrower?.id) {
        const { data } = await updateBorrower({
          variables: {
            input: {
              source: 'GREET',
              id: borrower?.id,
              address: {
                city: formData?.city,
                zip: formData?.zip,
                stateCode: formData?.state,
                street: formData?.street,
                addressLine2: formData?.addressLine2,
              },
              dob: sessionData?.dob,
              email: sessionData?.email,
              firstName: sessionData?.firstName,
              lastName: sessionData?.lastName,
            },
          },
        });

        if (data?.updateBorrower?.success) {
          if (applicationFlow?.hasTreatmentQuestions) {
            navigate('treatment-type');
          } else if (application?.flowType === 'HIGH_LINE') {
            navigate('request-amount');
          } else {
            navigate('ssn-collect');
          }
        } else {
          if (['kyc.status.invalid', 'borrower.kyc_status.invalid'].includes(data?.updateBorrower?.message)) {
            startCheckApplicationStatus({ subscribeFlow: true, isExisting: true });
          }
          alert.info(translate('personalAddressInfo.errors.anErrorOccurred'));
        }
      } else {
        captureException('Error', { message: 'Borrower ID not found', session: sessionData });
        captureMessage('Borrower ID not found');
        alert.info(translate('personalAddressInfo.errors.anErrorOccurredTryAgain'));
        navigate(``);
      }
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  if (isAlle) {
    return (
      <AllePersonalAddressInfo
        control={control}
        autocompleteData={autocompleteData}
        setAddressValues={setAddressValues}
        setSearchTerm={setSearchTerm}
        isValid={isValid}
        loading={borrowerLoading}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        checkKeyDown={checkKeyDown}
      />
    );
  }

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'location-pin'} />
        <Title m={'10px 0px 24px 0px'}>{translate('personalAddressInfo.title')}</Title>
        <Box>
          <Controller
            name="street"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: trapSpacesForRequiredFields }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Autocomplete
                options={autocompleteData}
                value={value}
                onChange={(event, newValue: ISelectedAddress) => {
                  setAddressValues(newValue);
                }}
                id="disable-clearable"
                disableClearable={true}
                renderInput={(params) => {
                  delete params.inputProps.className;
                  return (
                    <TextField
                      {...params}
                      id="street-address"
                      variant="filled"
                      data-testid="street"
                      label={translate('personalAddressInfo.form.streetAddress')}
                      name="street"
                      value={value}
                      onChange={(e) => {
                        setSearchTerm(e?.target?.value);
                        onChange(e?.target?.value);
                      }}
                      onKeyDown={checkKeyDown}
                      onFocus={() => {
                        sendActionSegmentEvent(WPQSegmentNames.streetClicked);
                      }}
                      onBlur={(e) => {
                        sendActionSegmentEvent(WPQSegmentNames.streetFilled, { metaData: e.target.defaultValue });
                        onBlur();
                      }}
                      error={!!error}
                      helperText={error ? error?.message : null}
                    />
                  );
                }}
              />
            )}
          />
          <Controller
            name="addressLine2"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="apt-addressLine2"
                variant="filled"
                label={translate('personalAddressInfo.form.unit')}
                data-testid="addressLine2"
                name="addressLine2"
                autoComplete="new-password"
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onFocus={() => {
                  sendActionSegmentEvent(WPQSegmentNames.aptClicked);
                }}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.aptFilled, { metaData: e.target.defaultValue });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
          <RowContainer>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="city-address"
                  variant="filled"
                  data-testid="city"
                  data-name="city"
                  label={translate('personalAddressInfo.form.city')}
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onFocus={() => {
                    sendActionSegmentEvent(WPQSegmentNames.cityClicked);
                  }}
                  onBlur={(e) => {
                    sendActionSegmentEvent(WPQSegmentNames.cityFilled, { metaData: e.target.defaultValue });
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="state"
                  variant="filled"
                  data-testid="state"
                  data-name="state"
                  label={translate('personalAddressInfo.form.state')}
                  select={true}
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onFocus={() => {
                    sendActionSegmentEvent(WPQSegmentNames.stateClicked);
                  }}
                  onBlur={(e) => {
                    sendActionSegmentEvent(WPQSegmentNames.stateFilled, { metaData: e.target.value });
                    onBlur();
                  }}
                  error={!!error}
                  helperText={error ? error?.message : null}
                >
                  {US_STATES.map((option: USStates) => (
                    <MenuItem data-testid="stateItem" key={option?.value} value={option?.value}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </RowContainer>
          <Controller
            name="zip"
            control={control}
            defaultValue=""
            rules={{ required: true, maxLength: 5, minLength: 5 }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="zip-code"
                variant="filled"
                data-testid="zip"
                data-name="zip"
                type="tel"
                inputProps={{
                  maxLength: 5,
                }}
                label={translate('personalAddressInfo.form.zipCode')}
                value={value}
                onKeyPress={(event) => {
                  if (!numericPattern.test(event?.key)) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onFocus={() => {
                  sendActionSegmentEvent(WPQSegmentNames.zipClicked);
                }}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.zipFilled, { metaData: e.target.defaultValue });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || borrowerLoading || loading}
          loading={loading || borrowerLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default PersonalAddressInfo;

const RowContainer = styled.div`
  display: flex;
  align-items: center;

  div[data-name='city'] {
    flex: 4;
    text-align: left;
  }

  div[data-name='state'] {
    flex: 2;
    margin-left: 8px;
  }

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    width: 100%;
  }
`;
