import { useMutation } from '@apollo/client';
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { START_OTP_FLOW } from 'lib/graphql/mutations';

import { useInitializeAuth } from 'pages/AuthChecker/services/useInitializeAuth';
import { APP_ENV } from 'config';
import { WEB_SITE, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { phonePattern } from 'lib/utils/Validators';

import { Box, Button, Container, Icon, PhoneInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import { SmallText } from 'pages/Terms/Terms';
import { generateSevenDigitNumber } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';

interface State {
  phone: string;
}

const PhoneNumber = () => {
  const alert = useAlert();
  const { id } = useParams();
  const { initPage } = useNeuroId();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { t: translate } = useTranslation();
  const { captureException, setTag, captureMessage } = useSentry();
  const { trackIdentify, trackBorrowerCreatedEvent, trackSegmentEvent } = useSegment();
  const { setSessionData, setPageIndex, organization, sessionData, flowType, defaultLanguage } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const { initializeAuth } = useInitializeAuth();

  const [startOtpFlow, { loading }] = useMutation(START_OTP_FLOW);

  let failedAttemptCount = 0;
  let isAlreadyFetching = false;

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.phoneNumberLoad);
      initPage('phone');
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(2);
  }, [setPageIndex]);

  const footerText = translate('phone.footer.text', {
    eSignDisclosuresAndConsent: `<a target="_blank" href=${WEB_SITE}/e-sign-disclosure${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('phone.footer.eSignDisclosuresAndConsent')}</a>`,
    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'phone.footer.termsOfService',
    )}</a>`,
    privacyPolicy: `<a target="_blank" href=${WEB_SITE}/privacy${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'phone.footer.privacyPolicy',
    )}</a>`,
    usaPatriotAct: `<a target="_blank" href=${WEB_SITE}/terms/#patriot-act${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('phone.footer.usaPatriotAct')}</a>`,
  });
  const defaultPhone = () => {
    if (APP_ENV === 'dev') {
      return `216${String(generateSevenDigitNumber())}`;
    }
    return sessionData?.phone;
  };

  const refreshPhone = () => {
    setValue('phone', defaultPhone());
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid } = {},
  } = useForm<State>({
    mode: 'onChange',
    defaultValues: { phone: defaultPhone() },
  });

  const onSubmit = async (formData: State) => {
    const { application, borrower, utmMedium } = useStore.getState() || {};
    try {
      trackEvent(AnalyticEventNames.PI_SEND_SEC_CODE);
      trackSegmentEvent(AnalyticEventNames.PI_SEND_SEC_CODE, {
        applicationId: application?.id,
        borrowerId: borrower?.id,
        application: WEBPREQUAL,
      });
      if (formData?.phone) {
        if (flowType === 'CheckoutWithEmailFlow' && formData?.phone !== borrower?.phone) {
          if (++failedAttemptCount === 3) {
            trackSegmentEvent(AnalyticEventNames.PHONE_NOT_MATCHING_WITH_LOAN, {
              applicationId: application?.id,
              borrowerId: borrower?.id,
              application: WEBPREQUAL,
              phone: formData?.phone,
            });
            navigate('');
          }
          alert.info(`${translate('phone.notMatch')}`);
          return;
        }

        setTag('phone', formData?.phone);
        const { data } = await startOtpFlow({
          variables: {
            input: {
              phone: formData?.phone,
            },
          },
          context: {
            headers: {
              extra: 'borrowerInit',
            },
          },
        });

        if (data?.startOtpFlow?.success) {
          alert.success(translate('phone.verificationCodeSent'));
          trackIdentify({ phone: formData?.phone });

          if (borrower?.id) {
            trackBorrowerCreatedEvent(organization, borrower);
          }

          setSessionData({ phone: formData?.phone });
          navigate(`verify`);
        } else {
          if (data?.startOtpFlow?.message === 'borrower.phone_voip.unprocessable') {
            alert.info(translate('phone.voipNumbers'));
          } else if (data?.startOtpFlow?.message) {
            alert.info(data?.startOtpFlow?.message);
          } else {
            if (!isAlreadyFetching) {
              await initializeAuth(id);
              isAlreadyFetching = true;
              handleClick();
            } else {
              captureMessage('Personal Phone Number 2. Attempt');
              window.location.href = `/${id}`;
            }
          }

          trackEvent(AnalyticEventNames.PI_BORROWER_NOT_FOUND);
        }
      } else {
        alert.error(`${translate('phone.validPhone')}}`);
      }
    } catch (err) {
      captureException(err, { phone: formData?.phone });
    }
  };

  const checkKeyDown = (e) => {
    if (e?.code === 'Enter' || Number(e?.keyCode) === 13) {
      e?.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleClick = () => {
    sendActionSegmentEvent(WPQSegmentNames.phoneNumberContinueClicked);
    handleSubmit(onSubmit)();
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'mobile'} />
        <Title data-testid="phone-number-title" m={'10px 0px 8px 0px'}>
          {translate('phone.enterYourMobileNumber')}
        </Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('phone.needPhoneNumber')}</Subtitle>
        <Box>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: phonePattern }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="phone"
                variant="filled"
                data-testid="phone"
                data-neuro-label="phone"
                type="tel"
                label={translate('phone.personalMobileNumber')}
                disabled={flowType === 'PointOfSalesFlow'}
                onKeyDown={checkKeyDown}
                InputProps={{
                  inputComponent: PhoneInput,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onFocus={() => {
                  sendActionSegmentEvent(WPQSegmentNames.phoneNumberInputClicked);
                }}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.phoneNumberInputFilled, { metaData: e.target.defaultValue });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        {APP_ENV === 'dev' ? (
          <Button dataTestId="refresh-phone-button" secondary={true} onClick={refreshPhone}>
            {translate('buttons.refreshPhone')}
          </Button>
        ) : null}

        <Button dataTestId="continue-button" disabled={!isValid || loading} loading={loading} onClick={handleClick}>
          {translate('buttons.continue')}
        </Button>
        <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
      </ButtonContainer>
    </Container>
  );
};

const LinkText = styled.a`
  font-weight: 700;
  color: ${(props) => props.theme.main.green};
`;

export default PhoneNumber;
