import { createContext, ReactNode } from 'react';
import useStore from 'lib/hooks/useStore';
import { useSentry } from '../useSentry';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { useFlags } from '../FeatureManagement/FlagsContext';
import { getDimensions } from 'lib/utils';

interface ContextState {
  sendLoadSegmentEvent: (eventName: string, data?) => void;
  sendActionSegmentEvent: (eventName: string, data?) => void;
}

export const SegmentContext = createContext<ContextState>({} as ContextState);

export const SegmentContextProvider = ({ children }: { children: ReactNode }) => {
  const { captureException } = useSentry();
  const { borrower, organization, merchant, application, loan, features, defaultLanguage } = useStore();
  const { flags } = useFlags();
  const pathname = location?.pathname.split('/')[2];
  const sendLoadSegmentEvent = (eventName: string, data?) => {
    try {
      // @ts-ignore analytics
      window?.analytics?.track(eventName, {
        version: TRACK_VERSION,
        application: WEBPREQUAL,
        language: defaultLanguage,
        ...(borrower?.id && { patientId: borrower?.id }),
        ...(borrower?.firstName && { patientName: borrower?.firstName + ' ' + borrower?.lastName }),
        ...(borrower?.email && { patientEmail: borrower?.email }),
        ...(borrower?.phone && { patientPhone: borrower?.phone }),
        ...(merchant && { merchantId: borrower }),
        ...(organization && { organizationId: organization?.id }),
        ...(organization && { organizationIndustry: organization?.industry }),
        ...(application?.id && { applicationId: application?.id }),
        ...(loan?.id && { loadId: loan?.id }),
        ...data,
        flags: getFlags(),
        path: pathname,
        ...getDimensions(),
      });
    } catch (e) {
      captureException(e);
    }
  };

  const sendActionSegmentEvent = (eventName: string, data?) => {
    try {
      // @ts-ignore analytics
      window?.analytics?.track(eventName, {
        version: TRACK_VERSION,
        application: WEBPREQUAL,
        language: defaultLanguage,
        ...(borrower?.id && { patientId: borrower?.id }),
        ...(borrower?.firstName && { patientName: borrower?.firstName + ' ' + borrower?.lastName }),
        ...(borrower?.email && { patientEmail: borrower?.email }),
        ...(borrower?.phone && { patientPhone: borrower?.phone }),
        ...(merchant && { merchantId: borrower }),
        ...(organization && { organizationId: organization.id }),
        ...(organization && { organizationIndustry: organization.industry }),
        ...(application?.id && { applicationId: application?.id }),
        ...(loan?.id && { loadId: loan?.id }),
        ...data,
        path: pathname,
        flags: getFlags(),
        ...getDimensions(),
      });
    } catch (e) {
      captureException(e);
    }
  };

  const getFlags = () => {
    const experimentFlags = {
      polling: Object.keys(flags?.POLLING)?.map((key) => ({ [key]: flags?.POLLING?.[key]?._value })),
      approvalSurvey: Object.keys(flags?.APPROVALSURVEY)?.map((key) => ({
        [key]: flags?.APPROVALSURVEY?.[key]?._value,
      })),
      preFunding: Object.keys(flags?.SURVEY)?.map((key) => ({ [key]: flags?.SURVEY?.[key]?._value })),
      checkout: Object.keys(flags?.CHECKOUT)?.map((key) => ({ [key]: flags?.CHECKOUT?.[key]?._value })),
      screen: Object.keys(flags?.SCREEN)?.map((key) => ({ [key]: flags?.SCREEN?.[key]?._value })),
      iv: Object.keys(flags?.IV)?.map((key) => ({ [key]: flags?.IV?.[key]?._value })),
    };
    return {
      featureFlags: features?.map((feature) => ({ [feature]: true })),
      experimentFlags,
    };
  };

  return (
    <SegmentContext.Provider value={{ sendLoadSegmentEvent, sendActionSegmentEvent }}>
      {children}
    </SegmentContext.Provider>
  );
};
