import React, { useEffect, useRef } from 'react';

import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { FaqManualReviewList } from 'pages/Faq/components';
import AlleApproval from 'pages/Approval/AlleApproval';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import NewFaqManualReviewList from 'pages/Faq/components/NewFaqManualReviewList';
import { theme } from 'config/theme';

const ApplicationReview = () => {
  const { trackPage, trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { isAlle } = useStore();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewDenialUi = featureFlags?.isNewDenialUi || false;

  useEffect(() => {
    const { application } = useStore.getState() || {};

    trackPage('manualReviewLanding', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
      applicationId: application?.id,
    });

    trackSegmentEvent('manualReviewLanding', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
      applicationId: application?.id,
    });
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'glasses-review'} />
        {isNewDenialUi ? (
          <>
            <Title m={'10px 0px 8px 0px'}>{translate('applicationReview.newTitle')}</Title>
            <Subtitle color={theme.main.textColor} m={'0px 0px 24px 0px'}>
              {translate('applicationReview.newSubtitle')}
            </Subtitle>
            <NewFaqManualReviewList />
          </>
        ) : (
          <>
            <Title m={'10px 0px 8px 0px'}>{translate('applicationReview.title')}</Title>
            <Subtitle m={'0px 0px 24px 0px'}>{translate('applicationReview.subTitle')}</Subtitle>
            <FaqManualReviewList />
          </>
        )}
      </ContentContainer>
    </Container>
  );
};

export default React.memo(ApplicationReview);
