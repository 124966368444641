const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const APP_ENV: string | undefined = process.env.REACT_APP_ENV;
const GQL_URL = process.env.REACT_APP_GQL_URL;
const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const APP_SMARTY_KEY = process.env.REACT_APP_SMARTY_CLIENT_KEY;
const COGNITO_PUBLISHABLE_KEY = process.env.REACT_APP_COGNITO_PUBLISHABLE_KEY;
const CLOUDBEES_APIKEY = process.env.REACT_APP_CLOUDBEES_APIKEY;
const API_URL_STATIC: string | undefined = process.env.REACT_APP_API_URL_STATIC;
const SANDBOX_URL: string | undefined = process.env.REACT_APP_SANDBOX_URL;

export {
  SENTRY_DSN,
  APP_ENV,
  API_URL,
  GQL_URL,
  APP_SMARTY_KEY,
  COGNITO_PUBLISHABLE_KEY,
  API_URL_STATIC,
  CLOUDBEES_APIKEY,
  SANDBOX_URL,
};
