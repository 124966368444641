import React, { useEffect, useRef } from 'react';
import { CallCherry } from 'lib/utils';

import { Button, ChatWidgetButton, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import FaqList from '../Faq/components';
import { MessageTitle } from '../FrozenCredit/FrozenCredit';
import useStore from 'lib/hooks/useStore';
import AlleApproval from '../Approval/AlleApproval';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const Review = () => {
  const { isAlle } = useStore();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'glasses-review'} />

        <Title m={'10px 0px 8px 0px'}>{translate('review.title')}</Title>
        <Subtitle m={'24px 0px 24px 0px'}>{translate('review.subTitle')}</Subtitle>

        <MessageTitle>{translate('review.info.title')}</MessageTitle>
        <Subtitle
          m={'0px 0px 24px 0px'}
          dangerouslySetInnerHTML={{
            __html: translate('review.info.subTitle'),
          }}
        />

        <FaqList />
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={CallCherry} secondary={true} icon={'phone'}>
          {translate('buttons.callSupport')}
        </Button>
        {navigator.cookieEnabled ? <ChatWidgetButton /> : null}
      </ButtonContainer>
    </Container>
  );
};

export default Review;
