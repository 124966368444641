import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { DATE_FORMAT } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useGetAgreementData } from 'lib/hooks/useGetAgreementData';
import {
  calculatePaymentFeeTotalAmount,
  filterCardsByCreatedAt,
  filterDebitOrAchMethods,
  FormatAmount,
} from 'lib/utils';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import AddPaymentMethodModal from './components/AddPaymentMethodModal';
import PaymentMethodCard from './components/PaymentMethodCard';

import { useServices } from 'pages/AuthChecker/services';
import { usePlans } from 'pages/Plans/services';
import AllePaymentMethodList from './AllePaymentMethodList';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

enum TypeEnum {
  ACH = 'ACH',
  CARD = 'CARD',
  RCC = 'RCC',
  DEBIT = 'DEBIT',
  PREPAID = 'PREPAID',
}

enum AccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

interface Merchant {
  name: string;
}

interface PatchLoanProps {
  amount: number;
  id: string;
  merchant: Merchant;
  status: string;
}

const PaymentMethodList = () => {
  const alert = useAlert();
  const { getLoan } = useServices();
  const { patchLoan } = usePlans();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { getAgreementData } = useGetAgreementData();

  const {
    paymentMethods,
    selectedPlan,
    installmentMethod,
    setInstallmentMethod,
    setPageIndex,
    paymentMethodFees,
    borrower,
    isAlle,
  } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [isPreferredType, setIsPreferredType] = useState('');
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const loan = useStore((state) => state?.loan);

  const debitOrAchMethods = filterDebitOrAchMethods(paymentMethods);
  const paymentFee = paymentMethodFees?.[installmentMethod?.storedCard?.type || '']?.percent || 0;
  const paymentFeeAmount = calculatePaymentFeeTotalAmount(
    paymentMethodFees,
    installmentMethod?.storedCard?.type,
    selectedPlan?.downPaymentAmount,
  );
  const creditCardFee = paymentMethodFees?.CREDIT?.percent;
  const startingDate = dayjs(selectedPlan?.paymentDates?.[0]).format(DATE_FORMAT);
  const disclaimerText =
    installmentMethod?.type === TypeEnum.RCC
      ? translate('addRemoteCheck.subTitle2')
      : translate('paymentMethodList.text', {
          installmentAmount: FormatAmount(selectedPlan?.installmentAmount),
          startingDate: startingDate,
          creditCardFee: creditCardFee,
        });

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutRecurringLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(4);
  }, [setPageIndex]);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      selectPreferredPaymentMethod();
    }
  }, [paymentMethods]);

  const selectPreferredPaymentMethod = () => {
    let preferredInstallmentMethod = {};

    if (checkingACHMethodContents()?.length > 0) {
      const checkingAchMethod = checkingACHMethodContents()?.sort((a, b) => b.id - a.id);
      preferredInstallmentMethod = checkingAchMethod?.[0];
      setIsPreferredType('checking');
    } else if (debitCardMethodContents()?.length > 0) {
      const debitCardMethod = debitCardMethodContents()?.sort((a, b) => b.id - a.id);
      preferredInstallmentMethod = debitCardMethod?.[0];
      setIsPreferredType('debit');
    } else if (savingACHMethodContents()?.length > 0) {
      const savingAchMethod = savingACHMethodContents()?.sort((a, b) => b.id - a.id);
      preferredInstallmentMethod = savingAchMethod?.[0];
      setIsPreferredType('savings');
    } else {
      const creditCardMethod = creditCardMethodContents()?.sort((a, b) => b.id - a.id);
      setInstallmentMethod(creditCardMethod?.[0]);
      preferredInstallmentMethod = creditCardMethod?.[0];
    }

    if (!installmentMethod) {
      setInstallmentMethod(preferredInstallmentMethod);
    } else if (
      debitOrAchMethods?.length > 0 &&
      installmentMethod.type === 'CARD' &&
      installmentMethod?.storedCard?.type !== 'DEBIT'
    ) {
      setInstallmentMethod(preferredInstallmentMethod);
    }
  };

  const hideModal = () => setShow(false);

  const navigateToPlanSummary = async () => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    try {
      setLoading(true);
      const loanData = await getLoan(loanId);

      if (loanData?.status === 'CANCELLED') {
        navigate(`expired`);
        setLoading(false);
        return;
      }

      const isRCC = installmentMethod?.type === TypeEnum.RCC;
      const isPrepaidCard = installmentMethod?.storedCard?.type === TypeEnum.PREPAID;
      const autoPay = !isRCC && !isPrepaidCard;

      const resultPathLoan: PatchLoanProps = await patchLoan({
        loanId: loanId,
        installmentPaymentMethodId: installmentMethod?.id,
        autoPay,
      });

      sendActionSegmentEvent(WPQSegmentNames.recurringContinueClicked, {
        payment_method_type: installmentMethod?.type,
        payment_type: 'Installment Payment',
        application: 'checkout',
      });

      if (resultPathLoan?.id) {
        await getAgreementData();
        setLoading(false);
        navigate('confirm');
      } else {
        setLoading(false);
        alert.info(translate('paymentMethodList.error.default'));

        captureException('Installment Method Patch Error', {
          id: resultPathLoan?.id,
          loanId: loanId,
          borrowerId: borrower?.id,
          installmentPaymentMethodId: installmentMethod?.id,
        });

        navigate(`loan-cancelled`);
      }
    } catch (err) {
      navigate(`loan-cancelled`);
      setLoading(false);

      captureException('Plan Summary Navigate Error', {
        error: err,
        loanId: loanId,
        borrowerId: borrower?.id,
      });
    }
  };

  const addPaymentMethodHandler = () => {
    sendActionSegmentEvent(WPQSegmentNames.recurrringAddMethodClicked);
    setShow(true);
  };

  const selectPaymentMethodHandler = (value) => {
    const eventName = value?.type === TypeEnum.ACH ? AnalyticEventNames.PM_PLAID_CHECKING : AnalyticEventNames.PM_DEBIT;
    trackEvent(eventName);
    setInstallmentMethod(value);
  };

  const checkingACHMethodContents = () => {
    return paymentMethods?.filter((content) => content.achAccount?.accountType === AccountType.CHECKING);
  };

  const savingACHMethodContents = () => {
    return paymentMethods?.filter((content) => content.achAccount?.accountType === AccountType.SAVINGS);
  };

  const debitCardMethodContents = () => {
    return paymentMethods?.filter(
      (content) =>
        content.type === 'CARD' && content?.storedCard?.type === 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  };

  const bankRCCMethodContents = () => {
    return paymentMethods?.filter((content) => content?.type === TypeEnum.RCC);
  };

  const creditCardMethodContents = () => {
    return paymentMethods?.filter(
      (content) =>
        content.type === 'CARD' && content?.storedCard?.type !== 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  };

  if (isAlle) {
    return (
      <>
        <AllePaymentMethodList
          checkingACHMethodContents={checkingACHMethodContents}
          savingACHMethodContents={savingACHMethodContents}
          debitCardMethodContents={debitCardMethodContents}
          bankRCCMethodContents={bankRCCMethodContents}
          creditCardMethodContents={creditCardMethodContents}
          selectPaymentMethodHandler={selectPaymentMethodHandler}
          paymentMethods={paymentMethods}
          installmentMethod={installmentMethod}
          isPreferredType={isPreferredType}
          loading={loading}
          navigateToPlanSummary={navigateToPlanSummary}
          addPaymentMethodHandler={addPaymentMethodHandler}
        />
        <AddPaymentMethodModal show={show} hideModal={hideModal} />
      </>
    );
  }

  return (
    <Container showBackButton={true} backUrl={'down-payment'}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'calendar'} />
          <Title m={'10px 0px 0px 0px'}>{translate('paymentMethodList.title')}</Title>

          <SuccessContainer>
            <ApprovalContainer>
              <Subtitle fontSize="10px" m={'10px 0px 0px 0px'} textAlign="center">
                {translate('paymentMethodList.subTitle')}
              </Subtitle>

              {installmentMethod?.type === 'CARD' && paymentFee > 0 ? (
                <Title fontSize={theme.constants.xl} color={theme.main.green} m={'10px 0 0 0'} textAlign="center">
                  {translate('paymentMethodList.amount.fee', {
                    paymentFeeAmount: FormatAmount(paymentFeeAmount),
                  })}
                </Title>
              ) : (
                <Title fontSize={theme.constants.xl} color={theme.main.green} m={'10px 0 0 0'} textAlign="center">
                  {translate('paymentMethodList.amount.installment', {
                    installmentAmount: FormatAmount(selectedPlan?.installmentAmount),
                  })}
                </Title>
              )}

              <StyledSubtitle
                m={'10px 0px 0px 0px'}
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html: translate('paymentMethodList.payment.start', {
                    term: selectedPlan?.product?.term,
                    installmentAmount: FormatAmount(selectedPlan?.installmentAmount),
                    startingDate: startingDate,
                  }),
                }}
              />

              {installmentMethod?.type === 'CARD' && paymentFee > 0 ? (
                <Subtitle
                  m={'0px 0px 0px 0px'}
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: translate('paymentMethodList.payment.typeFee', {
                      installmentAmount: FormatAmount((paymentFee * selectedPlan?.installmentAmount) / 100),
                      paymentFee: paymentFee,
                    }),
                  }}
                />
              ) : null}
            </ApprovalContainer>
          </SuccessContainer>

          {paymentMethods?.length > 0 ? (
            <Subtitle m={'10px 0px 10px 0px'}>{translate('paymentMethodList.payment.method')}</Subtitle>
          ) : null}

          {debitOrAchMethods?.length > 0 ? (
            <>
              {checkingACHMethodContents()?.map((paymentMethod, index) => (
                <PaymentMethodCard
                  key={`CHECKING-ACH-${index}-${paymentMethod.id}`}
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethodHandler}
                  selectedPaymentMethod={installmentMethod}
                  isPreferred={isPreferredType === 'checking'}
                />
              ))}

              {debitCardMethodContents()?.map((paymentMethod, index) => (
                <PaymentMethodCard
                  key={`DEBIT-CARD-${index}-${paymentMethod.id}`}
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethodHandler}
                  selectedPaymentMethod={installmentMethod}
                  isPreferred={isPreferredType === 'debit'}
                />
              ))}

              {savingACHMethodContents()?.map((paymentMethod, index) => (
                <PaymentMethodCard
                  key={`SAVING-ACH-${index}-${paymentMethod.id}`}
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethodHandler}
                  selectedPaymentMethod={installmentMethod}
                  isPreferred={isPreferredType === 'savings'}
                />
              ))}

              {bankRCCMethodContents()?.map((paymentMethod, index) => (
                <PaymentMethodCard
                  key={`RCC-${index}-${paymentMethod.id}`}
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethodHandler}
                  selectedPaymentMethod={installmentMethod}
                  isPreferred={false}
                />
              ))}
            </>
          ) : (
            <>
              {creditCardMethodContents()?.map((paymentMethod, index) => (
                <PaymentMethodCard
                  key={`CREDIT-CARD-${index}-${paymentMethod.id}`}
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethodHandler}
                  selectedPaymentMethod={installmentMethod}
                  isPreferred={false}
                />
              ))}
            </>
          )}
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>{translate('paymentMethodList.info')}</InfoText>
        </InfoContainer>
      </ContentContainer>

      <ButtonContainer>
        <Disclaimer
          dangerouslySetInnerHTML={{
            __html: disclaimerText,
          }}
        ></Disclaimer>
        <Button onClick={navigateToPlanSummary} disabled={!installmentMethod || loading} loading={loading}>
          {translate('buttons.agreeAndContinue')}
        </Button>
        <Button secondary={true} onClick={addPaymentMethodHandler}>
          {translate('buttons.addPaymentMethod')}
        </Button>
      </ButtonContainer>
      <AddPaymentMethodModal show={show} hideModal={hideModal} />
    </Container>
  );
};

export default PaymentMethodList;

const StyledSubtitle = styled(Subtitle)`
  strong {
    color: ${(props) => props.theme.main.green};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const SuccessContainer = styled.div`
  width: 100%;
  filter: drop-shadow(0px 0px 2px #d1d1d1);
  margin: 8px 0;
`;

const ApprovalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  border-radius: 8px 8px 0 0;
  padding: 24px 16px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-filter: drop-shadow(3px 3px 1px black);
  filter: drop-shadow(0px 0px 5px white);

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0px;
    content: '';
    display: block;
    background: radial-gradient(circle, #ffffff00 20px, #ffffff 20px);
    background-size: 35px 40px;
    background-position: -20px -20px;
    width: 100%;
    height: 40px;
    z-index: -1;
  }
`;

const Disclaimer = styled.div`
  color: ${(props) => props.theme.main.grayColor};
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: left;
`;
