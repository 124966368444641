import { gql } from '@apollo/client/core';

export const GET_PAYMENT_METHODS = gql`
  query GetPaymentMethods($input: GetPaymentMethodsInput) {
    getPaymentMethods(input: $input) {
      contents {
        firstName
        lastName
        zip
        type
        achAccount {
          accountNumber
          accountType
          bankName
          createdAt
          id
        }
        id
        createdAt
        storedCard {
          borrowerId
          expireMonth
          expireYear
          last4
          network
          type
          status
          id
          createdAt
        }
        rccAccount {
          accountNumber
          routingNumber
          bankName
          createdAt
          id
        }
      }
      total
    }
  }
`;

export const GET_PAYMENT_METHOD_FEES = gql`
  query GetPaymentFees {
    getPaymentFees {
      DEBIT {
        fixed
        percent
      }
      CREDIT {
        fixed
        percent
      }
    }
  }
`;

export const GET_ORGANIZATION_ACCOUNT_INFORMATION = gql`
  query fetchOrganizationAccountInformation($input: FetchOrganizationAccountInformationInput) {
    fetchOrganizationAccountInformation(input: $input) {
      organizationAccountId
      cherryPublicKey
    }
  }
`;
