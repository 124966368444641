import { gql } from '@apollo/client/core';

export const GET_FILE_LIST = gql`
  query GetFileList($input: FileListRequest) {
    getFileList(input: $input) {
      contents {
        id
      }
      total
    }
  }
`;

export const GET_FILE = gql`
  query GetFile($input: FileDetailRequest) {
    getFile(input: $input) {
      success
      data {
        data
        name
      }
    }
  }
`;
