import React from 'react';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Email } from './views/Email';
import { Review } from './views/Review';
import { HouseRent } from './views/HouseRent';
import { StatedIncome } from './views/StatedIncome';
import { RequestedAmount } from './views/RequestedAmount';

enum ActiveSteps {
  REQUESTED_AMOUNT = 'REQUESTED_AMOUNT',
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  HOUSING_SITUATION = 'HOUSING_SITUATION',
  EMAIL = 'EMAIL',
  STATED_INCOME = 'STATED_INCOME',
  REVIEW = 'REVIEW',
}

interface Props {
  type: 'REQUESTED_AMOUNT' | 'HOUSING_PAYMENT' | 'HOUSING_SITUATION' | 'STATED_INCOME' | 'EMAIL' | 'REVIEW';
}

const AdditionalInfo = ({ type }: Props) => {
  const { setSessionApiData, sessionApiData } = useStore.getState();
  const { missingInfo } = sessionApiData || {};
  const { navigate } = useNavigation();

  const saveEmailInfo = (email: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo?.key === ActiveSteps.EMAIL);
    findNextItemOrCompleteFlow(itemIndex, email);
  };

  const saveIVInfo = (ivInfo: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo?.key === ActiveSteps.STATED_INCOME);
    findNextItemOrCompleteFlow(itemIndex, ivInfo);
  };

  const saveHouseRent = (houseRent: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo?.key === ActiveSteps.HOUSING_PAYMENT);
    findNextItemOrCompleteFlow(itemIndex, houseRent === '0' ? '1' : houseRent);
  };

  const saveHouseSituation = (houseSituation: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo?.key === ActiveSteps.HOUSING_SITUATION);
    findNextItemOrCompleteFlow(itemIndex, houseSituation);
  };

  const saveRequestedAmount = (requestedAmount: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo?.key === ActiveSteps.REQUESTED_AMOUNT);
    findNextItemOrCompleteFlow(itemIndex, requestedAmount);
  };

  const getNavigatingUrl = (key) => {
    switch (key) {
      case 'EMAIL':
        return 'email';
      case 'HOUSING_PAYMENT':
      case 'HOUSING_SITUATION':
        return 'housing-rent';
      case 'STATED_INCOME':
        return 'income';
      case 'REQUESTED_AMOUNT':
        return 'requested-amount';
    }
  };

  const findNextItemOrCompleteFlow = (itemIndex, value) => {
    const newMissingInfo = [...missingInfo];
    newMissingInfo[itemIndex].value = value;
    newMissingInfo[itemIndex].isCompleted = true;

    const nextStepRequirement = newMissingInfo?.find((mInfo) => !mInfo?.isCompleted);
    setSessionApiData({ missingInfo: newMissingInfo });

    if (!!nextStepRequirement) {
      navigate(`approval-boost/additional-info/${getNavigatingUrl(nextStepRequirement.key)}`);
    } else {
      navigate('approval-boost/additional-info/review');
    }
  };

  const renderActiveStep = () => {
    if (type === ActiveSteps.EMAIL) {
      return <Email saveEmailInfo={saveEmailInfo} />;
    } else if (type === ActiveSteps.REQUESTED_AMOUNT) {
      return <RequestedAmount saveRequestedAmount={saveRequestedAmount} />;
    } else if (type === ActiveSteps.STATED_INCOME) {
      return <StatedIncome saveIVInfo={saveIVInfo} />;
    } else if (type === ActiveSteps.HOUSING_PAYMENT) {
      return <HouseRent saveHouseRent={saveHouseRent} saveHouseSituation={saveHouseSituation} />;
    } else if (type === ActiveSteps.REVIEW) {
      return <Review missingInfos={missingInfo} />;
    } else {
      return <></>;
    }
  };

  return renderActiveStep();
};

export default AdditionalInfo;
