import { InputAdornment } from '@mui/material';
import { Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';

import CancelModal from './views/CancelModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const RequestLineExpansion = () => {
  const { pollLatestCreditLineIncrease, createLineExpansion, createLoading } = useCreditLineIncrease();
  const { navigateBack } = useNavigation();
  const { t: translate } = useTranslation();
  const { application, setSessionApiData } = useStore.getState() || {};

  const [requestedAmount, setRequestedAmount] = useState<number | null>();
  const [formattedAmount, setFormattedAmount] = useState<number | null>();
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);

  const [validationError, setValidationError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    sendLoadSegmentEvent(WPQSegmentNames.highLineTreatmentCostLoad);
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.highLineTreatmentCostLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setRequestedAmount(Number(value));
      setFormattedAmount(Number(value));
      setValidationError(false);
    }
  };

  const handleCreateLineIncrease = async () => {
    sendActionSegmentEvent(WPQSegmentNames.highLineTreatmentCostContinue);
    if (requestedAmount && requestedAmount <= application?.balanceAvailable) {
      setValidationError(true);
      setErrorMessage(
        translate('requestLineExpansion.error.message', {
          balanceAvailable: FormatAmount(application?.balanceAvailable),
        }),
      );
    } else if (requestedAmount && requestedAmount > application?.menu?.maxPurchase) {
      setValidationError(true);
      setErrorMessage(
        translate('requestLineExpansion.error.messageElseIf', {
          maxPurchase: FormatAmount(application?.menu?.maxPurchase),
        }),
      );
    } else {
      setValidationError(false);
      const res = await createLineExpansion(Number(requestedAmount), application?.id);
      setSessionApiData({ creditLineIncrease: res });
      await pollLatestCreditLineIncrease();
    }
  };

  const toggleCancelButton = () => {
    setIsCancelModalOpened(!isCancelModalOpened);
  };

  const closeCancelModal = () => {
    setIsCancelModalOpened(false);
    navigateBack();
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (requestedAmount) {
        handleCreateLineIncrease();
      }
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'payment'} />
        <Title m={'10px 0px 8px 0px'}>{translate('requestLineExpansion.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('requestLineExpansion.subTitle')}</Subtitle>
        <TextField
          id="requested-amount"
          variant="filled"
          data-testid="requested-amount"
          data-neuro-label="requested-amount"
          label={translate('requestLineExpansion.form.requestedAmount')}
          type="tel"
          value={formattedAmount}
          max={application?.menu?.maxPurchase}
          min={application?.balanceAvailable}
          onChange={handlePriceChange}
          onKeyDown={checkKeyDown}
          error={validationError}
          onFocus={() => {
            sendActionSegmentEvent(WPQSegmentNames.highLineStatedIncomeClicked);
          }}
          onBlur={(e) => {
            sendActionSegmentEvent(WPQSegmentNames.highLineStatedIncomeFilled, {
              metaData: e.target.defaultValue,
            });
          }}
          helperText={validationError ? errorMessage : ''}
          InputProps={{
            inputComponent: NumberFormatInput,
            pattern: '[^0-9.]',
            startAdornment: (
              <InputAdornment
                sx={{
                  padding: '1px 0 0 0',
                  '& .MuiTypography-root': { color: '#000' },
                }}
                position="start"
              >
                $
              </InputAdornment>
            ),
          }}
        />
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={handleCreateLineIncrease}
          loading={createLoading}
          disabled={!requestedAmount || createLoading || validationError}
        >
          {translate('buttons.continue')}
        </Button>
        <LinkText onClick={toggleCancelButton}>{translate('buttons.nevermind')}</LinkText>
      </ButtonContainer>
      {isCancelModalOpened && (
        <CancelModal show={isCancelModalOpened} hideModal={toggleCancelButton} onApproved={closeCancelModal} />
      )}
    </Container>
  );
};

export default RequestLineExpansion;

const LinkText = styled.a`
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
  display: block;
`;
