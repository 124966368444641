import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import NavigateService from 'lib/services/Navigate';

import PlaidErrorHandler from 'pages/PlaidErrorHandler';
import { WEB_SITE } from './lib/constants';
import { Redirect } from './lib/utils';
import {
  AddCard,
  AddPlaidBank,
  AddRemoteCheck,
  ApplicationReview,
  Approval,
  AuthChecker,
  Autopay,
  BookAppointment,
  BrokenLink,
  Cancelled,
  CantFindBankAccount,
  CheckingOut,
  CheckoutInStore,
  CherryAccess,
  CherryAccessAdditionalInfo,
  ChoosePlan,
  Confirm,
  ConnectBankAccount,
  ConnectWithMXRetry,
  ConnectWithOauth,
  ConnectWithPlaidRetry,
  CustomizePlan,
  DataGathering,
  Declined,
  DefaultHighLine,
  DownPayment,
  DueDateAlignment,
  EmailCollection,
  EmailInfo,
  Expired,
  Faq,
  FrozenCredit,
  GetStarted,
  IdReview,
  IdReviewConnect,
  InactiveOrganization,
  Incomplete,
  LineExpansionAdditionalInfo,
  LocationSelection,
  MXAuth,
  Otp,
  PaymentMethodList,
  PayrollFileUpload,
  PaystubFileUpload,
  PersonalAddressInfo,
  PersonalAddressVerify,
  PersonalInfo,
  PersonalPhoneNumber,
  PlaidAuth,
  PlaidPayrollComplete,
  PlaidPaystubsComplete,
  PlanReview,
  Plans,
  PosCheckout,
  PosCongrats,
  PosLanding,
  PosLinkCanceled,
  PosPayCard,
  PosPaymentReview,
  PosQualify,
  PosSuccess,
  PreviewMyPayments,
  RemoteCheckReview,
  RequestAmount,
  RequestLineExpansion,
  Result,
  Review,
  SsnCollect,
  SsnConflict,
  SsnInput,
  SsnVerification,
  Success,
  Survey,
  SurveyOffer,
  Terms,
  TreatmentType,
  UploadPaystub,
  VerifyIncome,
  VerifyIncomeSuccess,
  WhatHappensNow,
  Acknowledgement,
  ApplyWithPatient,
  WhatHappensNext,
  YouAllSet,
  PreviewPaymentOptions,
} from './pages';
import ApplyCheckoutLanding from 'pages/ApplyCheckoutLanding';
import PhoneUpdate from 'pages/PhoneUpdate';
import useStore from './lib/hooks/useStore';
import FullSsnCollect from 'pages/FullSsnCollect';

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { authData } = useStore();
  const { token } = authData || {};

  const urlState = location.pathname.split('/');
  const urlName = urlState[1];

  useEffect(() => {
    if (urlName === '') {
      Redirect(WEB_SITE, '_self');
    } else if (urlState.length > 2 && token) {
      navigate(`/${urlName}`);
    }

    NavigateService.initNavigate(navigate);
  }, []);

  return (
    <Container>
      <Routes>
        <Route path="/" element={<AuthChecker />}>
          {/*  Loan Eligible Flow Start  */}
          <Route path=":id" element={<GetStarted />} />
          <Route path=":id/terms" element={<Terms />} />
          <Route path=":id/phone" element={<PersonalPhoneNumber />} />
          <Route path=":id/verify" element={<Otp />} />
          <Route path=":id/connect-bank-account/:loanId" element={<ConnectBankAccount />} />
          <Route path=":id/mx-auth/:loanId" element={<MXAuth />} />
          <Route path=":id/plaid-auth/:loanId" element={<PlaidAuth />} />
          <Route path=":id/bank-connect-callback/:oauthStateId" element={<ConnectWithOauth />} />
          <Route path=":id/upload-paystub/:idLoan" element={<UploadPaystub />} />
          <Route path=":id/retry-plaid-connect/:idLoan" element={<ConnectWithPlaidRetry />} />
          <Route path=":id/plaid-error-handler/:errorCode" element={<PlaidErrorHandler />} />
          <Route path=":id/retry-mx-connect/:connectionStatus/:idLoan" element={<ConnectWithMXRetry />} />
          <Route path=":id/email-collection" element={<EmailCollection />} />
          <Route path=":id/paystubs-file-upload/:idLoan" element={<PaystubFileUpload />} />
          <Route path=":id/payrolls-file-upload/:idLoan" element={<PayrollFileUpload />} />
          <Route path=":id/phone-update" element={<PhoneUpdate />} />
          <Route path=":id/id-review" element={<IdReview />} />
          <Route path=":id/id-review/self" element={<IdReviewConnect />} />
          <Route path=":id/info" element={<PersonalInfo />} />
          <Route path=":id/email-info" element={<EmailInfo />} />
          <Route path=":id/request-amount" element={<RequestAmount />} />
          <Route path=":id/address-info" element={<PersonalAddressInfo />} />
          <Route path=":id/address-verify" element={<PersonalAddressVerify />} />
          <Route path=":id/application-review" element={<ApplicationReview />} />
          <Route path=":id/treatment-type" element={<TreatmentType />} />
          <Route path=":id/ssn-collect" element={<SsnCollect />} />
          <Route path=":id/full-ssn-collect" element={<FullSsnCollect />} />
          <Route path=":id/ssn" element={<SsnInput />} />
          <Route path=":id/update-location" element={<LocationSelection />} />
          <Route path=":id/checking-out" element={<CheckingOut />} />
          <Route path=":id/book-an-appointment" element={<BookAppointment />} />
          <Route path=":id/preview-my-payments" element={<PreviewMyPayments />} />
          <Route path=":id/result" element={<Result />} />
          <Route path=":id/approval" element={<Approval />} />
          <Route path=":id/preview-payment" element={<PreviewPaymentOptions />} />
          <Route path=":id/what-happens-now" element={<WhatHappensNow />} />
          <Route path=":id/what-happens-next" element={<WhatHappensNext />} />
          <Route path=":id/all-set" element={<YouAllSet />} />
          <Route path=":id/checkout-in-person" element={<CheckoutInStore />} />
          <Route path=":id/faq" element={<Faq />} />
          <Route path=":id/ssn-verification" element={<SsnVerification />} />
          <Route path=":id/plaid-paystubs-complete" element={<PlaidPaystubsComplete />} />
          <Route path=":id/plaid-payrolls-complete" element={<PlaidPayrollComplete />} />
          {/* CLI */}
          <Route path=":id/request-increase" element={<RequestLineExpansion />} />
          <Route
            path=":id/additional-info/housing-rent"
            element={<LineExpansionAdditionalInfo type={'HOUSING_PAYMENT'} />}
          />
          <Route path=":id/additional-info/income" element={<LineExpansionAdditionalInfo type={'STATED_INCOME'} />} />
          <Route path=":id/additional-info/email" element={<LineExpansionAdditionalInfo type={'EMAIL'} />} />
          <Route path=":id/additional-info/review" element={<LineExpansionAdditionalInfo type={'REVIEW'} />} />
          <Route path=":id/default-line/stated-income" element={<DefaultHighLine type={'STATED_INCOME'} />} />
          <Route path=":id/default-line/housing-rent" element={<DefaultHighLine type={'HOUSING_PAYMENT'} />} />
          <Route path=":id/default-line/email" element={<DefaultHighLine type={'EMAIL'} />} />
          {/* CLI */}

          {/* Cherry Access */}
          <Route path=":id/approval-boost" element={<CherryAccess />} />
          <Route
            path=":id/approval-boost/additional-info/requested-amount"
            element={<CherryAccessAdditionalInfo type={'REQUESTED_AMOUNT'} />}
          />
          <Route
            path=":id/approval-boost/additional-info/housing-rent"
            element={<CherryAccessAdditionalInfo type={'HOUSING_PAYMENT'} />}
          />
          <Route
            path=":id/approval-boost/additional-info/income"
            element={<CherryAccessAdditionalInfo type={'STATED_INCOME'} />}
          />
          <Route
            path=":id/approval-boost/additional-info/email"
            element={<CherryAccessAdditionalInfo type={'EMAIL'} />}
          />
          <Route
            path=":id/approval-boost/additional-info/review"
            element={<CherryAccessAdditionalInfo type={'REVIEW'} />}
          />
          {/* Cherry Access */}
          {/*  Loan Eligible Flow End  */}

          {/*  Checkout Flow Start  */}
          <Route path=":id/choose-plan" element={<ChoosePlan />} />
          <Route path=":id/acknowledgement" element={<Acknowledgement />} />
          <Route path=":id/plans" element={<Plans />} />
          <Route path=":id/customize-plan" element={<CustomizePlan />} />
          <Route path=":id/plan-review" element={<PlanReview />} />
          <Route path=":id/add-card" element={<AddCard />} />
          <Route path=":id/add-remote-check" element={<AddRemoteCheck />} />
          <Route path=":id/plaid-bank-connection" element={<AddPlaidBank />} />
          <Route path=":id/remote-check-review" element={<RemoteCheckReview />} />
          <Route path=":id/down-payment" element={<DownPayment />} />
          <Route path=":id/method-list" element={<PaymentMethodList />} />
          <Route path=":id/autopay" element={<Autopay />} />
          <Route path=":id/survey" element={<Survey />} />
          <Route path=":id/survey-offer" element={<SurveyOffer />} />
          <Route path=":id/data-gathering" element={<DataGathering />} />
          <Route path=":id/confirm" element={<Confirm />} />
          <Route path=":id/due-date-alignment" element={<DueDateAlignment />} />
          <Route path=":id/success" element={<Success />} />
          <Route path=":id/expired" element={<Expired />} />
          {/*  Checkout Flow End  */}

          {/* Income Verification Flow Start */}
          <Route path=":id/income-verification" element={<ConnectBankAccount />} />
          {/* Income Verification Flow End */}

          {/* Bank Connection Flow Start */}
          <Route path=":id/plaid-bank-connection/:idBorrower" element={<ConnectBankAccount />} />
          {/* Bank Connection Flow End */}

          {/*  Verify Income Flow Start  */}
          <Route path=":id/verify-income" element={<VerifyIncome />} />
          <Route path=":id/find-bank-account" element={<CantFindBankAccount />} />
          <Route path=":id/verify-income-success" element={<VerifyIncomeSuccess />} />
          {/*  Verify Income Flow End  */}

          {/*  UnHappy Paths Start  */}
          <Route path=":id/file-frozen" element={<FrozenCredit />} />
          <Route path=":id/broken-link" element={<BrokenLink />} />
          <Route path=":id/loan-cancelled" element={<Cancelled />} />
          <Route path=":id/declined" element={<Declined />} />
          <Route path=":id/incomplete" element={<Incomplete />} />
          <Route path=":id/review" element={<Review />} />
          <Route path=":id/ssn-conflict" element={<SsnConflict />} />
          {/*  UnHappy Paths End */}

          {/*  Point of Sale  */}
          <Route path=":id/pos-landing" element={<PosLanding />} />
          <Route path=":id/pos-qualify" element={<PosQualify />} />
          <Route path=":id/pos-checkout" element={<PosCheckout />} />
          <Route path=":id/pos-success" element={<PosSuccess />} />
          <Route path=":id/pos-payment-review" element={<PosPaymentReview />} />
          <Route path=":id/pos-pay-card" element={<PosPayCard />} />
          <Route path=":id/pos-link-expired" element={<PosLinkCanceled />} />
          <Route path=":id/pos-congrats" element={<PosCongrats />} />
          {/*  Point of Sale  */}

          {/*  Apply Checkout  */}
          <Route path=":id/apply-checkout-landing" element={<ApplyCheckoutLanding />} />
          {/*  Apply Checkout  */}

          {/*  Organization Not Found  */}
          <Route path=":id/not-found" element={<InactiveOrganization />} />
          {/*  Organization Not Found  */}

          {/* Apply with Patient Review */}
          <Route path=":id/apply-with-patient" element={<ApplyWithPatient />} />
          {/* Apply with Patient Review */}
        </Route>
      </Routes>
    </Container>
  );
};

export default Router;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
