import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { WEBPREQUAL } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import { emailPattern } from 'lib/utils';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import CancelModal from './CancelModal';
import useStore from 'lib/hooks/useStore';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

export const EmailInfo = ({ saveEmailInfo, onCancelModalApprovedClick }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { trackSegmentEvent, trackIdentify } = useSegment();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.highLineEmailLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isValid } = {},
    setError,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onSubmit',
  });

  const toggleCancelModal = () => setShowCancelModal(!showCancelModal);

  const onCancelApproved = () => {
    const { application, borrower } = useStore.getState() || {};
    trackSegmentEvent('Canceled Line Increase', {
      borrowerId: borrower?.id,
      applicationId: application?.id,
      cancelScreen: 'Email Entry',
      application: WEBPREQUAL,
    });
    setShowCancelModal(false);
    onCancelModalApprovedClick();
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        next();
      }
    }
  };

  const next = () => {
    const { application, borrower } = useStore.getState() || {};
    sendActionSegmentEvent(WPQSegmentNames.highLineEmailContinue);

    const { email } = getValues();
    if (emailPattern.test(email)) {
      saveEmailInfo(email);
      trackIdentify({ ...borrower, email });
    } else {
      setIsFormValid(false);
      setError('email', { type: 'pattern', message: translate('cherryAccess.email.error') });
    }
  };

  const emailValue = watch('email');

  const onFormValueChange = (e, callback) => {
    clearErrors('email');
    const searchTerm = '@';
    const value = e?.target?.value;

    let isActive = false;
    if (value) {
      if (value.indexOf(searchTerm) >= 0 && emailPattern.test(value)) {
        isActive = true;
      }
    }

    setIsFormValid(isActive);
    callback(e);
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'mail'} />
        <Title m={'10px 0px 8px 0px'}>{translate('cherryAccess.email.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('cherryAccess.email.subTitle')}</Subtitle>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: translate('cherryAccess.email.form.emailMessage'),
              },
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email"
                  variant="filled"
                  data-testid="email"
                  data-neuro-label="email"
                  label={translate('cherryAccess.email.form.emailLabel')}
                  type="text"
                  value={value}
                  onChange={(e) => onFormValueChange(e, onChange)}
                  onKeyDown={checkKeyDown}
                  onFocus={() => {
                    sendActionSegmentEvent(WPQSegmentNames.highLineEmailClicked);
                  }}
                  onBlur={(e) => {
                    sendActionSegmentEvent(WPQSegmentNames.highLineEmailFilled, {
                      metaData: e.target.defaultValue,
                    });
                    onBlur;
                  }}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={!isFormValid || (!emailValue && emailValue !== '')}>
          {translate('buttons.continue')}
        </Button>
        <LinkText onClick={toggleCancelModal}>{translate('buttons.nevermind')}</LinkText>
      </ButtonContainer>
      {showCancelModal && (
        <CancelModal show={showCancelModal} hideModal={toggleCancelModal} onApproved={onCancelApproved} />
      )}
    </Container>
  );
};

const LinkText = styled.a`
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
  display: block;
`;
