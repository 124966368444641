import { useNavigation } from 'lib/hooks/useNavigation';
import React from 'react';
import { EmailInfo } from './views/EmailInfo';
import { HouseRent } from './views/HouseRent';
import { WEBPREQUAL } from 'lib/constants';

import { StatedIncome } from './views/StatedIncome';
import { useSegment } from 'lib/hooks/useSegment';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import useStore from 'lib/hooks/useStore';

enum ActiveSteps {
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  EMAIL = 'EMAIL',
  STATED_INCOME = 'STATED_INCOME',
}

enum CreditLineIncreaseReviewItems {
  EMAIL = 'EMAIL',
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  STATED_INCOME = 'STATED_INCOME',
  REQUESTED_AMOUNT = 'REQUESTED_AMOUNT',
}

interface Props {
  type: 'HOUSING_PAYMENT' | 'STATED_INCOME' | 'EMAIL' | 'REVIEW';
}

const LineExpansionAdditionalInfo = ({ type }: Props) => {
  const { navigate } = useNavigation();
  const { trackSegmentEvent } = useSegment();
  const { pollLatestCreditLineIncrease, createLineExpansion } = useCreditLineIncrease();

  const { application, borrower, sessionApiData, setCliMissingInfo, cliMissingInfo } = useStore();
  const { creditLineIncrease } = sessionApiData || {};
  const { missingInfo } = cliMissingInfo || {};

  const saveEmailInfo = (email: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo.key === ActiveSteps.EMAIL);
    findNextItemOrCompleteFlow(itemIndex, email);
  };

  const saveIVInfo = (ivInfo: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo.key === ActiveSteps.STATED_INCOME);
    findNextItemOrCompleteFlow(itemIndex, ivInfo);
  };

  const saveHouseRent = (houseRent: string) => {
    const itemIndex = missingInfo?.findIndex((mInfo) => mInfo.key === ActiveSteps.HOUSING_PAYMENT);
    findNextItemOrCompleteFlow(itemIndex, houseRent === '0' ? '1' : houseRent);
  };

  const getNavigatingUrl = (key) => {
    switch (key) {
      case 'EMAIL':
        return 'email';
      case 'HOUSING_PAYMENT':
        return 'housing-rent';
      case 'STATED_INCOME':
        return 'income';
    }
  };

  const findNextItemOrCompleteFlow = (itemIndex, value) => {
    const newMissingInfo = [...missingInfo];
    newMissingInfo[itemIndex].value = value;
    newMissingInfo[itemIndex].isCompleted = true;

    const nextStepRequirement = newMissingInfo?.find((mInfo) => !mInfo?.isCompleted);
    setCliMissingInfo({ missingInfo: newMissingInfo });
    if (!!nextStepRequirement) {
      navigate(`additional-info/${getNavigatingUrl(nextStepRequirement?.key)}`);
    } else {
      next();
    }
  };

  const next = async () => {
    trackSegmentEvent('line_increase_completed', {
      applicationId: application?.id,
      borrowerId: borrower?.id,
      application: WEBPREQUAL,
    });
    const statedInfos = missingInfo?.map((mInfo) => {
      let value;
      switch (mInfo.key) {
        case CreditLineIncreaseReviewItems.HOUSING_PAYMENT:
          value = mInfo?.value;
          break;
        case CreditLineIncreaseReviewItems.STATED_INCOME:
          value = mInfo?.value;
          break;
        case CreditLineIncreaseReviewItems.EMAIL:
          value = mInfo?.value;
      }

      return { type: mInfo.key, value: String(value) };
    });
    await createLineExpansion(creditLineIncrease?.amount?.requested, application?.id, statedInfos);
    await pollLatestCreditLineIncrease();
  };

  const onCancelModalApprovedClick = () => {
    navigate('approval');
  };

  const renderActiveStep = () => {
    if (type === ActiveSteps.EMAIL) {
      return <EmailInfo saveEmailInfo={saveEmailInfo} onCancelModalApprovedClick={onCancelModalApprovedClick} />;
    } else if (type === ActiveSteps.STATED_INCOME) {
      return <StatedIncome saveIVInfo={saveIVInfo} onCancelModalApprovedClick={onCancelModalApprovedClick} />;
    } else if (type === ActiveSteps.HOUSING_PAYMENT) {
      return <HouseRent saveHouseRent={saveHouseRent} onCancelModalApprovedClick={onCancelModalApprovedClick} />;
    } else {
      return <></>;
    }
  };

  return renderActiveStep();
};

export default LineExpansionAdditionalInfo;
