import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Grid } from '@mui/material';
import Confetti from 'react-confetti';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import timezone from 'dayjs/plugin/timezone';
import { useApolloClient, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { IApplication } from 'lib/types';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { confettiColors, theme } from 'config/theme';
import { GET_APPLICATION } from 'lib/graphql/queries';
import { useNavigation } from 'lib/hooks/useNavigation';
import { FormatAmount, FormatPrice } from 'lib/utils';
import { EXPIRED_DATE_FORMAT, LANGUAGES } from 'lib/constants';

import { FaqListModal } from 'lib/components/FaqListModal';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Button, Container, Icon, Loading, Subtitle, SurveyTypeForm, Title } from 'lib/components';
import {
  CreditLineIncreaseStatus,
  CreditLineIncreaseSubStatus,
  useCreditLineIncrease,
} from 'lib/hooks/useCreditLineIncrease';

import faqList from 'pages/Faq/components/faq.json';
import ChooseAPlan from 'pages/Approval/ChooseAPlan';
import { PaymentPlanModal } from 'pages/Approval/modals/PaymentPlanModal';
import CreditLineIncreaseModal from './modals/CreditLineIncreaseModal';
import AlleApproval from './AlleApproval';
import MickeyMouseModal from './modals/MickeyMouseModal';
import { PartialApprovalModal } from './modals/PartialApprovalModal';
import { CREATE_LOANS } from 'lib/graphql/mutations';
import { useServices } from 'pages/AuthChecker/services';
import { loanStatusService } from 'pages/AuthChecker/utilityFunctions';
import RevampMickeyMouseModal from './modals/RevampMickeyMouseModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';

dayjs.extend(utc);
dayjs.extend(timezone);

enum CreditLineStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
}

const STANDARD_APPROVAL_PAGE_FLOWS = ['ApplyCheckoutFlow', 'PointOfSalesFlow'];

const OldApproval = () => {
  const { t: translate } = useTranslation();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { submitApplication } = useNeuroId();
  const client = useApolloClient();
  const { getProductCalculation } = useProductCalculation();
  const [createLoanRequest] = useMutation(CREATE_LOANS);
  const { getApplication } = useServices();
  const alert = useAlert();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();

  const {
    organization,
    selectedLocation,
    locations,
    setSelectedApplication,
    organizationGroup,
    flowType,
    isAlle,
    setApplication,
    productCalculations,
    setSelectedPlan,
    sessionData,
    application,
    setLoan,
    defaultLanguage,
    sessionApiData,
  } = useStore();

  const [displayFaqModal, setDisplayFaqModal] = useState(false);
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [contentsData, setContentsData] = useState<IApplication | null>(null);
  const [showMickeyMouseModal, setShowMickeyMouseModal] = useState(false);
  const [showRevampMickeyMouseModal, setShowRevampMickeyMouseModal] = useState(false);
  const [chooseAPlanLoading, setChooseAPlanLoading] = useState(false);
  const [readyForAnalyticEvents, setReadyForAnalyticEvents] = useState<boolean>(false);
  const [displayPaymentPlanModal, setDisplayPaymentPlanModal] = useState(false);

  const [isPartialApprovalModalVisible, setIsPartialApprovalModalVisible] = useState(false);

  const {
    fetchLatestCreditLineIncrease,
    pollLatestCreditLineIncrease,
    loading,
    creditLineIncrease,
    setShowCreditLineIncreaseModal,
    showCreditLineIncreaseModal,
  } = useCreditLineIncrease();

  const [creditLineIncreaseItem, setCreditLineIncreaseItem] = useState(
    creditLineIncrease || sessionApiData?.creditLineIncrease,
  );

  const [defaultConfetti, setDefaultConfetti] = useState(false);

  const promoProducts = productCalculations?.filter((product) => product?.promo);
  const lowestInterestRateProduct = promoProducts?.reduce((lowestProduct, product) => {
    const promoApr = product?.product?.promoApr;
    const apr = product?.product?.apr;

    if (promoApr !== null && promoApr < (lowestProduct?.product?.promoApr || Infinity)) {
      return product;
    } else if (apr < (lowestProduct?.product?.apr || Infinity)) {
      return product;
    }
    return lowestProduct;
  }, null);

  const index = productCalculations?.findIndex((product) => product === lowestInterestRateProduct);
  const [selectedIndex, setSelectedIndex] = useState<number>(index || 0);

  useEffect(() => {
    const index = productCalculations?.findIndex((product) => product === lowestInterestRateProduct);
    setSelectedIndex(index);
  }, [productCalculations]);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const containerRef: any = useRef(null);
  const [confettiActive, setConfettiActive] = useState(defaultConfetti);

  const getInitialStatus = () => {
    const { applicationFlow } = useStore.getState() || {};

    setDefaultConfetti(
      dayjs().diff(applicationFlow?.updatedAt, 'minutes') < 135 && applicationFlow?.status === 'APPROVED',
    );
  };

  const setAnalyticsEvent = () => {
    const { application } = useStore.getState() || {};

    sendLoadSegmentEvent(WPQSegmentNames.approvalPageLoad, { approvalAmount: application?.balanceAvailable });
  };

  useEffect(() => {
    getInitialStatus();

    setTimeout(() => {
      setConfettiActive(false);
    }, 6000);
  }, []);

  useEffect(() => {
    const { application } = useStore.getState() || {};
    if (application?.id) {
      fetchLatestCreditLineIncrease(application?.id).then((res) => {
        if (res?.id) {
          setCreditLineIncreaseItem(res);
          checkCreditLineIncreaseModalStatus(res);
        }
      });
    }
  }, []);

  const checkCreditLineIncreaseModalStatus = (cLineIncreaseItem) => {
    if (
      [CreditLineIncreaseStatus.REVIEW].includes(cLineIncreaseItem?.status) &&
      ![
        CreditLineIncreaseSubStatus.PROCESSING,
        CreditLineIncreaseSubStatus.MISSING_INFO,
        CreditLineIncreaseSubStatus.APPROVED,
        CreditLineIncreaseSubStatus.MORE,
      ].includes(cLineIncreaseItem?.subStatus) &&
      cLineIncreaseItem?.source === 'NORMAL'
    ) {
      setShowCreditLineIncreaseModal(true);
    } else {
      setShowCreditLineIncreaseModal(false);
    }
  };

  useEffect(() => {
    const { application } = useStore.getState();
    checkCreditLineIncreaseModalStatus(creditLineIncreaseItem);
    if (application?.id) {
      getApplicationsWithSearch?.(application?.id);
    }
    submitApplication('approval');
  }, []);

  useEffect(() => {
    if (readyForAnalyticEvents) {
      setAnalyticsEvent();
    }
  }, [readyForAnalyticEvents]);

  const hideCreditLineIncreaseModal = () => setShowCreditLineIncreaseModal(false);

  const hideMickeyMouseModal = () => setShowMickeyMouseModal(false);
  const hideRevampMickeyMouseModal = () => setShowRevampMickeyMouseModal(false);
  const handleCarouselChange = (index: number) => {
    sendActionSegmentEvent(`WPQ.OUTCOME.APPROVAL_${productCalculations[index].product.term}_MONTH_PLAN_CLICKED`);
    setSelectedIndex(index);
  };

  const handleContinue = () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked, {
      selectedPlan: productCalculations?.[selectedIndex]?.product?.term,
    });
    setSelectedPlan(productCalculations?.[selectedIndex ?? 0]);
    setDisplayPaymentPlanModal(true);
  };

  const hideContinueModal = () => {
    setDisplayPaymentPlanModal(false);
  };

  const handleDisplayFAQ = () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalHaveQuestionClicked, {
      value: 70,
      organizationSlug: organization?.slug,
      organizationName: organization?.name,
    });
    setDisplayFaqModal(true);
  };

  const changeLocationHandler = () => {
    sendActionSegmentEvent(WPQSegmentNames.changeLocationClicked);
    navigate(`update-location`);
  };

  const getApplicationsWithSearch = async (applicationId: string) => {
    setContentLoading(true);
    const { applicationFlow } = useStore.getState() || {};
    try {
      if (applicationId) {
        const { data } = await client.query({
          query: GET_APPLICATION,
          variables: {
            input: {
              applicationId,
            },
          },
        });

        if (data?.getApplication?.id) {
          const response = data.getApplication;
          setContentsData(response);
          setSelectedApplication(response);
          setContentLoading(false);
          setReadyForAnalyticEvents(true);
          setApplication(response);
          if (
            response.mickeyMouse &&
            response.creditLineIncrease === CreditLineStatus.ELIGIBLE &&
            applicationFlow.requestAmount > response.balanceAvailable
          ) {
            setShowRevampMickeyMouseModal(true);
          }
          if (response?.balanceAvailable) {
            try {
              setChooseAPlanLoading(true);
              await getProductCalculation(applicationId, response?.balanceAvailable);
              setChooseAPlanLoading(false);
            } catch (err) {
              setChooseAPlanLoading(false);
            }
          }

          return;
        } else {
          setContentLoading(false);
        }
      } else {
        throw new Error('OldApproval Page - application id not found.');
      }
    } catch (err) {
      captureException(err, {
        message: 'OldApproval Page - fetch application list with search error',
        error: err,
      });
      setContentLoading(false);
    }
  };

  const requestAnIncrease = async () => {
    if (contentsData?.mickeyMouse) {
      setShowMickeyMouseModal(true);
      sendActionSegmentEvent(WPQSegmentNames.mickeyMouseRequestIncreaseClicked);
    } else {
      sendActionSegmentEvent(WPQSegmentNames.requestIncreaseClicked);
      await pollLatestCreditLineIncrease('APPROVAL_SCREEN');
    }
  };

  const iconName = () => {
    return `party`;
  };

  const titleText = () => {
    return translate('approval.title');
  };

  const preferredLocationText = (): JSX.Element | string => {
    const merchantId = contentsData?.merchantId;
    const findLocation = locations?.find((loc) => loc.id === merchantId);

    if (selectedLocation || findLocation) {
      const { name, address } = findLocation || selectedLocation || {};
      return (
        <LocationText>
          {name} - {address?.city?.name ?? address?.city}
        </LocationText>
      );
    } else if (locations?.length === 1) {
      const { name, address } = locations[0] || {};
      return (
        <LocationText>
          {name} - {address?.city?.name ?? address?.city}
        </LocationText>
      );
    }
    return '';
  };

  const approvalPartialOrDeniedText = () => {
    if (
      creditLineIncreaseItem &&
      (creditLineIncreaseItem.source === 'DEFAULT_HIGH_LINE' || creditLineIncreaseItem.source === 'NORMAL') &&
      creditLineIncreaseItem.subStatus === 'PARTIAL'
    ) {
      return (
        <Subtitle m={'0px 0px 14px 0px'} textAlign="center">
          {translate('approval.cli.success', {
            balanceAvailable: FormatPrice(application?.balanceAvailable),
          })}
        </Subtitle>
      );
    }

    if (
      creditLineIncreaseItem &&
      creditLineIncreaseItem.source === 'NORMAL' &&
      creditLineIncreaseItem.status === 'DENIED'
    ) {
      return (
        <Subtitle m={'0px 0px 14px 0px'} textAlign="center">
          {translate('approval.cli.denied', {
            balanceAvailable: FormatPrice(application?.balanceAvailable),
          })}
        </Subtitle>
      );
    }

    return null;
  };

  const posCheckoutHandler = async () => {
    sendActionSegmentEvent(WPQSegmentNames.approvalContinueClicked);
    if (flowType === 'PointOfSalesFlow') {
      navigate('pos-checkout');
    } else if (flowType === 'ApplyCheckoutFlow') {
      setContentLoading(true);
      const params = {
        merchantId: selectedLocation?.id,
        applicationId: application?.id,
        purchaseAmount: Math.min(sessionData.treatmentAmount, Number(contentsData?.balanceAvailable)),
        selfCheckout: true,
        channel: 'APPLY_CHECKOUT',
      };
      const { data } = await createLoanRequest({ variables: { input: { ...params } } });
      if (data.createLoan?.data) {
        await getApplication(data.createLoan?.data?.applicationId);
        setLoan(data?.createLoan?.data);
        loanStatusService(data.createLoan?.data, organization?.slug);
      } else {
        alert.info(`${translate('approval.error.default')}`);
      }
      setContentLoading(false);
      navigate(`choose-plan`);
    }
  };

  const closePartialApprovalModal = () => {
    setIsPartialApprovalModalVisible(false);
  };

  const openPartialApprovalModal = () => {
    setIsPartialApprovalModalVisible(true);
  };

  if (contentLoading) {
    return (
      <CenterLoading>
        <Loading size={40} />
      </CenterLoading>
    );
  }

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          {confettiActive ? (
            <ConfettiContainer>
              <Confetti
                width={containerRef.current?.clientWidth}
                height={containerRef.current?.clientHeight}
                numberOfPieces={300}
                colors={confettiColors}
                recycle={false}
              />
            </ConfettiContainer>
          ) : null}
          <Icon src={iconName()} />
          <Title m={'10px 0px 24px 0px'}>{titleText()}</Title>
          <SuccessContainer>
            <Header>{translate('approval.approvalAmount')}</Header>
            <ApprovalContainer noGap={locations?.length > 1 || preferredLocationText?.() !== ''}>
              <Title fontSize={theme.constants.xxl} m={'16px 0px 24px 0px'} textAlign="center">
                ${FormatPrice(contentsData?.balanceAvailable)}
              </Title>

              {creditLineIncreaseItem?.status === 'REVIEW' &&
                !STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) &&
                !application?.mickeyMouse && (
                  <CreditLineIncreaseReview>
                    {translate('approval.cli.review', {
                      requested: FormatAmount(creditLineIncreaseItem?.amount?.requested),
                    })}
                  </CreditLineIncreaseReview>
                )}

              {contentsData?.creditLineIncrease === CreditLineStatus.ELIGIBLE && (
                <RequestIncreaseButtonContainer>
                  <Button secondary={true} onClick={requestAnIncrease} loading={loading} disabled={loading}>
                    {translate('buttons.requestAnIncrease')}
                  </Button>
                </RequestIncreaseButtonContainer>
              )}

              {approvalPartialOrDeniedText()}
              <Subtitle
                m={approvalPartialOrDeniedText?.() === null ? '14px 0px 24px 0px' : '0 0 24px 0'}
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html: translate('approval.validUntil', {
                    organizationName: organization?.name || organizationGroup?.name,
                    date: dayjs(contentsData?.expireAt).format(EXPIRED_DATE_FORMAT),
                  }),
                }}
              />
            </ApprovalContainer>
          </SuccessContainer>

          {!STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
            <LocationInfoContainer noMargin={preferredLocationText?.() === '' && locations?.length < 1}>
              {preferredLocationText()}
              {locations?.length > 1 ? (
                <ChangeLocationButton onClick={changeLocationHandler}>
                  {translate('approval.changeLocation')}
                </ChangeLocationButton>
              ) : null}
            </LocationInfoContainer>
          ) : null}

          <SurveyTypeForm
            page="APPROVAL"
            title={translate('approval.survey.title', {
              price: '$25',
            })}
            description={translate('approval.survey.description', {
              price: '$25',
            })}
          />

          {!STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
            <>
              {chooseAPlanLoading ? (
                <Loading margin={'0 auto'} size={30} />
              ) : (
                <ChooseAPlan
                  defaultSelected={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  handleCarouselChange={handleCarouselChange}
                />
              )}
            </>
          ) : null}

          {!STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
            <ButtonText isNew={true} onClick={handleDisplayFAQ}>
              {translate('buttons.haveAQuestion')}
            </ButtonText>
          ) : null}
        </InnerContainer>

        {flowType === 'ApplyCheckoutFlow' ? (
          Number(contentsData?.balanceAvailable) >= sessionData.treatmentAmount ? (
            <Grid container alignItems="center" wrap="nowrap" gap="8px">
              <Icon height={24} width={24} src="circle_check_outline_black" />
              <Subtitle>
                {translate('fullyCoversYou', {
                  treatmentAmount: FormatAmount(sessionData.treatmentAmount),
                })}
              </Subtitle>
            </Grid>
          ) : (
            <Grid direction="column" container gap="16px" mt={5}>
              <Subtitle>
                {translate('approval.partOfYour', {
                  treatmentAmount: FormatAmount(sessionData.treatmentAmount),
                })}
              </Subtitle>
              <Grid container item alignItems="center" wrap="nowrap" gap="8px">
                <Icon height={24} width={24} src="help_circle_fill" />
                <Subtitle cursor="pointer" onClick={openPartialApprovalModal}>
                  <u>{translate('approval.partialApproval')}</u>
                </Subtitle>
              </Grid>

              <PartialApprovalModal show={isPartialApprovalModalVisible} hideModal={closePartialApprovalModal} />
            </Grid>
          )
        ) : null}
      </ContentContainer>

      {STANDARD_APPROVAL_PAGE_FLOWS.includes(flowType) ? (
        <>
          <ButtonContainer>
            <Button onClick={posCheckoutHandler}>{translate('buttons.continue')}</Button>
          </ButtonContainer>
        </>
      ) : (
        <>
          {!chooseAPlanLoading && !!productCalculations && productCalculations?.length > 0 && selectedIndex >= 0 ? (
            <ButtonContainer>
              <Button onClick={handleContinue}>{translate('buttons.continue')}</Button>
            </ButtonContainer>
          ) : null}

          <PaymentPlanModal
            show={displayPaymentPlanModal}
            hideModal={hideContinueModal}
            chosenPlan={productCalculations?.[selectedIndex ?? 0]}
          />

          <FaqListModal
            hideModal={() => setDisplayFaqModal(false)}
            show={displayFaqModal}
            faqList={faqList?.[defaultLanguage || LANGUAGES[0]]}
            title={translate('buttons.commonQuestions')}
            titleMargin={'auto'}
            modalHeight={'80%'}
            modalWidth={'550px'}
            modalTop={'50px'}
            scrollEnabled={true}
          />

          {showCreditLineIncreaseModal ? (
            <CreditLineIncreaseModal
              show={showCreditLineIncreaseModal}
              hideModal={hideCreditLineIncreaseModal}
              creditLineIncrease={creditLineIncreaseItem}
            />
          ) : null}
        </>
      )}
      {showMickeyMouseModal ? <MickeyMouseModal show={showMickeyMouseModal} hideModal={hideMickeyMouseModal} /> : null}
      {showRevampMickeyMouseModal ? (
        <RevampMickeyMouseModal show={true} hideModal={hideRevampMickeyMouseModal} />
      ) : null}
    </Container>
  );
};

export default OldApproval;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ButtonText = styled.div<{ isNew?: boolean }>`
  font-weight: 700;
  font-size: ${(props) => (props?.isNew ? '18px' : '14px')};
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => (props?.isNew ? props.theme.main.textColor : props.theme.main.green)};
  margin: 24px 0 4px;
  cursor: pointer;
`;

const CenterLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;

const SuccessContainer = styled.div`
  width: 98%;
  margin-left: 1%;
  position: relative;
  border-radius: 8px;
  margin-right: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) => props.theme.main.green};
  padding: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ApprovalContainer = styled.div<{ noGap?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 0;

  text-align: center;
  border-top: 1px solid ${(props) => props.theme.main.green};
  border-left: 1px solid ${(props) => props.theme.main.green};
  border-right: 1px solid ${(props) => props.theme.main.green};
  border-bottom: 1px solid ${(props) => props.theme.main.green};
  border-radius: 0 0 8px 8px;

  margin-bottom: ${(props) => (props.noGap ? '0' : '24px')};
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 36px 67px 0 rgba(0, 0, 0, 0.07);
`;

const ChangeLocationButton = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  display: inherit;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
`;

const LocationText = styled.span`
  display: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
`;

const LocationInfoContainer = styled.div<{ noMargin?: boolean }>`
  margin-top: 24px;
  margin-bottom: 24px;

  ${({ noMargin }) =>
    noMargin &&
    `
       margin: 0;
    `}
`;

const RequestIncreaseButtonContainer = styled.div`
  margin-top: 16px;
`;

const CreditLineIncreaseReview = styled.span`
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-top: 8px;
`;
