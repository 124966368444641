import { theme } from 'config/theme';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Icon } from 'lib/components';
import { EXPIRED_DATE_FORMAT } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { useViewport } from 'lib/hooks/useViewport';
import { FormatPrice } from 'lib/utils';
import { useTranslation } from 'react-i18next';

interface ApprovalCardProps {
  expireAt?: string;
  balanceAvailable?: number;
}

const ApprovalCard = ({ expireAt, balanceAvailable }: ApprovalCardProps) => {
  const { isMobile } = useViewport();
  const { organization, borrower } = useStore();
  const { t: translate } = useTranslation();

  return (
    <ApprovalContainer isMobile={isMobile} id="capture">
      <BackgroundImage src="cherry-logo-white" width={300} height={300} />
      <ApprovalCardHeader>
        <Icon src="cherry_white_logo" width={88} height={25} />
        <ExpireDate>
          <>{translate('approval.expires')}:</>
          <span>{dayjs(expireAt).format(EXPIRED_DATE_FORMAT)}</span>
        </ExpireDate>
      </ApprovalCardHeader>
      <ApprovedContainer>
        <ApprovalText>{translate('approvalCard.approvalUpTo')}</ApprovalText>
        <ApprovalAmount>${balanceAvailable && FormatPrice(Number(balanceAvailable))}</ApprovalAmount>
        <ApprovalOrganization> {organization?.name}</ApprovalOrganization>
        <BorrowerInfo>
          {' '}
          {borrower?.firstName} {borrower?.lastName}
        </BorrowerInfo>
      </ApprovedContainer>
    </ApprovalContainer>
  );
};
export default ApprovalCard;

const ApprovalContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  position: relative;
  padding: 20px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid ${theme.main.midnightBlue};
  background: ${theme.main.midnightBlue};
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 36px 67px 0 rgba(0, 0, 0, 0.07);
`;

const ApprovedContainer = styled.div`
  margin-top: 16px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const ApprovalText = styled.span`
  color: ${theme.main.green};
  font-size: 24px;
  font-weight: 700;
`;

const ApprovalAmount = styled.span`
  color: ${theme.main.white};
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const BorrowerInfo = styled.span`
  color: ${theme.main.white};
  font-size: 10px;
  font-weight: 400;
`;

const ApprovalOrganization = styled.span`
  color: ${theme.main.grey20};
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const BackgroundImage = styled(Icon)`
  position: absolute;
  right: -90px;
  top: -35px;
  opacity: 0.1;
`;

const ApprovalCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ExpireDate = styled.span`
  font-size: 12px;
  display: flex;
  font-weight: 400;
  text-align: center;
  color: ${theme.main.white};
  gap: 2px;
  span {
    font-weight: 700;
  }
`;
