import { Box, Typography } from '@mui/material';
import { theme } from 'config/theme';
import { Button, Icon, Subtitle, SurveyTypeForm } from 'lib/components';
import SurveyNavigatorBox from 'lib/components/SurveyNavigatorBox/SurveyNavigatorBox';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { FaqKycList } from 'pages/Faq/components';
import FaqKycDenialList from 'pages/Faq/components/FaqKycDenialList';
import { MessageTitle } from 'pages/FrozenCredit/FrozenCredit';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function KycDenial({
  isNewDenialUi,
  goToCustomerPortal,
  isOpenAdverseActionLetterLoading,
  openAdverseActionLetter,
}) {
  const { t: translate } = useTranslation();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const isAdverseActionLetterPageLoadSegmentEventLockedRef = useRef(false);

  useEffect(() => {
    if (isNewDenialUi && !isAdverseActionLetterPageLoadSegmentEventLockedRef.current) {
      sendLoadSegmentEvent(WPQSegmentNames.adverseActionLetterPageLoad);
      isAdverseActionLetterPageLoadSegmentEventLockedRef.current = true;
    }
  }, [isNewDenialUi]);

  if (isNewDenialUi) {
    return (
      <>
        <SubtitleContainer mb={3}>
          <PageSubtitle variant="body2">{translate('result.kycDenial.subtitle')}</PageSubtitle>
        </SubtitleContainer>
        <WhatHappensNextBox>
          <Typography color={theme.main.textColor} mb={0.5} fontSize={16} fontWeight="700" variant="h2">
            {translate('result.kycDenial.whatHappensNext.title')}
          </Typography>
          <Typography color={theme.main.textColor} variant="body2">
            {translate('result.kycDenial.whatHappensNext.subtitle')}
          </Typography>
        </WhatHappensNextBox>
        <WhyWasIDeniedBox>
          <WhyWasIDeniedTitle variant="h3" fontSize={16} fontWeight="700">
            {translate('whyWasIDenied.title')}
          </WhyWasIDeniedTitle>
          <WhyWasIDeniedSubtitle variant="body2">{translate('whyWasIDenied.subtitle')}</WhyWasIDeniedSubtitle>
          <AdverseActionLetterButton
            disabled={isOpenAdverseActionLetterLoading}
            loading={isOpenAdverseActionLetterLoading}
            onClick={openAdverseActionLetter}
            mb={0}
            disableRipple
            endIcon={
              !isOpenAdverseActionLetterLoading ? <Icon width={20} height={20} src="location-export-open" /> : null
            }
          >
            <Typography mr={1} fontWeight="600">
              {translate('whyWasIDenied.buttonText')}
            </Typography>
          </AdverseActionLetterButton>
        </WhyWasIDeniedBox>
        <SurveyNavigatorBox
          page="DENIAL"
          title={translate('result.survey.title', {
            price: '$25',
          })}
        />
        <FaqListContainer>
          <FaqKycDenialList />
        </FaqListContainer>
      </>
    );
  } else {
    return (
      <>
        <SubtitleContainer>
          <Subtitle color={theme.main.textColor}>{translate('result.subTitle2')}</Subtitle>

          <SurveyTypeForm
            page="DENIAL"
            title={translate('result.survey.title', {
              price: '$25',
            })}
            description={translate('result.survey.desc', {
              price: '$25',
            })}
          />

          <StyledMessageTitle>{translate('result.info.title')}</StyledMessageTitle>
          <Subtitle m="16px 0 16px 0" color={theme.main.textColor}>
            {translate('result.info.subTitle1')}
          </Subtitle>
          <Subtitle m="0 0 16px 0" color={theme.main.textColor}>
            {translate('result.info.subTitle2')}
          </Subtitle>
          <Subtitle m="0 0 20px 0" color={theme.main.textColor}>
            {translate('result.info.subTitle3')}
          </Subtitle>

          <Button onClick={goToCustomerPortal}>{translate('buttons.patientPortal')}</Button>
        </SubtitleContainer>

        <SubtitleContainer removePadding={true}>
          <FaqKycList />
        </SubtitleContainer>
      </>
    );
  }
}

const WhyWasIDeniedBox = styled(Box)`
  margin-bottom: 36px;
`;

const WhyWasIDeniedTitle = styled(Typography)`
  margin-bottom: 4px !important;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const WhyWasIDeniedSubtitle = styled(Typography)`
  color: ${(props) => props.theme.main.midnightBlue};
  margin-bottom: 8px !important;
`;

const AdverseActionLetterButton = styled(Button)``;

const PageSubtitle = styled(Typography)`
  color: ${(props) => props.theme.main.midnightBlue};
`;

const WhatHappensNextBox = styled(Box)`
  margin-bottom: 24px;
`;

const StyledMessageTitle = styled(MessageTitle)`
  color: ${(props) => props.theme.main.textColor};
  margin-top: 40px;
`;

const FaqListContainer = styled(Box)``;

const SubtitleContainer = styled(Box)<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props?.removePadding ? '20px -24px 0' : 'initial')};
`;
