import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import { FormatAmount } from 'lib/utils';

import { AlleHeader, Button, HaveQuestionsModal, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { PlanCard } from './components/PlanCard';
import { theme } from 'config/theme';

type AllePlansProps = {
  amount: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  filteredProductCalculations: any; // Note: It is set as any because of it has been set as any before. will be fixed
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedPlan: any; // Note: It is set as any because of it has been set as any before. will be fixed
  selectedIndex: number;
  sliderDisplayCount: number;
  customButtonText: string;
  selectedMonth: string;
  handleCarouselChange: (index: number) => void;
  navigateToPlanReview: () => void;
  handleCustomDownPayment: () => void;
};

const AllePlans = ({
  amount,
  filteredProductCalculations,
  selectedPlan,
  selectedIndex,
  sliderDisplayCount,
  customButtonText,
  selectedMonth,
  handleCarouselChange,
  navigateToPlanReview,
  handleCustomDownPayment,
}: AllePlansProps) => {
  return (
    <ContentContainer justify="space-between" noPadding={true}>
      <AlleHeader />
      <InnerContainer>
        <InsideContainer>
          <PageTitle
            m={'10px 0px 0px 0px'}
            color={theme.main.black10}
            fontSize="18px"
            fontWeight={600}
            lineHeight="32px"
          >
            Pick the payment plan that fits you best
          </PageTitle>
          <Paragraph m={'10px 0px 12px 0px'}>Split your {FormatAmount(amount)} purchase and pay over time</Paragraph>
        </InsideContainer>
        {filteredProductCalculations?.length > 1 ? (
          <SliderContainer isFirstItemSelected={selectedIndex === 0 || false}>
            <Carousel
              emulateTouch={true}
              selectedItem={selectedIndex}
              swipeScrollTolerance={10}
              showIndicators={false}
              swipeable={true}
              centerMode={true}
              showThumbs={false}
              centerSlidePercentage={100 / sliderDisplayCount}
              showArrows={false}
              showStatus={false}
              onChange={handleCarouselChange}
              onClickItem={handleCarouselChange}
            >
              {filteredProductCalculations?.map((data, index) => (
                <PlanCard
                  key={index}
                  active={selectedIndex === index}
                  data={data}
                  handleCustomDownPayment={handleCustomDownPayment}
                  useElegantTheme={true}
                />
              ))}
            </Carousel>
          </SliderContainer>
        ) : (
          <SoloContainer>
            <PlanCard
              key={1}
              active={true}
              data={filteredProductCalculations?.[0]}
              handleCustomDownPayment={handleCustomDownPayment}
              useElegantTheme={true}
            />
          </SoloContainer>
        )}
      </InnerContainer>
      {selectedPlan?.promo && selectedPlan?.product?.promoApr !== null && (
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_black'} />
            <InfoText>
              APR increases to <b>{selectedPlan?.product?.fallbackApr || selectedPlan?.product?.apr}%</b> permanently if
              payment is not received within 5 days of any due date.
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      )}
      <ButtonContainer>
        <Button
          data-testid={selectedPlan?.product?.parentId ? 'choose-month-plan' : 'choose-custom-month-plan'}
          onClick={navigateToPlanReview}
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Choose {customButtonText} {selectedMonth}-Month Plan
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </ContentContainer>
  );
};

const InsideContainer = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled(Title)`
  font-family: 'Graphik' !important;
`;

const Paragraph = styled(Subtitle)`
  font-family: 'Graphik' !important;
  font-size: 16px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 20px 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const SoloContainer = styled.div`
  padding: 0 24px 20px 24px;
`;

const SliderContainer = styled.div<{ isFirstItemSelected: boolean }>`
  width: ${(props) => (props?.isFirstItemSelected ? 'calc(100% - 24px)' : '100%')};
  padding: ${(props) => (props?.isFirstItemSelected ? '0 0px 0 24px' : '0 0px 0 0px')};
  margin-bottom: 75px;

  .carousel.carousel-slider {
    overflow: initial !important;
  }

  .carousel .control-dots {
    bottom: -50px !important;
  }

  .carousel .control-dots .dot {
    background: ${(props) => props.theme.main.grayColor};
    box-shadow: none;
    opacity: 1;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background: ${(props) => props.theme.main.green};
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    margin-top: 50px;
    transition: margin 200ms ease-in-out;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide.selected {
    margin-top: 20px;
    transition: margin 200ms ease-in-out;
  }
`;

const ButtonContainer = styled.div`
  padding: 0 24px;
  margin-bottom: 32px;
`;

export default AllePlans;
