import { InputAdornment } from '@mui/material';
import { theme } from 'config/theme';
import { Icon, Loading, NumberFormatInput, TextField, Title } from 'lib/components';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useDebounce } from 'lib/hooks/useDebounce';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import useStore from 'lib/hooks/useStore';
import { useViewport } from 'lib/hooks/useViewport';
import { FormatPrice } from 'lib/utils';
import { PlanBox } from 'pages/Plans/components/PlanBox';
import { SelectedProductCard } from 'pages/Plans/components/SelectedPlanCard';
import { IPlan } from 'pages/Plans/types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const PreviewPaymentOptionsWidget = () => {
  const { t: translate } = useTranslation();
  const { isMobile } = useViewport();
  const { getProductCalculation } = useProductCalculation();
  const { sendActionSegmentEvent, sendLoadSegmentEvent } = useSegmentContext();
  const { productCalculations, selectedApplication, applicationFlow, application, setPageIndex } = useStore();
  const loadSegmentController = useRef(true);

  const { requestAmount = 0 } = applicationFlow || {};
  const { balanceAvailable = 0 } = selectedApplication || {};
  let selectedAmount: number;
  if (requestAmount > 0) {
    selectedAmount = Math.min(requestAmount, balanceAvailable);
  } else if (balanceAvailable > 0) {
    selectedAmount = balanceAvailable;
  } else {
    selectedAmount = 0;
  }
  const [activePlan, setActivePlan] = useState<IPlan>({} as IPlan);
  const [planList, setPlanList] = useState<IPlan[]>([]);
  const [minEligibleAmount, setMinEligibleAmount] = useState(0);
  const [error, setError] = useState<string>('');
  const [calculationLoading, setCalculationLoading] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number | null>(selectedAmount);
  const debouncedValue = useDebounce(enteredAmount, 1000);

  const TreatmentCostError = {
    Maximum: translate('treatmentCost.error.maximum', { amount: `$${FormatPrice(minEligibleAmount)}` }),
    Minimum: translate('treatmentCost.error.minimum', { amount: `$${FormatPrice(balanceAvailable)}` }),
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      const productsOptionMonthArray = productCalculations.map((x) => x.product.term);
      sendLoadSegmentEvent(WPQSegmentNames.reviewPaymentOptionLoad, {
        availableProducts: productsOptionMonthArray,
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    if (debouncedValue > balanceAvailable) {
      setError(`${TreatmentCostError.Minimum}`);
    } else if (debouncedValue < minEligibleAmount) {
      setError(`${TreatmentCostError.Maximum}`);
    } else {
      setError(``);
      getProducts(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setPageIndex(1);
  }, []);

  useEffect(() => {
    if (productCalculations.length > 0) {
      const productList: IPlan[] = productCalculations;
      setPlanList(productList);
      setActivePlan(productList?.[0]);
      setCalculationLoading(false);
    }
  }, [productCalculations]);

  const getProducts = async (balance) => {
    setCalculationLoading(true);
    const res = await getProductCalculation(application?.id, balance);
    const minValue = res.reduce((min, value) => Math.min(min, value?.product?.minEligible), Infinity);
    setMinEligibleAmount(minValue);
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const regex = /^[0-9.\b]+$/;
    if (value && regex.test(value)) {
      setEnteredAmount(Number(value));
    }
  };

  const renderAdornment = () => {
    return (
      <InputAdornment
        sx={{
          padding: '1px 0 0 0',
          '& .MuiTypography-root': { color: '#000' },
        }}
        position="start"
      >
        $
      </InputAdornment>
    );
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
    }
  };
  return (
    <ContentContainer isMobile={isMobile}>
      <PreviewPaymentOptionsContainer>
        <Title>{translate('approval.previewPayment.title')}</Title>
        <InputContainer>
          <CustomTextField
            id="amount"
            variant="filled"
            data-testid="amount"
            data-neuro-label="amount"
            label={translate('approval.previewPayment.estimatedTreatmentCost')}
            type="tel"
            value={debouncedValue}
            max={balanceAvailable}
            onChange={handlePriceChange}
            error={!!error}
            helperText={error ? error : null}
            onBlur={(e) => {
              sendActionSegmentEvent(WPQSegmentNames.reviewPaymentOptionInputFilled, {
                metaData: e.target.defaultValue,
              });
            }}
            onKeyDown={checkKeyDown}
            InputProps={{
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: renderAdornment(),
            }}
          />
        </InputContainer>
        {calculationLoading ? (
          <CenterLoading>
            <Loading size={40} />
          </CenterLoading>
        ) : (
          <ProductContainer>
            {planList && (
              <ProductList totalPlanLength={planList?.length} isMobile={isMobile}>
                {planList?.map((data, index) => (
                  <>
                    <PlanBox
                      key={`plan-box-${data?.product?.term}`}
                      isActive={data?.product?.term === activePlan?.product?.term}
                      setActivePlan={setActivePlan}
                      plan={data}
                      totalLength={planList?.length}
                      approvalScreen
                    />
                  </>
                ))}
              </ProductList>
            )}
            <SelectedProductCard selectedPlan={activePlan} approvalScreen />
          </ProductContainer>
        )}
        <PreviewContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <span>{translate('approval.previewPayment.onlyPreview')}</span>
        </PreviewContainer>
      </PreviewPaymentOptionsContainer>
    </ContentContainer>
  );
};

const PreviewPaymentOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  span {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }
`;

const CenterLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

const ProductContainer = styled.div`
  background-color: ${theme.main.lightGreen};
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 16px;
`;

const ProductList = styled.div<{ totalPlanLength: number; isMobile: boolean }>`
  padding: 16px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: ${(props) => (props?.totalPlanLength > 5 && props?.isMobile ? 'wrap' : 'no-wrap')};
  justify-content: ${(props) => (props?.totalPlanLength === 5 ? 'space-between' : 'flex-start')};
`;

const CustomTextField = styled(TextField)`
  width: 100% !important;
`;

const InputContainer = styled.div`
  margin: 16px 0px;
`;

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 0px 24px 20px 24px;
  margin-top: 0px;
  padding-top: 15px;
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
`;

export default PreviewPaymentOptionsWidget;
