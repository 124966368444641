import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { calculatePaymentFeeTotalAmount, filterCardsByCreatedAt, filterPaymentMethods, FormatAmount } from 'lib/utils';
import AddPaymentMethodModal from './components/AddPaymentMethodModal';
import PaymentMethodCard from './components/PaymentMethodCard';

import { CREDIT_CARD_DOWN_PAYMENT } from 'lib/constants';
import { useServices } from 'pages/AuthChecker/services';
import { usePlans } from 'pages/Plans/services';
import AlleDownPayment from './AlleDownPayment';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

interface StoreCardType {
  borrowerId: string;
  expireMonth: number;
  expireYear: number;
  id: string;
  last4: string;
  network: string;
  status: string;
  type: string;
}

export interface SelectedPaymentMethodType {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  achAccount: any;
  createdAt: string;
  firstName: string;
  id: string;
  lastName: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  rccAccount: any;
  type: string;
  zip: string;
  storedCard: StoreCardType;
}

const DownPayment = () => {
  const { patchLoan } = usePlans();
  const { getLoan } = useServices();
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const {
    paymentMethods,
    loan,
    downPaymentMethod,
    setDownPaymentMethod,
    setPageIndex,
    selectedPlan,
    features,
    paymentMethodFees,
    isAlle,
  } = useStore();

  const { captureException } = useSentry();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();

  const loadSegmentController = useRef(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethodType | null>(
    downPaymentMethod || null,
  );

  const hasCreditCardPaymentActive = features?.includes(CREDIT_CARD_DOWN_PAYMENT);

  const cardList = filterPaymentMethods(
    paymentMethods?.filter((payment) => payment?.type === 'CARD' && payment?.storedCard?.type !== 'PREPAID'),
    hasCreditCardPaymentActive,
  );

  const paymentFee = paymentMethodFees?.[selectedPaymentMethod?.storedCard?.type || '']?.percent || 0;
  const paymentFeeAmount = calculatePaymentFeeTotalAmount(
    paymentMethodFees,
    selectedPaymentMethod?.storedCard?.type,
    selectedPlan?.downPaymentAmount,
  );

  const debitCardMethodContents = () => {
    return cardList?.filter(
      (content) =>
        content.type === 'CARD' && content.storedCard.type === 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  };
  const creditCardMethodContents = () => {
    return cardList?.filter(
      (content) =>
        content.type === 'CARD' && content.storedCard.type !== 'DEBIT' && filterCardsByCreatedAt(content?.storedCard),
    );
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutDownPaymentLoad, {
        application: 'checkout',
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(3);
  }, [setPageIndex]);

  useEffect(() => {
    if (debitCardMethodContents()?.length > 0 && !selectedPaymentMethod) {
      const debitCards = debitCardMethodContents()?.sort((a, b) => b.id - a.id);
      selectPaymentMethodHandler(debitCards?.[0]);
    }
  }, [paymentMethods]);

  const hideModal = () => setShow(false);

  const navigateToPaymentMethod = async () => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    try {
      setLoading(true);
      const loanData = await getLoan(loanId);

      if (loanData?.status === 'CANCELLED') {
        navigate(`expired`);
        setLoading(false);
        return;
      }

      if (selectedPaymentMethod) {
        await patchLoan({
          loanId: loanId,
          downPaymentPaymentMethodId: selectedPaymentMethod?.id,
        });
      }
      sendActionSegmentEvent(WPQSegmentNames.downPaymentContinueClicked, {
        payment_method_type: selectedPaymentMethod?.type,
        payment_type: 'Down Payment',
        loan_id: loanId,
      });

      setDownPaymentMethod(selectedPaymentMethod);
      navigate(`method-list`);
      setLoading(false);
    } catch (err) {
      captureException('Down Payment Navigate Error', {
        page: 'DownPayment',
        message: 'Down Payment Navigate Error',
        loanId: loanId || 'No Loan',
        cardType: selectedPaymentMethod?.type,
      });

      navigate(`loan-cancelled`);
      setLoading(false);
    }
  };

  const addPaymentMethodHandler = () => {
    sendActionSegmentEvent(WPQSegmentNames.downPaymentAddMethodClicked);
    setShow(true);
  };

  const selectPaymentMethodHandler = (value) => {
    setSelectedPaymentMethod(value);
  };

  const addDebitCardHandler = () => {
    navigate(`add-card`);
  };

  if (isAlle) {
    return (
      <>
        <AlleDownPayment
          debitCardMethodContents={debitCardMethodContents}
          creditCardMethodContents={creditCardMethodContents}
          navigateToPaymentMethod={navigateToPaymentMethod}
          addPaymentMethodHandler={addPaymentMethodHandler}
          selectPaymentMethodHandler={selectPaymentMethodHandler}
          selectedPaymentMethod={selectedPaymentMethod}
          loading={loading}
        />
        <AddPaymentMethodModal show={show} hideModal={hideModal} addCardHandler={addDebitCardHandler} />
      </>
    );
  }

  return (
    <Container showBackButton={true} backUrl={cardList?.length === 0 ? 'add-card' : 'plan-review'}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'calendar_check'} />
          <Title m={'10px 0px 12px 0px'} fontSize="20px">
            {translate('downPayment.title')}
          </Title>

          <SuccessContainer>
            <ApprovalContainer>
              <Subtitle fontSize="10px" m={'10px 0px 0px 0px'} textAlign="center">
                {translate('downPayment.subTitle')}
              </Subtitle>

              {selectedPaymentMethod?.type === 'CARD' && paymentFee > 0 ? (
                <Title fontSize={theme.constants.xl} color={theme.main.green} m={'10px 0 0 0'} textAlign="center">
                  {FormatAmount(paymentFeeAmount)}
                </Title>
              ) : (
                <Title fontSize={theme.constants.xl} color={theme.main.green} m={'10px 0 0 0'} textAlign="center">
                  {FormatAmount(selectedPlan?.downPaymentAmount)}
                </Title>
              )}

              <Subtitle
                m={'10px 0px 0px 0px'}
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html: translate('downPayment.downPaymentAmount', {
                    downPaymentAmount: FormatAmount(selectedPlan?.downPaymentAmount),
                  }),
                }}
              />

              {selectedPaymentMethod?.type === 'CARD' && paymentFee > 0 ? (
                <Subtitle
                  m={'0px 0px 0px 0px'}
                  textAlign="center"
                  dangerouslySetInnerHTML={{
                    __html: translate('downPayment.downPaymentFee', {
                      downPaymentAmount: FormatAmount((paymentFee * selectedPlan?.downPaymentAmount) / 100),
                      paymentFee: paymentFee,
                    }),
                  }}
                />
              ) : null}
            </ApprovalContainer>
          </SuccessContainer>

          {cardList?.length > 0 ? (
            <Subtitle m={'10px 0px 10px 0px'}>{translate('downPayment.selectPaymentMethod')}</Subtitle>
          ) : null}

          {debitCardMethodContents()?.map((paymentMethod, index) => (
            <PaymentMethodCard
              key={index}
              paymentMethod={paymentMethod}
              selectPaymentMethod={selectPaymentMethodHandler}
              selectedPaymentMethod={selectedPaymentMethod}
              isPreferred={true}
            />
          ))}

          {creditCardMethodContents()?.map((paymentMethod, index) => (
            <PaymentMethodCard
              key={index}
              paymentMethod={paymentMethod}
              selectPaymentMethod={selectPaymentMethodHandler}
              selectedPaymentMethod={selectedPaymentMethod}
            />
          ))}
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>{translate('downPayment.info')}</InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <DisclaimerText>
          {translate('downPayment.footerText', {
            amount: FormatAmount(selectedPlan?.downPaymentAmount),
          })}
        </DisclaimerText>
        <Button onClick={navigateToPaymentMethod} disabled={!selectedPaymentMethod || loading} loading={loading}>
          {translate('buttons.agreeAndContinue')}
        </Button>
        <Button secondary={true} onClick={addPaymentMethodHandler}>
          {translate('buttons.addPaymentMethod')}
        </Button>
      </ButtonContainer>
      <AddPaymentMethodModal show={show} hideModal={hideModal} addCardHandler={addDebitCardHandler} />
    </Container>
  );
};

export default DownPayment;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const SuccessContainer = styled.div`
  width: 100%;
  filter: drop-shadow(0px 0px 2px #d1d1d1);
  margin: 8px 0;
`;

const ApprovalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #ffffff 0%, #ffffff 100%);
  border-radius: 8px 8px 0 0;
  padding: 24px 16px;
  margin-bottom: 20px;
  text-align: center;
  -webkit-filter: drop-shadow(3px 3px 1px black);
  filter: drop-shadow(0px 0px 5px white);

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0;
    content: '';
    display: block;
    background: radial-gradient(circle, #ffffff00 20px, #ffffff 20px);
    background-size: 35px 40px;
    background-position: -20px -20px;
    width: 100%;
    height: 40px;
    z-index: -1;
  }
`;

const DisclaimerText = styled.div`
  color: ${(props) => props.theme.main.grayColor};
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 24px;
`;
