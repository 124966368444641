import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useViewport } from 'lib/hooks/useViewport';
import { FormatAmount } from 'lib/utils';
import { useSentry } from 'lib/hooks/useSentry';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { PlanCard } from './components/PlanCard';
import AllePlans from 'pages/Plans/AllePlans';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import PlanSelection from './PlanSelection';
import { IPlan } from './types';

const Plans = () => {
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { isMobile } = useViewport();
  const { trackEvent } = useAnalytics();
  const { trackOptionSelected } = useSegment();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();
  const {
    organization,
    loan,
    borrower,
    setPageIndex,
    setSelectedPlan,
    productCalculations,
    setSelectedPaymentPlan,
    selectedPaymentPlan,
    isAlle,
    sessionApiData,
  } = useStore();
  const [filteredProductCalculations, setFilteredProductCalculations] = useState<IPlan[]>(productCalculations);

  const { featureFlags } = sessionApiData || {};

  const amount = loan?.purchaseAmount;
  const calculateSelectedIndex = 0;

  const isDownPaymentEnabled = featureFlags?.downPaymentEnabled || false;
  const isNewProductScreenEnable = featureFlags?.newProductSelection || false;

  useEffect(() => {
    let productList: IPlan[] = productCalculations;
    if (!isDownPaymentEnabled) {
      productList = productCalculations.filter((calculation) => calculation?.product?.parentId === null);
    }
    setFilteredProductCalculations(productList);
  }, [productCalculations]);

  const [selectedIndex, setSelectedIndex] = useState<number>(selectedPaymentPlan || calculateSelectedIndex);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutSelectionLoad, {
        purchaseAmount: amount,
        application: 'checkout',
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  useEffect(() => {
    setSelectedPaymentPlan(selectedIndex);
  }, [selectedIndex]);

  const navigateToPlanReview = () => {
    try {
      const { application } = useStore.getState() || {};
      const selectedPlan = filteredProductCalculations?.[selectedIndex];

      trackOptionSelected(organization, application, loan, selectedPlan, amount);
      sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${selectedMonth}_MONTH_PLAN_SELECTED`);

      setSelectedPlan(selectedPlan);

      navigate(`plan-review`);
    } catch (err) {
      captureException('Plans Error', {
        error: err,
        page: 'Plans',
        organizationId: organization?.id,
        loanId: loan?.id,
      });
    }
  };

  const handleCarouselChange = (index: number) => {
    trackEvent(AnalyticEventNames.PLAN_PICK);
    setSelectedIndex(index);
    sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${selectedMonth}_MONTH_PLAN_CLICKED`);
  };

  const handleCustomDownPayment = () => {
    try {
      const selectedPlan = filteredProductCalculations?.[selectedIndex];
      setSelectedPaymentPlan(selectedIndex);
      setSelectedPlan(selectedPlan);
      sendActionSegmentEvent(WPQSegmentNames.customDownPaymentClicked);

      navigate(`customize-plan`);
    } catch (err) {
      captureException('Customize Plan Error', {
        error: err,
        page: 'Plans',
        message: 'Customize Plan Error',
        loanId: loan?.id || 'No Loan',
      });
    }
  };

  // Slider Count
  const sliderDisplayCount = isMobile ? (selectedIndex === 0 ? 1.5 : 1.6) : 2.2;
  const selectedMonth = filteredProductCalculations?.[selectedIndex]?.product?.term;
  const _selectedPlan = filteredProductCalculations?.[selectedIndex];

  const customButtonText = filteredProductCalculations?.[selectedIndex]?.product?.parentId
    ? translate('plans.customTitle')
    : '';

  const renderAlleScreen = () => {
    return (
      <AllePlans
        amount={amount}
        filteredProductCalculations={filteredProductCalculations}
        selectedPlan={_selectedPlan}
        selectedIndex={selectedIndex}
        sliderDisplayCount={sliderDisplayCount}
        customButtonText={customButtonText}
        selectedMonth={String(selectedMonth)}
        handleCarouselChange={handleCarouselChange}
        navigateToPlanReview={navigateToPlanReview}
        handleCustomDownPayment={handleCustomDownPayment}
      />
    );
  };

  const renderNewProductSelectionScreen = () => {
    return <PlanSelection planList={filteredProductCalculations} />;
  };

  const renderOldProductSelectionScreen = () => {
    return (
      <Container showBackButton={true} backUrl={`choose-plan`}>
        <ContentContainer justify="space-between" noPadding={true}>
          <InnerContainer>
            <InsideContainer>
              <Icon src={'payment'} />
              <Title m={'10px 0px 0px 0px'}>{translate('plans.title')}</Title>
              <Subtitle
                m={'10px 0px 12px 0px'}
                dangerouslySetInnerHTML={{
                  __html: translate('plans.subTitle', {
                    amount: FormatAmount(amount),
                  }),
                }}
              />
            </InsideContainer>
            {filteredProductCalculations?.length > 1 ? (
              <SliderContainer isFirstItemSelected={selectedIndex === 0 || false}>
                <Carousel
                  emulateTouch={true}
                  selectedItem={selectedIndex}
                  swipeScrollTolerance={10}
                  showIndicators={true}
                  swipeable={true}
                  centerMode={true}
                  showThumbs={false}
                  centerSlidePercentage={100 / sliderDisplayCount}
                  showArrows={false}
                  showStatus={false}
                  onChange={handleCarouselChange}
                  onClickItem={handleCarouselChange}
                >
                  {filteredProductCalculations?.map((data, index) => (
                    <PlanCard
                      key={index}
                      active={selectedIndex === index}
                      data={data}
                      handleCustomDownPayment={handleCustomDownPayment}
                    />
                  ))}
                </Carousel>
              </SliderContainer>
            ) : (
              <SoloContainer>
                <PlanCard
                  key={1}
                  active={true}
                  data={filteredProductCalculations?.[0]}
                  handleCustomDownPayment={handleCustomDownPayment}
                />
              </SoloContainer>
            )}
          </InnerContainer>
          {_selectedPlan?.promo && _selectedPlan?.product?.promoApr !== null && (
            <InnerContainer>
              <InfoContainer>
                <Icon width={20} height={20} src={'info_circle_outline'} />
                <InfoText
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.apr', {
                      apr: _selectedPlan?.product?.fallbackApr || _selectedPlan?.product?.apr,
                    }),
                  }}
                />
              </InfoContainer>
            </InnerContainer>
          )}
        </ContentContainer>
        <ButtonContainer>
          <Button
            data-testid={_selectedPlan?.product?.parentId ? 'choose-month-plan' : 'choose-custom-month-plan'}
            onClick={navigateToPlanReview}
          >
            {translate('plans.choosePlan', {
              text: customButtonText,
              selectedMonth: selectedMonth,
            })}
          </Button>
        </ButtonContainer>
      </Container>
    );
  };
  return (
    <>
      {isAlle
        ? renderAlleScreen()
        : isNewProductScreenEnable
        ? renderNewProductSelectionScreen()
        : renderOldProductSelectionScreen()}
    </>
  );
};

export default Plans;

const InsideContainer = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 20px 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const SoloContainer = styled.div`
  padding: 0 24px 20px 24px;
`;

const SliderContainer = styled.div<{ isFirstItemSelected: boolean }>`
  width: ${(props) => (props?.isFirstItemSelected ? 'calc(100% - 24px)' : '100%')};
  padding: ${(props) => (props?.isFirstItemSelected ? '0 0px 0 24px' : '0 0px 0 0px')};
  margin-bottom: 75px;

  .carousel.carousel-slider {
    overflow: initial !important;
  }

  .carousel .control-dots {
    bottom: -50px !important;
  }

  .carousel .control-dots .dot {
    background: ${(props) => props.theme.main.grayColor};
    box-shadow: none;
    opacity: 1;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background: ${(props) => props.theme.main.green};
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    margin-top: 50px;
    transition: margin 200ms ease-in-out;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide.selected {
    margin-top: 20px;
    transition: margin 200ms ease-in-out;
  }
`;
