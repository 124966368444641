import dayjs from 'dayjs';
export interface CardPaymentMethodInfo {
  cardType: string;
  expireMonth: number;
  expireYear: number;
  firstName: string;
  last4: string;
  lastName: string;
  network: string;
  zip: string;
  type: string;
}

export interface PaymentMethod {
  accountNumber: string;
  bankName: string;
  createdAt: string;
  idPaymentMethod: number;
  recurringMethod: string;
  routingNumber: string;
  storedCard: CardPaymentMethodInfo;
  id: number;
  type: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  achAccount?: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  rccAccount: any;
}

export const filterPaymentMethods = (
  paymentMethods: PaymentMethod[],
  hasCreditCardPaymentActive: boolean,
  hasAchPayment = false,
) => {
  return paymentMethods?.filter((pm) => {
    if (pm?.type === 'CARD') {
      if (hasCreditCardPaymentActive) {
        return true;
      } else {
        return pm?.storedCard?.type === 'DEBIT' || pm?.storedCard?.network === 'AMEX';
      }
    } else {
      return hasAchPayment;
    }
  });
};

export const filterDebitOrAchMethods = (paymentMethods: PaymentMethod[]) => {
  return paymentMethods?.filter((pm) => {
    if (pm?.type === 'CARD') {
      return pm?.storedCard?.type === 'DEBIT';
    } else {
      return true;
    }
  });
};

export const calculatePaymentFeeAmount = (fees, storedCardType, amount) => {
  try {
    const paymentFee = fees?.[storedCardType];
    const paymentFeeFixed = paymentFee?.fixed || 0;
    if (paymentFee?.percent) {
      return paymentFeeFixed + amount * (paymentFee.percent / 100);
    }
    return 0;
  } catch (err) {
    return 0;
  }
};

export const calculatePaymentFeeTotalAmount = (fees, storedCardType, amount) => {
  try {
    const paymentFee = fees?.[storedCardType];
    const paymentFeeFixed = paymentFee?.fixed || 0;
    if (paymentFee?.percent) {
      return paymentFeeFixed + amount + amount * (paymentFee.percent / 100);
    }
    return amount;
  } catch (err) {
    return amount;
  }
};

export const filterCardsByCreatedAt = (card) => {
  const cherryPayCreatedAt = '2022-11-17 06:00:00';
  const cardDate = dayjs(card?.createdAt);
  const filterDate = dayjs(cherryPayCreatedAt);

  if (cardDate) {
    return cardDate.isAfter(filterDate);
  }
  return true;
};
