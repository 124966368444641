import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import timezone from 'dayjs/plugin/timezone';

import useStore from 'lib/hooks/useStore';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { redirectToCustomerPortal } from 'lib/utils';
import { Button, Container, Icon, Subtitle, SurveyTypeForm, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { PHONE, SUPPORT_URL, WEBPREQUAL } from 'lib/constants';

import AlleApproval from 'pages/Approval/AlleApproval';
import { ApplicationFlowStatuses } from 'pages/AuthChecker/type';
import { FaqDeclinedList, FaqKycList, FaqManualReviewList } from 'pages/Faq/components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { GET_FILE, GET_FILE_LIST } from 'lib/graphql/queries';
import { Box } from '@mui/material';
import { FileDetailRequest, FileDetailResponse, FileListRequest, FileListResponse } from './types';
import openPdfFile from 'lib/utils/OpenPdfFile';
import KycFailure from './KycFailure';
import KycDenial from './KycDenial';

dayjs.extend(utc);
dayjs.extend(timezone);

const Result = () => {
  const { navigate } = useNavigation();
  const { submitApplication } = useNeuroId();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();

  const [isOpenAdverseActionLetterLoading, setIsOpenAdverseActionLetterLoading] = useState(false);
  const { applicationFlow, isAlle } = useStore();
  const [readyForAnalyticEvents, setReadyForAnalyticEvents] = useState<boolean>(false);
  const [isConflictError, setIsConflictError] = useState(false);
  const apolloClient = useApolloClient();

  const isKYCStatus = (status: ApplicationFlowStatuses) =>
    [ApplicationFlowStatuses.DENIED, ApplicationFlowStatuses.BLACKLIST].includes(status);
  const isKYCFailure = isKYCStatus(applicationFlow?.status) && !applicationFlow?.kycdenial;
  const isKYCDenial = isKYCStatus(applicationFlow?.status) && applicationFlow?.kycdenial;
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewDenialUi = (featureFlags?.isNewDenialUi || false) && (isKYCFailure || isKYCDenial);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const isManualReview = [ApplicationFlowStatuses.REVIEW].includes(applicationFlow?.status);
  const getInitialStatus = () => {
    const { applicationFlow, borrower } = useStore.getState() || {};
    const _isConflictError =
      borrower &&
      [ApplicationFlowStatuses.SSN_CONFLICT, ApplicationFlowStatuses.ID_CONFLICT].includes(applicationFlow?.status);

    setIsConflictError(_isConflictError);
  };

  const setAnalyticsEvent = () => {
    const { application } = useStore.getState() || {};
    if (isConflictError) {
      trackPage('ssnConflictLanding', {
        application: WEBPREQUAL,
        applicationId: application?.id,
      });

      trackSegmentEvent('ssnConflictLanding', {
        application: WEBPREQUAL,
        applicationId: application?.id,
      });
    } else if (isManualReview) {
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewLoad);
    } else if (isKYCFailure) {
      sendLoadSegmentEvent(WPQSegmentNames.denialLoad);
    } else if (isKYCDenial) {
      sendLoadSegmentEvent(WPQSegmentNames.kycDenialLoad);
    }
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      setAnalyticsEvent();
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    getInitialStatus();
  }, []);

  useEffect(() => {
    submitApplication('result');
  }, []);

  useEffect(() => {
    if (readyForAnalyticEvents) {
      setAnalyticsEvent();
    }
  }, [readyForAnalyticEvents]);

  const startOverHandler = () => {
    navigate(``);
  };

  const iconName = () => {
    if (isKYCFailure || isKYCDenial) {
      return `decline-fail`;
    } else if (isConflictError) {
      return `warning_outline`;
    } else {
      return `glasses-review`;
    }
  };

  const titleText = () => {
    const { borrower } = useStore.getState() || {};

    if (isKYCFailure) {
      return translate(isNewDenialUi ? 'result.kycFailure.title' : 'result.title1');
    } else if (isKYCDenial) {
      return translate(isNewDenialUi ? 'result.kycDenial.title' : 'result.title2');
    } else if (isConflictError) {
      return translate('result.title3');
    } else if (isManualReview) {
      return translate('result.title4');
    } else {
      return translate('result.title5', {
        name: borrower?.firstName,
      });
    }
  };

  const goToCustomerPortal = () => {
    if (isKYCFailure) {
      sendActionSegmentEvent(WPQSegmentNames.denialViewInPatientClick);
    } else if (isKYCDenial) {
      sendActionSegmentEvent(WPQSegmentNames.kycDenialPatientPortalClicked);
    }
    redirectToCustomerPortal();
  };

  const openAdverseActionLetter = async () => {
    sendActionSegmentEvent(WPQSegmentNames.denialClickedAdverseActionLetter);
    const applicationId = useStore.getState()?.application?.id;
    setIsOpenAdverseActionLetterLoading(true);
    const { data: fileListResponse } = await apolloClient.query<FileListResponse, FileListRequest>({
      query: GET_FILE_LIST,
      variables: {
        input: {
          search: [
            {
              key: 'relationId',
              value: applicationId,
            },
            {
              key: 'relationType',
              value: 'APPLICATION',
            },
            {
              key: 'type',
              value: 'ADVERSE_ACTION_LETTER',
            },
          ],
        },
      },
    });
    const { data: fileDetailResponse } = await apolloClient.query<FileDetailResponse, FileDetailRequest>({
      query: GET_FILE,
      variables: {
        input: {
          fileId: fileListResponse.getFileList?.contents[0].id,
        },
      },
    });

    const isFileTokenExpired = fileListResponse.getFileList === null;
    const isFileListEmpty = fileListResponse.getFileList?.total === 0;

    if (isFileTokenExpired && isFileListEmpty) {
      setIsOpenAdverseActionLetterLoading(false);
      return;
    }

    if (fileDetailResponse.getFile?.success) {
      const { data: pdfDataString } = fileDetailResponse.getFile.data;
      openPdfFile(pdfDataString);
    }
    setIsOpenAdverseActionLetterLoading(false);
  };

  const subTitleText = () => {
    if (isKYCFailure) {
      return (
        <KycFailure
          isNewDenialUi={isNewDenialUi}
          isOpenAdverseActionLetterLoading={isOpenAdverseActionLetterLoading}
          openAdverseActionLetter={openAdverseActionLetter}
        />
      );
    } else if (isKYCDenial) {
      return (
        <KycDenial
          isNewDenialUi={isNewDenialUi}
          isOpenAdverseActionLetterLoading={isOpenAdverseActionLetterLoading}
          openAdverseActionLetter={openAdverseActionLetter}
          goToCustomerPortal={goToCustomerPortal}
        />
      );
    } else if (isConflictError) {
      return <Subtitle>{translate('result.subTitle3')}</Subtitle>;
    } else if (isManualReview) {
      return (
        <>
          <SubtitleContainer>
            <Subtitle m={'0px 0px 24px 0px'}>{translate('result.subTitle4')}</Subtitle>
          </SubtitleContainer>

          <SurveyTypeForm
            page="DENIAL"
            title={translate('result.survey.title', {
              price: '$25',
            })}
            description={translate('result.survey.desc', {
              price: '$25',
            })}
          />

          <SubtitleContainer removePadding={true}>
            <FaqManualReviewList />
          </SubtitleContainer>
        </>
      );
    } else {
      return (
        <SubtitleContainer>
          <Subtitle m={'10px 0px 24px 0px'}>{translate('result.subTitle5')}</Subtitle>

          <SurveyTypeForm
            page="DENIAL"
            title={translate('result.survey.title', {
              price: '$25',
            })}
            description={translate('result.survey.desc', {
              price: '$25',
            })}
          />

          <SubtitleContainer removePadding={true}>
            <FaqDeclinedList />
          </SubtitleContainer>
        </SubtitleContainer>
      );
    }
  };

  const infoText = () => {
    if (isKYCFailure || isKYCDenial) {
      return (
        <InfoContainer>
          <InfoText>
            {translate('result.info1', {
              url: SUPPORT_URL,
            })}
          </InfoText>
        </InfoContainer>
      );
    } else if (isConflictError) {
      return (
        <InfoContainer>
          <InfoText>
            {translate('result.info2', {
              phone: PHONE,
            })}
          </InfoText>
        </InfoContainer>
      );
    } else {
      return;
    }
  };

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      {isNewDenialUi ? (
        <NewDenialUiContentContainer justify="space-between">
          <InnerContainer>
            <Title m={'0'}>{titleText()}</Title>
            <Subtitle m={'8px 0 24px'}>{subTitleText()}</Subtitle>
          </InnerContainer>
        </NewDenialUiContentContainer>
      ) : (
        <ContentContainer justify="space-between">
          <InnerContainer>
            <Icon src={iconName()} />
            <Title m={'10px 0px 0px 0px'}>{titleText()}</Title>
            <Subtitle m={'10px 0px 24px 0px'}>{subTitleText()}</Subtitle>
          </InnerContainer>
          {infoText()}
        </ContentContainer>
      )}
      {isConflictError ? (
        <ButtonContainer>
          <Button onClick={startOverHandler}>{translate('buttons.exitApplication')}</Button>
        </ButtonContainer>
      ) : null}
    </Container>
  );
};

export default Result;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SubtitleContainer = styled(Box)<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props?.removePadding ? '20px -24px 0' : 'initial')};
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

export const NewDenialUiContentContainer = styled.div<{
  justify?: string;
  noMargin?: boolean;
  noPadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  padding: ${(props) => (props.noPadding ? '0px' : '24px 24px 40px')};
  margin-top: ${(props) => (props.noMargin ? '0px' : '15px')};
  background-color: ${(props) => props.theme.main.white};
`;
