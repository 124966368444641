import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import App from './App';
import './index.css';
import './i18n';

import { theme } from 'config/theme';
import { APP_ENV, SENTRY_DSN } from 'config';
import { shouldIgnoreError } from 'lib/utils';
import { FeatureFlagsContextProvider } from 'lib/hooks/FeatureManagement/FlagsContext';
import { CookieModal } from 'lib/components';

const container = document.getElementById('root');
const root = createRoot(container!);

SentryBrowser.init({
  dsn: SENTRY_DSN,
  integrations: [new SentryBrowser.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: APP_ENV,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Unhandled Promise Rejection: Failed to fetch',
    'Unhandled Promise Rejection: Load failed',
    'ApolloError: Load failed',
  ],
  beforeSend: (error, hint) => {
    return shouldIgnoreError(error, hint) ? null : error;
  },
  beforeBreadcrumb(breadcrumb, hint) {
    return breadcrumb.category === 'console' ? null : breadcrumb;
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary>
        <FeatureFlagsContextProvider>
          <App />
        </FeatureFlagsContextProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
);
